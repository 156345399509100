<nav class="ajuste-navbar">
    <!-- <a routerLink="/landing">
        <img class="px-1 m-0" src="assets/images/logoSithecBlanco.png" width="110" height="45">
    </a> -->
    <div class="opciones-navbar">
        <!-- <ul *ngIf="user && user.id"> 
            <li class="nav-item dropdown submenu-user">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle"  (click)="showUserOptions()">
                    <span>{{ user.name }}</span>
                </a>
            </li>
        </ul> -->
        <a class="informacion-navbar" *ngIf="!admin && user" >
            <img class="icon-informacion" (click)="abrirSeleccion()" src="../../../../../../assets/images/burger.svg">
        </a>
        <a class="informacion-navbar" *ngIf="!admin && !user" >
            <img class="icon-informacion " (click)="routerNavbar('/account/authentication')" src="../../../../../../assets/images/iniciar_sesion_blue.svg">
        </a>
        <a routerLink="/landing">
            <img class="px-1 m-0" src="assets/images/logoSithecBlanco.png" width="110" height="45">
        </a>
        <div class="icon-badge-container" *ngIf="!admin">
            <img class="icon-badge-icon" src="../../../../../../assets/images/card.svg" alt="" (click)="showCart()">
            <div class="icon-badge">{{badgeItems}}</div>
        </div>
    </div>
</nav>

<div class="menu" id="navbarSupportedContent" *ngIf="bandShowMenu">
    <ul class="navbar-nav">
        <ng-container *ngFor="let item of items">
            <li class="nav-item menu-item" *ngIf="item.items == undefined || item.items.length <= 0">
                <a href="javascript:void(0)" class="nav-link" (click)="routerNavbar(item?.url)">
                    {{ item.label }}
                </a>
            </li>
            <li class="nav-item dropdown menu-item" *ngIf="item.items">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ item.label }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li *ngFor="let submenu of item.items; let i = index" class="menu-item">
                        <a class="dropdown-item" href="javascript:void(0)"
                            (click)="routerNavbar(submenu?.url)">{{ submenu.label }}</a>
                    </li>
                </ul>
            </li>
        </ng-container>
        <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
                <span *ngIf="!user" (click)="routerNavbar('/account/authentication')">Iniciar sesión</span>
            </a>
        </li>
    </ul>
</div>

<div class="user-menu py-3" *ngIf="bandShowUserOptions">
    <a href="javascript:void(0)" (click)="showUserOptions()">
        <logout>
            <span>{{"AUTH.CLOSE_SESSION" | translate }}</span>
        </logout>
    </a>
</div>

  <div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':boolVisibleSelection}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="seccion-seleccion seleccionable" routerLink="informacion" (click)="abrirSeleccion()">
            <p class="opcion-seleccion">Información de cuenta</p>
        </div>
        <div class="seccion-seleccion seleccionable" routerLink="compras" (click)="abrirSeleccion()">
            <p class="opcion-seleccion">Mis Compras</p>
        </div>
        <div class="seccion-seleccion seleccionable" routerLink="certificados" (click)="abrirSeleccion()">
            <p class="opcion-seleccion">Mis Certificados</p> 
        </div>
        <a href="javascript:void(0)" routerLink="account/authentication" style="text-decoration: none;">
            <div class="seccion-seleccion seleccionable" (click)="salir()">
              <p class="opcion-seleccion">Cerrar Sesión</p> 
            </div>
        </a>
        <img src="../../../../../../assets/images/cerrar-menu-movil.svg" class="close-icon" (click)="abrirSeleccion()">
      </div>
    </div>
  </div>


