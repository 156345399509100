import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataDogService } from '../../../services/datadog.service';

@Component({
  selector: 'product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.less']
})
export class ProductInformationComponent implements OnInit {

  activeIndexSelected!: number;
  
  seleccion !: number;
  tipoSeleccionado: string = "";
  nombrePadre: string = "";
  idPadre: string ="";
  isMobileView: boolean=false;
  isSelected: boolean = false;
  actualizarSeleccion(tipo: string) {
    this.tipoSeleccionado = tipo;
    this.isSelected = true;
  }
  getNombre(nombre:string){
    this.nombrePadre = nombre;
  }
  getIdProducto(id:string){
    this.idPadre = id;
  }
  constructor(
    private dd: DataDogService,
    private router: Router
  ) 
    { 
      
    }

  ngOnInit(): void {
    this.checkViewport()
    this.checkSelected();
  }
  goBack(): void {
    this.router.navigate(['/product/CONTPAQi']);
  }
  ngAfterViewInit(){
    this.dd.startSessionRecording();
  }

  ngOnDestroy(){
    this.dd.stopSessionRecording();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
    //this.checkSelected();
  }
  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }
  checkSelected(){
    if(this.isMobileView){
      if(this.tipoSeleccionado == ""){
        this.isSelected = false;
      }
      else{
        this.isSelected = true;
      }
    }
  }
  updateSelected(selection:boolean){
    this.isSelected = selection;
  }
}
