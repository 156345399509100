<div class="container content">


  <h5>Severities Toastr message</h5>
  <button type="button" (click)="showSuccess()" >Success</button>
  <button type="button"  (click)="showInfo()" >Info</button>
  <button type="button" (click)="showWarn()"  >Warn</button>
  <button type="button" (click)="showError()"  >Error</button>

  {{ 'AUTH.USER_PASSWORD' | translate }}
    <form [formGroup]="loginForm" (ngSubmit)="submit()" novalidate>
  <form-input
    name="email"
    label="{{ 'AUTH.USER_EMAIL' | translate }} *"
    placeholder="name@email.com"
    type="email"
    formControlName="username"
    [control]="loginForm.controls['username']"
    [submitted]="submitted"
  >
  </form-input>

  <form-input-password
    name="password"
    label="{{ 'AUTH.USER_PASSWORD' | translate }} *"
    placeholder="*********"
    formControlName="password"
    [control]="loginForm.controls['password']"
    [submitted]="submitted"
  >
  </form-input-password>

  <div class="d-flex flex-column mt-5">
    <app-button
      class="full-width"
     
        label="AUTH.USER_PASSWORD"
      type="submit"
    >
    </app-button>
  </div>

</form>
    <form-check-box
    label="Test checkbox"
   >
    </form-check-box>
    <form-radio-button
    [className]="'red'"
    label="Radio button"
    value="0"
    ></form-radio-button>
    <form-input [label]="'test'" [placeholder]="'placeholder'"></form-input>

    <form-select
      id="country"
      name="country"
      label="test select"
      placeholder="placeholder"
      [class]="'color-primary'"
    >
      <ng-container *ngFor="let country of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
        <form-option [value]="country">
          {{ country }}
        </form-option>
      </ng-container>
    </form-select>
    <form-switch
    id="needsRelocation"
    name="needsRelocation"
    rightLabel="Prueba"
   >
    </form-switch>

    <h5>Basic</h5>
    <app-button label="Submit"></app-button>

    <app-button label="Link" class="app-button-link"></app-button>

    <h5>Icons</h5>
    <app-button icon="arrow_back"></app-button>
    <app-button label="Submit" icon="arrow_back"></app-button>
    <app-button label="Submit" icon="arrow_back" iconPos="right"></app-button>

    <h5>Normales</h5>
    <app-button label="Primary" class="primary"></app-button>
    <app-button label="Secondary" class="app-button-secondary"></app-button>
    <app-button label="Success" class="app-button-success"></app-button>
    <app-button label="Info" class="app-button-info"></app-button>
    <app-button label="Warning" class="app-button-warning"></app-button>
    <app-button label="Help" class="app-button-help"></app-button>
    <app-button label="Danger" class="app-button-danger"></app-button>

    <h5>Raised</h5>
    <app-button label="Primary" class="app-button-raised"></app-button>
    <app-button
      label="Secondary"
      class="app-button-raised app-button-secondary"
    ></app-button>
    <app-button
      label="Success"
      class="app-button-raised app-button-success"
    ></app-button>
    <app-button
      label="Info"
      class="app-button-raised app-button-info"
    ></app-button>
    <app-button
      label="Warning"
      class="app-button-raised app-button-warning"
    ></app-button>
    <app-button
      label="Help"
      class="app-button-raised app-button-help"
    ></app-button>
    <app-button
      label="Danger"
      class="app-button-raised app-button-danger"
    ></app-button>

    <h5>Redondeados</h5>
    <app-button label="Primary" class="app-button-rounded"></app-button>
    <app-button
      label="Secondary"
      class="app-button-rounded app-button-secondary"
    ></app-button>
    <app-button
      label="Success"
      class="app-button-rounded app-button-success"
    ></app-button>
    <app-button
      label="Info"
      class="app-button-rounded app-button-info"
    ></app-button>
    <app-button
      label="Warning"
      class="app-button-rounded app-button-warning"
    ></app-button>
    <app-button
      label="Help"
      class="app-button-rounded app-button-help"
    ></app-button>
    <app-button
      label="Danger"
      class="app-button-rounded app-button-danger"
    ></app-button>

    <h5>Texto</h5>
    <app-button label="Primary" class="app-button-text"></app-button>
    <app-button
      label="Secondary"
      class="app-button-secondary app-button-text"
    ></app-button>
    <app-button
      label="Success"
      class="app-button-success app-button-text"
    ></app-button>
    <app-button
      label="Info"
      class="app-button-info app-button-text"
    ></app-button>
    <app-button
      label="Warning"
      class="app-button-warning app-button-text"
    ></app-button>
    <app-button
      label="Help"
      class="app-button-help app-button-text"
    ></app-button>
    <app-button
      label="Danger"
      class="app-button-danger app-button-text"
    ></app-button>
    <app-button
      label="Plain"
      class="app-button-text app-button-plain"
    ></app-button>

    <h5>Raised</h5>
    <app-button
      label="Primary"
      class="app-button-raised app-button-text"
    ></app-button>
    <app-button
      label="Secondary"
      class="app-button-raised app-button-secondary app-button-text"
    ></app-button>
    <app-button
      label="Success"
      class="app-button-raised app-button-success app-button-text"
    ></app-button>
    <app-button
      label="Info"
      class="app-button-raised app-button-info app-button-text"
    ></app-button>
    <app-button
      label="Warning"
      class="app-button-raised app-button-warning app-button-text"
    ></app-button>
    <app-button
      label="Help"
      class="app-button-raised app-button-help app-button-text"
    ></app-button>
    <app-button
      label="Danger"
      class="app-button-raised app-button-danger app-button-text"
    ></app-button>
    <app-button
      label="Plain"
      class="app-button-raised app-button-text app-button-plain"
      type="submit"    ></app-button>

    <h5>Outlined</h5>
    <app-button label="Primary" class="app-button-outlined"></app-button>
    <app-button
      label="Secondary"
      class="app-button-outlined app-button-secondary"
    ></app-button>
    <app-button
      label="Success"
      class="app-button-outlined app-button-success"
    ></app-button>
    <app-button
      label="Info"
      class="app-button-outlined app-button-info"
    ></app-button>
    <app-button
      label="Warning"
      class="app-button-outlined app-button-warning"
    ></app-button>
    <app-button
      label="Help"
      class="app-button-outlined app-button-help"
    ></app-button>
    <app-button
      label="Danger"
      class="app-button-outlined app-button-danger"
    ></app-button>

    <h5>Rounded Outlined</h5>
    <app-button
      label="Primary"
      class="app-button-outlined app-button-rounded"
    ></app-button>
    <app-button
      label="Secondary"
      class="app-button-outlined app-button-rounded app-button-secondary"
    ></app-button>
    <app-button
      label="Success"
      class="app-button-outlined app-button-rounded app-button-success"
    ></app-button>
    <app-button
      label="Info"
      class="app-button-outlined app-button-rounded app-button-info"
    ></app-button>
    <app-button
      label="Warning"
      class="app-button-outlined app-button-rounded app-button-warning"
    ></app-button>
    <app-button
      label="Help"
      class="app-button-outlined app-button-rounded app-button-help"
    ></app-button>
    <app-button
      label="Danger"
      class="app-button-outlined app-button-rounded app-button-danger"
    ></app-button>

    <h5>Rounded Icon app-buttons</h5>
    <app-button icon="arrow_back" class="app-button-rounded"></app-button>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-secondary"
    ></app-button>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-success"
    ></app-button>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-info"
    ></app-button>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-warning"
    ></app-button>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-help"
    ></app-button>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-danger"
    ></app-button>

    <h5>Rounded Text Icon app-buttons</h5>
    <app-button
      icon="arrow_back"
      class="app-button-rounded app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-secondary app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-success app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-info app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-warning app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-help app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-danger app-button-text"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-text app-button-plain"
    ></app-button>

    <h5>Rounded and Outlined Icon app-buttons</h5>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-outlined"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-secondary app-button-outlined"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-success app-button-outlined"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-info app-button-outlined"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-warning app-button-outlined"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-help app-button-outlined"
    ></app-button>
    <app-button
      [icon]="'arrow_back'"
      class="app-button-rounded app-button-danger app-button-outlined"
    ></app-button>
    <app-button
    class="app-button-rounded app-button-text"
    >
    <ng-template pTemplate="content">
      <img alt="logo" src="assets/images/little-facebook-logo.png"/>
  </ng-template>
  </app-button>

  
    <h5>Badges</h5>
    <app-button label="Emails" badge="8"></app-button>
    <app-button
      label="Messages"
      [icon]="'arrow_back'"
      badge="8"
      badgeClass="p-badge-danger"
    ></app-button>

    <h5>app-button Set</h5>
    <span class="app-buttonset">
      <app-button label="Save" [icon]="'arrow_back'"></app-button>
      <app-button label="Delete" [icon]="'arrow_back'"></app-button>
      <app-button label="Cancel" [icon]="'arrow_back'"></app-button>
    </span>
  </div>
