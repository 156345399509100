import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "file-preview",
  templateUrl: "./file-preview.component.html",
  styleUrls: ["./file-preview.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilePreviewComponent),
      multi: true,
    },
  ],
})
export class FilePreviewComponent implements ControlValueAccessor {
  @ViewChild("file", { static: false }) file!: ElementRef<HTMLInputElement>;

  @Input() control: any;
  @Input() submitted: boolean = false;

  value: any = null;

  constructor() {}

  onChange(val: any): void {}
  onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectImage() {
    this.file.nativeElement.click();
  }

  handleFileSelect(evt: any) {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64: string = <string>reader.result;
        this.value = base64.split(",")[1];
        this.onChange(this.value);
        this.onTouched();
      };
    }
  }

  get errorClass() {
    return this.hasErrorInput() ? ` is-invalid-input` : "";
  }

  hasErrorInput(): boolean {
    return (
      (this.submitted && this.control?.invalid) ||
      (this.control &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }
}
