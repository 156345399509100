import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
  
  @NgModule({
    declarations: [
    ],
    imports: [
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ],
    exports: [
      TranslateModule
    ]
  })

  export class SithecTranslateModule {}