import { Injectable } from '@angular/core';
import { IUser } from '../models/user.model';
import { SessionService } from './session.service';
import { SithecCommonApiService } from './sithec-common-api.service';
import { UserService } from './user.service';

export function sessionInitializerFactory(
  sessionInitializerService: SessionInitializerService
) {
  return () => sessionInitializerService.init();
}

@Injectable({
  providedIn: 'root',
})
export class SessionInitializerService {
  constructor(
    private sessionService: SessionService,
    private sithecApiService: SithecCommonApiService,
    private userService: UserService
  ) {}

  public init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.sessionService.hasValidSession) {
        return resolve(true);
      }
    
        this.userService.setUser(JSON.parse(localStorage.getItem('user') || '{}'))
      resolve(true);
    });
  }
}
