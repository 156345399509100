<div class="contenido">
    <div  class="recuadro">
        <h1>AVISO DE PRIVACIDAD </h1>
        <p>Nosotros, Sithec (HECTOR DANIEL HOLGUIN LOPEZ), estamos comprometidos a proteger la privacidad y seguridad de su información personal. Este aviso de privacidad describe cómo recopilamos, usamos y divulgamos su información personal en relación con nuestros servicios en línea. </p>
        <h2>INFORMACIÓN QUE RECOPILAMOS</h2>
        <p>Recopilamos información personal que nos proporciona cuando utiliza nuestro sitio web, incluyendo su nombre, dirección de correo electrónico, dirección postal, número de teléfono y detalles de pago. También recopilamos información de su dispositivo, como su dirección IP y tipo de navegador. </p>
        <h2>CÓMO UTILIZAMOS SU INFORMACIÓN</h2>
        <p>Utilizamos su información personal para procesar sus pedidos, proporcionar soporte al cliente, enviarle actualizaciones de pedidos, enviarle promociones y mejorar nuestros servicios. Además, utilizamos su información para fines internos, como análisis de datos y mejora de nuestro sitio web. </p>
        <h2>CÓMO PROTEGEMOS SU INFORMACIÓN</h2>
        <p>Implementamos medidas de seguridad técnicas y organizativas adecuadas para proteger su información personal contra la pérdida, el uso indebido y el acceso no autorizado. Además, utilizamos el cifrado de datos para proteger la información de su tarjeta de crédito durante la transmisión. </p>
        <h2>DIVULGACIÓN DE INFORMACIÓN A TERCEROS</h2>
        <p>No vendemos ni compartimos su información personal con terceros para fines de marketing. Podemos compartir su información con proveedores de servicios externos para ayudarnos a operar nuestro sitio web y procesar sus pedidos. También podemos divulgar su información personal cuando sea necesario para cumplir con la ley aplicable. </p>
        <h2>CÓMO ACCEDER O ACTUALIZAR SU INFORMACIÓN PERSONAL </h2>
        <p>Si desea acceder, actualizar o eliminar su información personal, puede hacerlo a través de su cuenta en nuestro sitio web o poniéndose en contacto con nuestro equipo de soporte al cliente. </p>
        <h2>CAMBIOS A ESTE AVISO DE PRIVACIDAD </h2>
        <p>Podemos actualizar este aviso de privacidad de vez en cuando. Si realizamos cambios importantes en la forma en que utilizamos su información personal, le notificaremos mediante la publicación de un aviso destacado en nuestro sitio web o mediante el envío de un correo electrónico a la dirección que nos proporcionó. </p>
        <h2>CONTÁCTENOS</h2>
        <p>Si tiene preguntas o inquietudes sobre nuestro aviso de privacidad o nuestra práctica de privacidad, puede ponerse en contacto con nosotros en cualquier momento a través de nuestro equipo de soporte al cliente.</p>
    </div>
</div>        
