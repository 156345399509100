import { NgModule } from '@angular/core'; 
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PurchaseAcceptedComponent } from './components/purchase-accepted/purchase-accepted.component';
import { ComprasComponent } from './components/compras/compras.component';
import { CertificadosComponent } from './components/certificados/certificados.component';
import { VistaResiboComponent } from './components/vista-resibo/vista-resibo.component';
import { WebAuthenticationGuardService } from './services/web-authentication-guard.service';
import { InformacionUsuarioComponent } from './components/informacion-usuario/informacion-usuario.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { GarantiaComponent } from './components/garantia/garantia.component';
import { VerificarComponent } from './components/verificar/verificar.component';
import { RenovacionesComponent } from './components/products/renovaciones/renovaciones.component';
import { ChecaCorreoComponent } from './components/checa-correo/checa-correo.component';
import { VerificaCelularComponent } from './components/verifica-celular/verifica-celular.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ProductSelectedComponent } from './components/product/product-selected/product-selected.component';
import { ProductsResolver } from './resolvers/products.resolver';
import { ProductDetailComponent } from './components/product/product-detail/product-detail.component';
import { ProductResolver } from './resolvers/product.resolver';
import { LandingComponent } from './components/landing/landing.component';
import { AuthenticationPageComponent } from './components/authentication/authentication-page/authentication-page.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { VerifyEmailComponent } from './components/authentication/verify-email/verify-email.component';
import { ResetPasswordTokenComponent } from './components/authentication/reset-password-token/reset-password-token.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  //{ path: 'landing', loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule) },
  {
    path: 'landing',
    component: LandingComponent
  },
  //{path: 'account',loadChildren: () => import("./components/authentication/authentication.module").then(module => module.AuthenticationModule),},
  {
    path: 'account/authentication',
    component: AuthenticationPageComponent
  },
  {
    path: 'account/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'account/reset-password',
    component: ResetPasswordComponent
  },
  {
    path: "account/verify-email",
    component: VerifyEmailComponent,
  },
  {
    path: "account/success-reset",
    component: ResetPasswordTokenComponent,
  },
  { path: 'error',loadChildren: () => import("./components/controls/error-page/error-page.module").then(module => module.ErrorPageModule)},
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [WebAuthenticationGuardService]
  },
  //{path: 'cart',loadChildren: () => import("./components/shopping-cart/shopping-cart.module").then(module => module.ShoppingCartModule)},
  {
    path: 'cart',
    component: ShoppingCartComponent
  },
  {
    path: 'aceptada',
    component: PurchaseAcceptedComponent,
    canActivate: [WebAuthenticationGuardService]
  },
  {
    path: 'informacion',
    component: InformacionUsuarioComponent,
    canActivate: [WebAuthenticationGuardService]
  },
  {
    path: 'compras',
    component: ComprasComponent,
    canActivate: [WebAuthenticationGuardService]
  },
  {
    path: 'recibo',
    component: VistaResiboComponent,
    canActivate: [WebAuthenticationGuardService]
  },
  {
    path: 'certificados',
    component: CertificadosComponent,
    canActivate: [WebAuthenticationGuardService]
  },
  {
    path: 'enviado',
    component: ChecaCorreoComponent
  },
  {
    path: 'sms/:phone',
    component: VerificaCelularComponent
  },
  {
    path: 'landing/aviso',
    component: AvisoComponent
  },
  {
    path: 'landing/terminos',
    component: TerminosComponent
  },
  {
    path: 'landing/garantia',
    component: GarantiaComponent
  },
  {
    path: 'verificar',
    component: VerificarComponent
  },
  {
    path: 'renovacion',
    component: RenovacionesComponent
  },
  {
    path: 'product/:productType',
    component: ProductSelectedComponent,
    resolve:{
      products: ProductsResolver
    },
  },
  // {
  //   path: 'product/:productType/:idProduct',
  //   component: ProductSelectedComponent,
  //   resolve:{
  //     products: ProductsResolver
  //   },
  // },
  {
    path: 'product/detail/:id',
    component: ProductDetailComponent,
    resolve:{
      products: ProductResolver
    },
  },
  {
    path: 'products',
    loadChildren: () => import("./components/products/products.module").then(module => module.ProductsModule),
  },
  {
    path: '**',
    loadChildren: () => import("./components/error-page/error-page.module").then(module => module.ErrorPageModule)
  }
];

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    BrowserModule,
    CommonModule,
    RouterModule
  ],
  providers: [
    ProductsResolver
  ]
})
export class AppRoutingModule { }