import { Component, OnInit } from '@angular/core';
import { IPurchaseData, IPurchaseItem } from '../../models/purchase.model';
import { PurchaseInformationService } from '../../services/purchase-information.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-vista-resibo',
  templateUrl: './vista-resibo.component.html',
  styleUrls: ['./vista-resibo.component.less']
})
export class VistaResiboComponent implements OnInit {

  purchase!:IPurchaseData
  constructor(private purchaseService:PurchaseInformationService, private router:Router) { 
    this.purchase=this.purchaseService.getPurchase()
  }

  ngOnInit(): void {
    //console.log(this.purchase)
  }
  trackDetails(index:number, item:IPurchaseItem){
    return item.productId
  }
  validarDatosFacturacion(texto:string, notNull:string){
    if(notNull){
      /* return texto.toUpperCase() */
      return texto
    }else{
      return ""
    }
  }
  checkCombo(list:IPurchaseItem[]){
    var thereIsCombo=false;
    list.forEach(product =>{
      if(product.isInCombo){
        thereIsCombo=true;
      }
    })
    return thereIsCombo;
  }
  getImporte(unitPrice:number, quantity:number, userQuantity:number, userCost:number){
    // console.log(unitPrice,quantity,userQuantity,userCost)
    if(userCost){
      return (unitPrice+(userQuantity-1)*userCost)*quantity
    }else{
      return unitPrice*quantity
    }
    
  }

  getPrecioUnitario(unitPrice:number, quantity:number, userQuantity:number, userCost:number){
    // console.log(unitPrice,quantity,userQuantity,userCost)
    if(userCost){
      return (unitPrice+(userQuantity-1)*userCost)
    }else{
      return unitPrice
    }
    
  }
  regresar(){
    ////console.log("El botón se presionó")
    localStorage.setItem('modalOpen', 'true');
    this.router.navigate(['/compras']);
  }
  getLicenciamiento(type:boolean){
    return type? "Mono-empresa":"Multi-empresa"
  }
  checkCard(input: string): string {
    let tipo:string ="";
    const firstChar = input.charAt(0);
    if(firstChar === '4' ){
      tipo = "VISA";
    }
    if(firstChar === '5'){
      tipo = "MASTERCARD";
    }
    return tipo;
}
getmetadata(meta:any){
  return meta;
}
}
