// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from "./common_environment";

export const environment = {
  production: false,
  isSignupAllowed: true,
  common: commonEnvironment,
  source: 'APP',
  datadogService: 'shop-sithec-web',
  datadogRecording: false,
  firebase: {
    apiKey: "AIzaSyAJMpya3lUfcfDZ8VogySyOhS2IrTKycRg",
    authDomain: "shop-sithec.firebaseapp.com",
    projectId: "shop-sithec",
    storageBucket: "shop-sithec.appspot.com",
    messagingSenderId: "532584313503",
    appId: "1:532584313503:web:fc9db73676148b60c4e8aa",
    measurementId: "G-9T9J2N651Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
