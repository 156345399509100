<div class="card m-0 mx-2 main-widget main-widget-product">
  <div class="view main-widget-img">
    <img
      class="card-img-top img-fluid m-5 mx-auto"
      [src]="configurationCard.headerImage"
      alt="Bancos"
    />
  </div>
  <div
    class="card-body p-4 main-widget-product-gradient-accounting"
    [ngStyle]="{ 'background-image': backgroundColor }"
  >
    <h4 class="white-text titulo-estilado text-center">
      {{ configurationCard.name }}
    </h4>
    <div class="overlay">
      <div class="d-flex flex-column my-2">
        <p class="white-text titulo">{{ configurationCard.name }}</p>
        <p class="descripcion">
          <span
            class="text-center text"
            *ngIf="configurationCard.textInformation.length > 0"
          >
            {{ configurationCard.textInformation }} 
          </span>
        </p>
        <div class="w-100 d-flex justify-content-center">
          <app-button
            class="app-button-rounded app-button-secondary hover-button w-80 d-flex justify-content-center ajuste"
            [label]="'CART.SEE' | translate"
            type="submit"
            (onClick)="addCard()"
          >
          </app-button>
        </div>
      </div>
    </div>
    <span #content>
      <ng-content></ng-content>
    </span>
  </div>
</div>
