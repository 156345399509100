<div [ngClass]="['form-check', className]">
  <input
    type="checkbox"
    [id]="id"
    class="form-check-input"
    [checked]="selected"
    (input)="change($event)"
  />
  <label *ngIf="!!label" class="form-label-check" [for]="id">
    {{ label | translate }}</label
  >
</div>