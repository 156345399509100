<div class="cuerpo">
  <div class="go-back" [hidden]="isMobileView">
    <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()" id="imgCONTPAQi" >
</div>
    
    <lib-product-card (tipoLicenciaSeleccionada)="actualizarSeleccion($event)" (nombreProducto)="getNombre($event)" [hidden]="isMobileView && isSelected"></lib-product-card>
    <div [hidden]="isMobileView && !isSelected">
      <div *ngIf="tipoSeleccionado === 'nuevo'">
        <app-new-license (selection)="updateSelected($event)" [nombreProducto]="nombrePadre"></app-new-license>
      </div>
      <div *ngIf="tipoSeleccionado === 'renovacion'">
          <app-renovaciones (selection)="updateSelected($event)" ></app-renovaciones>
        </div>
        <div *ngIf="tipoSeleccionado === 'cambio'">
          <app-change (selection)="updateSelected($event)"></app-change>
        </div>
      </div>
</div>