import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ShoppingCartComponent } from "./shopping-cart.component";
import { CommonModule } from "@angular/common";

const routes: Routes = [
    {
        path: '',
        component: ShoppingCartComponent,
    }
];

@NgModule({
    imports: [CommonModule,RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class CartRoutingModule {
}