<div class="contenido">
    <div  class="recuadro">
        <div class="arriba row">
            <div class="col-2">
                <p class="titulo">Mis licencias</p>
            </div>
            <div class="col-10">
                <input class="buscar search-bar" type="text" placeholder="Buscar" [formControl]="search">
            </div>
        </div>
        <div class="contenedor-tabla">
            <table class="table table-striped">
                <thead class="color-fondo"> 
                    <tr>
                        
                        <th class="fecha" scope="col">Folio</th>
                        <th class="fecha" scope="col">Fecha</th>
                        <th scope="col">Productos</th>
                        <th class="fecha" scope="col">Vigencia</th>
                        <th class="fecha" scope="col">Descargar Certificado</th>
                    </tr>
                </thead>
                <tbody *ngFor="let detail of purchaseDetail; trackBy: trackDetails">
                    <tr>
                        
                        <td class="fecha"><p>{{detail.purchase.folio}}</p></td>
                        <td class="fecha">{{detail.purchase.date| date:'dd/MM/yyyy'}}</td>
                        <td class="fecha">
                            <div *ngIf="detail.isMono ; else mono">
                                <p>{{detail.name}} Mono-empresa  {{detail.users}} Usuario(s) <span *ngIf="detail.license ">[{{detail.license}}]</span> </p>
                            </div>
                            <ng-template #mono>
                                <p>{{detail.name}} Multi-empresa {{detail.users}} Usuario(s) <span *ngIf="detail.license ">[{{detail.license}}]</span> </p>
                            </ng-template>
                        </td>
                        <td class="fecha"><p *ngIf="detail.effectiveDate;else datenull">{{detail.effectiveDate | date:'dd/MM/yyyy'}}</p>
                                        <ng-template #datenull> <p>Por Definir</p></ng-template>
                        </td>
                        <td class="fecha descargar"><img [src]="getIcon(detail.certificado)" alt="" (click)="checkCertificado(detail.certificado, detail.id)"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</div>
