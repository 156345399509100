import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrMessageService } from "../../services/message.service";
import { ShoppingCarServiceService } from "../../services/shopping-car-service.service";
import { ICardInformationConfiguration } from "../../models/product.model";
import { Router } from "@angular/router";
import { PlatformUtil } from '../../models/platformUtil';

@Component({
  selector: "app-shopping-cart",
  templateUrl: "./shopping-cart.component.html",
  styleUrls: ["./shopping-cart.component.less"],
})
export class ShoppingCartComponent implements OnInit {
  boolUser: boolean = false;
  cartSelected: boolean = true;
  products: any[] = [];
  combo: any[] = [];
  contadorCombo:number = 0
  emptyCartBool: boolean = false;
  confirmAction: boolean = false;

  constructor(private router:Router, private messageService: ToastrMessageService,private localStorageService: ShoppingCarServiceService, 
    public platformUtil: PlatformUtil) {}

  redirigir(){
    this.router.navigate(['/landing']);
  } 
  ngOnInit(): void {
    if(this.platformUtil.isBrowser()){
      let auxUsr = JSON.parse(localStorage.getItem("user") || "{}");
      this.boolUser = auxUsr && auxUsr.username;
      /* if (this.boolUser) {
         
      } */
      this.fnGetProductsAvailable();
      this.checkIsEmpty();
    }
  }

  fnGetProductsAvailable() {
    let info:any[] = []
    this.localStorageService.getCarAsObservable().subscribe((item) => {
      info.push(item);
    });
    this.combo = [[]]
    this.products = [[]]
    info[0].forEach((product:ICardInformationConfiguration) => {
      if(product.isInCombo){
        this.combo[0].push(product);
        this.contadorCombo++;
      }else{
        this.products[0].push(product);
      }
    });
  }

  checkForUser() {   
      if (this.boolUser) {
        if(!this.emptyCartBool){
          if(this.contadorCombo<2 && this.contadorCombo!=0){
            this.messageService.showWarn("El combo debe de contar con al menos dos productos");
          }else{
            this.cartSelected = false;
          }
          
        }else{
          this.messageService.showWarn("Su carrito está vacío");
        }
      } else {
        //this.messageService.showWarn("Se requiere inicio de sesión!");
        if(!this.emptyCartBool){
          localStorage.setItem("cart", "1")
          this.router.navigate(["account/authentication"]);
        }
      }
  }

  checkForUserClickConfirm(){
    this.confirmAction = true;
    // resetear el estado después de la confirmación
    setTimeout(() => {
      this.confirmAction = false;
    }, 0);
  }

  checkIsEmpty() {
    this.products[0].length == 0 && this.combo[0].length == 0
      ? (this.emptyCartBool = true)
      : (this.emptyCartBool = false);
  }
  
}
