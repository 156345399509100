import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
// import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { ToastrMessageService } from '../../services/message.service';
import { Router } from '@angular/router';
import { Auth, RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from '@angular/fire/auth';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { SithecCommonApiService } from '../../services/sithec-common-api.service';

@Component({
  selector: 'app-verifica-celular',
  templateUrl: './verifica-celular.component.html',
  styleUrls: ['./verifica-celular.component.less']
})
export class VerificaCelularComponent implements OnInit {

  //public auth = getAuth();
  public submitted = false;
  private recaptchaVerifier!: RecaptchaVerifier;
  confirmationResult: ConfirmationResult | undefined;
  verificationCode: string = '';
  phoneNumber: string = '';
  verifyForm: FormGroup = new FormGroup(
    {
      code: new FormControl('', Validators.required)
    }
  );

  

  constructor(
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private auth: Auth,
    private toastrService:ToastrMessageService,
    private router: Router,
    private sithecCommonApiService:SithecCommonApiService,
    ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(){
    this.setUpRecaptcha();
    this.phoneNumber = this.route.snapshot.params['phone'];
    if(this.phoneNumber){
      this.sendLoginCode();
    }
  }

  setUpRecaptcha() {
    this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response:any) => {
        // reCAPTCHA solved, you can now proceed with phone number verification
        //console.log("solved")
      }
    }, this.auth);

    this.recaptchaVerifier.render().then((widgetId) => {
      // Store widgetId if you need to reset the reCAPTCHA later
    });
  }

  sendLoginCode() {
    signInWithPhoneNumber(this.auth, this.phoneNumber, this.recaptchaVerifier)
      .then(confirmationResult => {
        //console.log(confirmationResult)
        this.confirmationResult = confirmationResult;
      })
      .catch(error => {
        //console.log(error)
        this.toastrService.showError("Hubo un error")
      });
  }

  verifyLoginCode() {
    if (!this.confirmationResult) {
      //console.log('No confirmationResult available');
      return;
    }

    this.confirmationResult.confirm(this.verifyForm.get('code')?.value)
      .then(userCredential => {
        //console.log(userCredential)
        // User successfully signed in
        // You can now obtain the user's information from userCredential.user
        var signUpInformation = localStorage.getItem("SignUpInformation")
        var signUpInformationObject = JSON.parse(signUpInformation!);

        signUpInformationObject["tokenFirebase"] = userCredential.user.uid

        this.spinnerService.show()
        
        this.sithecCommonApiService
        .signUpSMS(signUpInformationObject)
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(
          () => {
            localStorage.removeItem("SignUpInformation")
            this.toastrService.showSuccess("Número confirmado exitosamente");
            this.router.navigate(["/account/authentication"]);
          },
          (errorDefinition:any) => this.handleError(errorDefinition)
        );
      })
      .catch(error => {
        //console.log(error)
        this.toastrService.showError("Código incorrecto");
        // User couldn't sign in (bad verification code?)
      });
  }

  handleError(error: any) { 
    this.toastrService.showError(error.message) 
  }

  submit(){
    if(this.verifyForm.invalid){
      this.submitted = true;
      return;
    }

    this.submitted = false;
    
    this.verifyLoginCode()
  }

}
