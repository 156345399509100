import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ICardInformationConfiguration } from '../../../models/product.model';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ProductInformationService } from '../../../services/product-information.service';
import { Router } from "@angular/router";
import { ToastrMessageService } from '../../../services/message.service';
import { ISwitch } from '../../../models/switch.model';
import { ShoppingCarServiceService } from '../../../services/shopping-car-service.service';
import { filter } from 'rxjs/operators';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { AdminApiServiceService } from 'src/app/services/admin-api-service.service';

@Component({
  selector: 'app-new-license',
  templateUrl: './new-license.component.html',
  styleUrls: ['./new-license.component.less']
})
export class NewLicenseComponent implements OnInit, OnDestroy {
  producto!:ICardInformationConfiguration;
  precioMulti!:number;
  precioMono!:number;
  totalMulti!:number;
  totalMono!:number;
  usuarioMulti!:number;
  usuarioMono!:number;
  mostrarMulti!:boolean;
  mostrarInformacionMulti!:boolean;
  mostrarUsuariosMulti!:boolean;
  mostrarMono!:boolean;
  mostrarInformacionMono!:boolean;
  mostrarUsuariosMono!:boolean;
  seleccion!:string;
  seleccionable!:boolean;
  seleccionado!:string;

  precio!:number;
  total!:number;
  costoUsuarioAdicional!:number;
  usuarios!:number;
  mostrarLicencias!:boolean;
  mostrarCombo!:boolean;
  productForm: FormGroup = new FormGroup(
    {
      licencia: new FormControl(),
      compra: new FormControl(),
    }
  );
  idProduct = "";

  @Input() nombreProducto: string = "";
  isMobileView:boolean = false;
  @Output() selection = new EventEmitter<boolean>();
  imagen:string = "";

  showAnimation: boolean = false;
 constructor(
    private shoppingCarService: ShoppingCarServiceService,
    private productService:ProductInformationService,
    private productInformation: ProductInformationService,
    private router:Router,
    private message:ToastrMessageService,
    private apiService: AdminApiServiceService,
    ) {
      let id = sessionStorage.getItem('productID')
      if(id)
      this.idProduct = id.toString();
      if (this.idProduct) {
        this.getProductById();
      }

  }

  cambiarSeleccion(valor:any){
    this.seleccionado=this.productForm.controls['licencia'].value;
    this.alternar();
  }

  cambiarCombo(valor:any){
    if(this.productForm.controls['compra'].value=='1'){
      this.producto.isInCombo=false;
    }else{
      this.producto.isInCombo=true;
    } 
    //console.log(this.producto)
  }

  addQuantity(){
    this.usuarios++;
    this.total+=this.costoUsuarioAdicional
  }
  removeQuantity(){
    if(this.usuarios>1){
      this.usuarios--;
      this.total-=this.costoUsuarioAdicional
    }
  }

  public addCard() {
    if(this.seleccion == "Multiempresa"){
      this.producto.tipoLicencia = 1;
      this.producto.usersQuantity = this.usuarios;
      this.producto.selectedCost = this.producto.costMultiRFC;
      if(this.usuarioMulti!=null){
        this.producto.costMultiRFCUA = this.usuarioMulti
      }else{
        this.producto.costMultiRFCUA = 0;
      }
      this.producto.isMulti=true;
      this.producto.isMono=false;
    }else{
      this.producto.tipoLicencia = 1;
      this.producto.usersQuantity = this.usuarios;
      this.producto.selectedCost = this.producto.costMonoRFC;
      this.producto.costMonoRFCUA = this.usuarioMono
      if(this.usuarioMono!=null){
        this.producto.costMonoRFCUA = this.usuarioMono
      }else{
        this.producto.costMonoRFCUA = 0;
      }
      this.producto.isMulti=false;
      this.producto.isMono=true;
    }
    this.shoppingCarService.setItemLocalStorage(this.producto);
    
    if(!this.isMobileView){
      this.showAnimation = true;
      setTimeout(() => {
        const animatedImage = document.getElementById('animatedImage');
        if (animatedImage) {
          animatedImage.classList.add('animate-move');
        }
      }, 50);
  
      setTimeout(() => {
        this.showAnimation = false;
        this.goBack2();
      }, 1050);
    }
    else{
      this.message.showInfo('Producto añadido exitosamente');
      this.goBack2();
    }
  }
  ngOnInit(): void {
    this.getProductById();
        this.producto = this.productService.getProducto();
    this.checkViewport();
  }
  ngOnDestroy():void{
    
  }
  cambiar(data:ISwitch){
    this.alternar();
  }
  alternar(){
    if(this.seleccionado=="1"){
      this.producto.isMono=false;
      this.seleccion="Multiempresa"
      this.costoUsuarioAdicional = this.producto.costMultiRFCUA!
      this.total = this.precioMulti + this.costoUsuarioAdicional*(this.usuarios-1);
    }else{
      this.producto.isMono=true;
      this.seleccion="Monoempresa"
      this.costoUsuarioAdicional = this.producto.costMonoRFCUA!
      this.total = this.precioMono + this.costoUsuarioAdicional*(this.usuarios-1);
    }
  }

  mostrarInfo(mensaje:string){
    this.message.showInfo(mensaje)
  }
  async getProductById() {
    this.apiService
      .getProduct(this.idProduct)
      .subscribe(
        (product: any) => {
          if (product) {
            if (product.headerImage) {
              let name = product.headerImage?.split("/").reverse()[0];
              this.producto = product;
              this.productInformation.setProducto(this.producto);
              this.producto = this.productService.getProducto();
              this.getImage(name)
            }else{
              this.producto = product;
              this.productInformation.setProducto(this.producto);
              this.producto = this.productService.getProducto();
              //console.log("this.producto",this.producto)
            }

            this.constructor2();
          }
        }
      );
  }

  constructor2(){
    const product: ICardInformationConfiguration = this.productService.getProducto();
    this.producto = product;
    this.seleccion="Multiempresa"
    this.usuarios=1
    this.producto.purchaseType="Nueva Licencia";
    this.producto.isInCombo=false;
    //console.log(this.producto)
    this.precioMulti=this.producto.costMultiRFC!;
    this.precioMono=this.producto.costMonoRFC!;
    this.usuarioMulti=this.producto.costMultiRFCUA!;
    this.usuarioMono=this.producto.costMonoRFCUA!;
    this.totalMulti=this.precioMulti;
    this.totalMono=this.precioMono;

    this.productForm.controls['licencia'].setValue('1');
    this.productForm.controls['compra'].setValue('1');

    this.seleccionable=true;
    this.seleccionado="1";
    this.producto.isUnique=false;

    this.total = this.precioMulti;
    this.costoUsuarioAdicional = this.producto.costMultiRFCUA!

    if(this.precioMulti==null){
      this.mostrarMulti=false;
      this.seleccionable=false;
      this.producto.isMulti=false;
      this.producto.isMono=true;
      this.seleccion= "Monoempresa";
      this.total = this.precioMono;
      this.costoUsuarioAdicional = this.producto.costMonoRFCUA!
    }

    if(this.precioMono==null){ 
      this.mostrarMono=false;
      this.seleccionable=false;
      this.producto.isMulti=true;
      this.producto.isMono=false;
      this.seleccion= "Multiempresa";
      this.total = this.precioMulti;
      this.costoUsuarioAdicional = this.producto.costMultiRFCUA!
    }

    if(this.usuarioMulti==null){
      this.mostrarUsuariosMulti=false;
      this.producto.isUnique=true;
    }else{
      this.mostrarUsuariosMulti=true;
    }
    if(this.usuarioMono==null){
      this.mostrarUsuariosMono=false;
      this.producto.isUnique=true;
    }else{
      this.mostrarUsuariosMono=true;
    }
    if(this.mostrarMulti && this.mostrarMono){
      this.alternar();
    }
    this.mostrarLicencias=!this.producto.isUnique;

    /* if(this.producto.name=="XML en Línea+" || this.producto.name=="CFDI Facturación en Línea+"){
      this.mostrarCombo=false;
    }else{
      this.mostrarCombo=true;
    } */

    // if(this.producto.name=="CFDI Facturación en Línea+"){
    //   this.mostrarCombo=false;
    // }else{
      this.mostrarCombo=true;
    // }
    
    if(!this.producto?.productFeatures?.annualLicenseMultiRFC? true: false){
      this.productForm.controls['licencia'].setValue('2');
      this.cambiarSeleccion(2)
    }
  }
  goBack(){
    this.selection.emit(false)
  }
  goBack2(){
    this.router.navigate(['/product/CONTPAQi']);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }
  getImage(name: string) {
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if (response) {
            //Verificar que existe imagen
            this.producto.headerImage = response.result;
            this.imagen = this.producto.headerImage!;
          }
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
}
