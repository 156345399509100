import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export interface PriceData {
  _idProduct: string;
  _quantity: number;
  _users: number;
  _cost:number;
  _aditionalCost:number;
  _discountable: boolean;
  _isInCombo: boolean;
  _productType?: string;
}

export interface ObjTotal {
  _discount: number;
  _iva: number;
  _subtotal: number;
}

@Injectable({
  providedIn: "root",
})
export class PricesService { 
  private prices: PriceData[];
  private globTotal$: Subject<number>;
  private total$: BehaviorSubject<ObjTotal>;
  productsLength: number = 0;

  constructor() {
    this.prices = [];
    this.total$ = new BehaviorSubject({ _discount: 0, _iva: 0, _subtotal: 0 });
    this.globTotal$ = new Subject();
    this.productsLength = 0;
  }

  initPrices(pPrices: PriceData[]) {
    this.prices = pPrices;
  }

  updatePrice(pPrices: PriceData[]) {
    let totalAux: ObjTotal = {
      _discount: 0,
      _iva: 0,
      _subtotal: 0,
    };

    this.initPrices(pPrices);
    this.getProductsLength();
    totalAux = this.fnCalculateTotals();
    this.total$.next(totalAux);
  }

  updateTotal(total: number) {
    this.globTotal$.next(total);
  }

  getProductsLength() {
    this.productsLength = 0;
    this.prices.forEach((price) => {
      this.productsLength += price._quantity;
    });
  }

  fnGetDiscount(res: ObjTotal): number {
    if (this.productsLength >= 2) {
      return res._subtotal * 0.15;
    } else {
      return 0;
    }
  }

  fnGetIVA(res: ObjTotal): number {
   /*  return (res._subtotal-res._discount) * 0.16; */
    if (this.productsLength >= 2) {
      return (res._subtotal-res._discount) * 0.16;
    } else {
      return (res._subtotal) * 0.16;
    }
  }

  fnCalculateTotals(): ObjTotal {
    let res: ObjTotal = {
      _discount: 0,
      _iva: 0,
      _subtotal: 0,
    };
    var temp = 0; 
    this.prices.forEach((priceD: PriceData) => {
      /* res._subtotal += priceD._cost * priceD._quantity +  priceD._users; */
      if(priceD._users==1){
        temp = priceD._cost * priceD._quantity
        res._subtotal += temp;
      }else{
        temp = (priceD._cost +priceD._aditionalCost * (priceD._users-1) )* priceD._quantity 
        res._subtotal += temp;
      }
      if(!priceD._discountable || !priceD._isInCombo){
        this.productsLength--;
      }else{
        //console.log("Descuento antes: "+res._discount)
        //console.log("temp: "+temp)
        res._discount += temp*.15;
        //console.log("Descuento despues: "+res._discount)
      }

    });

    if(this.productsLength<2){
      res._discount = 0
      ////console.log("El supuesto descuento es: "+res._discount)
    }

    /* res._discount = this.fnGetDiscount(res); */

    res._iva = this.fnGetIVA(res);

    return res;
  }

  getObjTotal$(): Observable<ObjTotal> {
    return this.total$.asObservable();
  }

  getObjTotalData(): ObjTotal {
    return this.total$.getValue();
  }

  getTotal$(): Observable<number> {
    return this.globTotal$.asObservable();
  }

  getPrices() {
    return this.prices;
  }
}
