import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ICardInformationConfiguration } from '../../../models/product.model';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ProductInformationService } from '../../../services/product-information.service';
import { Router } from "@angular/router";
import { ToastrMessageService } from '../../../services/message.service';
import { ISwitch } from '../../../models/switch.model';
import { ShoppingCarServiceService } from '../../../services/shopping-car-service.service';
import { ECommerceApiService } from '../../../services/e-commerce-api.service';
import { finalize } from "rxjs/operators";
import { SpinnerService } from '../../../services/spinner.service';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { DataDogService } from '../../../services/datadog.service';
import Swal from 'sweetalert2';
import { AdminApiServiceService } from 'src/app/services/admin-api-service.service';

@Component({
  selector: 'app-renovaciones',
  templateUrl: './renovaciones.component.html',
  styleUrls: ['./renovaciones.component.less']
})
export class RenovacionesComponent implements OnInit {
  
  productType =  [ 
    {
      name: "Nóminas",
      productID: 18
    },
    {
      name: "Contabilidad",
      productID: 11
    },
    {
      name: "Bancos",
      productID: 10
    },
    {
      name: "Comercial Start",
      productID:  9
    },
    {
      name: "Comercial Pro",
      productID: 7
    },
    {
      name: "Comercial Premium",
      productID: 8
    },
    {
      name: "Línea",
      productID: 14
    }
  ]
  
  producto!:ICardInformationConfiguration;
  precioMulti!:number;
  precioMono!:number;
  totalMulti!:number;
  totalMono!:number;
  usuarioMulti!:number;
  usuarioMono!:number;
  usuariosMulti!:number;
  usuariosMono!:number;
  mostrarMulti!:boolean;
  mostrarInformacionMulti!:boolean;
  mostrarUsuariosMulti!:boolean;
  mostrarMono!:boolean;
  mostrarInformacionMono!:boolean;
  mostrarUsuariosMono!:boolean;
  seleccion!:string;
  seleccionable!:boolean;
  seleccionado!:string;
  estatus!:boolean;
  empresa!:string;

  licencia!:string;
  productId!:number;

  propietario!:string;
  registro!:string;
  vigencia!:string;
  usuariosInfo!:string;
  empresas!:string;
  licenciamiento!:string;

  precio!:number;
  total!:number;
  costoUsuarioAdicional!:number;
  usuarios!:number;
  mostrarLicencias!:boolean;
  mostrarCombo!:boolean;

  licenseTypeId!:string

  encontrado!:boolean;
  cotizado!:boolean;

  action:string="el timbrado"

  productForm: FormGroup = new FormGroup(
    {
      compra: new FormControl(),
    }
  );

  DD_EVENT_TRIGGER_RENOVATION = 'RENOVATION_INVOICE'
  DD_EVENT_RENOVATION_SUCCESS = 'RENOVATION_SUCCESS'
  DD_EVENT_RENOVATION_FAILITURE = 'RENOVATION_INVOICE'


  isMobileView:boolean = false;
  @Output() selection = new EventEmitter<boolean>();
  imagen:string = "";

  showAnimation: boolean = false;
  constructor(
    private api2Service: AdminApiServiceService,
    private apiService:ECommerceApiService,
    private spinnerService:SpinnerService, 
    private shoppingCarService: ShoppingCarServiceService,
    private productService:ProductInformationService,
    private router:Router,
    private message:ToastrMessageService,
    private dd: DataDogService
    ) {
    this.encontrado=false
    this.cotizado=false
    this.producto=this.productService.getProducto();
    this.productForm.controls['compra'].setValue('1');
    this.producto.purchaseType="Renovación";
    this.producto.isInCombo=false;
    let name = this.producto.headerImage?.split("/").reverse()[0];
    if(name)
    this.getImage(name)
    /* if(this.producto.name=="XML en Línea+" || this.producto.name=="CFDI Facturación en Línea+"){
      this.mostrarCombo=false;
    }else{
      this.mostrarCombo=true;
    } */

    if(this.producto.name=="CFDI Facturación en Línea+"){
      this.mostrarCombo=false;
    }else{
      this.mostrarCombo=true;
    }
   }

  cambiarSeleccion(valor:any){
    this.seleccionado=valor;
    this.alternar();
  }

  cambiarCombo(valor:any){
    let obj = localStorage.getItem("empresa")
    if(this.productForm.controls['compra'].value=='1'){
      this.producto.isInCombo=false;
    }else{
      if(!obj){
        this.producto.isInCombo=true;
      }else{
        obj = obj.replace('"', '')
        obj = obj.replace('"', '')
        //console.log(obj)
        //console.log(this.empresa)
        if(obj==this.empresa){
          this.producto.isInCombo=true;
        }else{
          this.producto.isInCombo=false;
          this.productForm.controls['compra'].setValue('1');
          this.message.showError("No se puede agregar al combo pues tiene un rfc diferente al de otro producto")
        }
      }
    } 
  }

  addQuantity(){
    this.usuarios++;
    this.total+=this.costoUsuarioAdicional
    this.cotizado=false
  }

  removeQuantity(){
    if(this.usuarios>1){
      this.usuarios--;
      this.total-=this.costoUsuarioAdicional
      this.cotizado=false
    }
  }

  public addCard() {
    if(this.seleccion == "Multiempresa"){
      this.producto.tipoLicencia = 2;
      this.producto.usersQuantity = this.usuarios;
      this.producto.selectedCost = this.total;
      if(this.usuarioMulti!=null){
        this.producto.costMultiRFCUA = this.usuarioMulti
      }else{
        this.producto.costMultiRFCUA = 0;
      }
      this.producto.isMulti=true;
      this.producto.isMono=false;
    }else{
      this.producto.tipoLicencia = 2;
      this.producto.usersQuantity = this.usuarios;
      this.producto.selectedCost = this.total;
      if(this.usuarioMono!=null){
        this.producto.costMonoRFCUA = this.usuarioMono
      }else{
        this.producto.costMonoRFCUA = 0;
      }
      this.producto.isMulti=false;
      this.producto.isMono=true;
    }
    let obj = localStorage.getItem("empresa")
    if(this.producto.isInCombo && !obj){
      localStorage.setItem("empresa", JSON.stringify(this.empresa)) 
    }
    this.shoppingCarService.setItemLocalStorage(this.producto);
    
    if(!this.isMobileView){
      this.showAnimation = true;
      setTimeout(() => {
        const animatedImage = document.getElementById('animatedImage');
        if (animatedImage) {
          animatedImage.classList.add('animate-move');
        }
      }, 50);
  
      setTimeout(() => {
        this.showAnimation = false;
        this.goBack2();
      }, 1050);
    }
    else{
      this.message.showInfo('Producto añadido exitosamente');
      this.goBack2();
    }
  }
  ngOnInit(): void {
    this.checkViewport();
  }
  cambiar(data:ISwitch){
    this.alternar();
  }
  alternar(){
    if(this.seleccionado=="1"){
      this.mostrarMulti=true;
      this.mostrarMono=false;
      this.producto.isMono=false;
      this.producto.costMultiRFCUA = this.precioMulti;
      this.seleccion="Multiempresa"
      
    }else{
      this.mostrarMulti=false;
      this.mostrarMono=true;
      this.producto.isMono=true;
      this.producto.costMonoRFCUA = this.precioMono;
      this.seleccion="Monoempresa"
    }
  }
  getInfoSerialNumber(license:string){
    this.total+=this.costoUsuarioAdicional
    var buscar = true;
    var cartProducts = this.shoppingCarService.getPurchaseItems();
    cartProducts.forEach((element) => {
      if(element.license==license){
        this.message.showError("No es posible cotizar la serie ya que se encuentra agregada en el carrito.")
        buscar = false;  
      }
    });
    if(license.length!=16){
      this.message.showError("La serie debe contar con 16 dígitos alfanuméricos.")
    }else if(buscar){
      this.producto.License=license
      this.spinnerService.show()
      this.licencia=license
      this.encontrado=false
      let productID = 0;
      this.productType.forEach(item =>{
        if(this.isContain(this.producto.name!,item.name))
        {
          productID = item.productID
        }
      })
      this.dd.addAction({action:this.DD_EVENT_TRIGGER_RENOVATION,description:''})
      this.productId=productID
      this.apiService
        .getInfoSerialNumber(license, productID)
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(
            (info:any) => {
              //console.log(info)
              //console.log(info.value.serialNumberInformation)
              //console.log(info.value.licenseInfo) 
              this.propietario=info.value.licenseInfo.registrado_a
              this.empresas=info.value.serialNumberInformation.empresas
              this.usuariosInfo=info.value.serialNumberInformation.no_usuarios
              this.usuarios=info.value.serialNumberInformation.no_usuarios
              this.registro=info.value.serialNumberInformation.fecha_activacion
              this.vigencia=info.value.serialNumberInformation.vigencia
              this.licenciamiento=info.value.serialNumberInformation.version
              this.licenseTypeId=info.value.licenseTypeQuote
              //console.log("License Type Id: "+this.licenseTypeId)
              this.estatus=(parseInt(info.value.serialNumberInformation.dias_restantes)>0)
              this.empresa=info.value.serialNumberInformation.empresa
              this.encontrado=true
              this.seleccion = this.empresas
              this.dd.addAction({action:this.DD_EVENT_RENOVATION_SUCCESS,description:''})
            },
            ({ message }: IErrorDefinition) => {
              this.dd.addAction({action:this.DD_EVENT_RENOVATION_FAILITURE,description:''})
              this.message.showError(message)
            }
          );
    }       
  }

  isContain(name1: string, name2: string): boolean{
    //En minúsculas
    name1 = name1.toLocaleLowerCase();
    name2 = name2.toLocaleLowerCase();
    //Quitar acentos
    name1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    name2.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //Coincide?
    if(name1.includes(name2)){
      return true;
    }
    return false;
  }

  cotizar(){
    this.spinnerService.show()
    this.cotizado=false
    this.apiService
      .getQuote(this.licencia, this.productId, 5, this.usuarios, this.usuariosInfo, this.empresas=="Multiempresa"?2:1, this.empresas=="Multiempresa"?"2":"1", this.licenseTypeId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (info:any) => {
            //console.log(info)
            this.total=info.value.prices.basePrice
            this.cotizado=true
          },
          ({ message }: IErrorDefinition) => this.message.showError(message)
        ); 
  }

  mostrarInfo(mensaje:string){
    this.message.showInfo(mensaje)
  }
  showTutorial(){
    Swal.fire({
      html: `
      <style>
      .custom-swal-modal {
        background: rgba(0, 0, 0, 0.15);
        width: 80vw;
        height: 80vh;
      }
      .iframe {
        width: 75vw;
        height: 75vh;
      }
      </style>
      <iframe class="iframe"  src="https://www.youtube.com/embed/TgbgJGo7BXA?si=pAEFw8ByFKQywX8F" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      `,
      showConfirmButton: false, // Ocultar botón de confirmación
      showCloseButton: true, // Mostrar botón de cerrar
      customClass: {
        popup: 'custom-swal-modal', // Clase CSS personalizada para hacer el modal más ancho
      },
      heightAuto:true
    });
  
  }
  
  goBack(){
    this.selection.emit(false)
  }
  goBack2(){
    this.router.navigate(['/product/CONTPAQi']);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }

  getImage(name: string) {
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.api2Service
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if (response) {
            //Verificar que existe imagen
            this.producto.headerImage = response.result;
            this.imagen = this.producto.headerImage!;
          }
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
}
