<div class="contenido">
    <div  class="recuadro">
        <div class="arriba row">
            <div class="col-2">
                <p class="titulo">Mis Compras</p>
            </div>
            <div class="col-10">
                <input class="buscar search-bar" type="text" placeholder="Buscar" [(ngModel)]="busqueda">
            </div>
        </div>
        <div class="contenedor-tabla justify-content-center">
            <table class="table table-striped justify-content-center" style="background-color: transparent;">
                <thead class="color-fondo justify-content-center"> 
                    <tr>
                        <th class="col" scope="col">Folio</th>
                        <th scope="fecha">Fecha</th>
                        <th scope="col">Importe</th>
                        <th scope="col">Método de Pago</th>
                        <th scope="col">Certificados</th>
                        <th class="col" scope="col">Detalle de Compra</th>
                    </tr>
                </thead>
                <tbody *ngFor="let purchase of purchasesData | filterPurchase: busqueda; trackBy: trackPurchase">
                    <tr>
                        <td class="folio"><p>{{purchase?.folio}}</p></td>
                        <td class="fecha"><p>{{purchase?.date | date:'dd/MM/yyyy'}}</p></td>
                        <td class="total"><p>{{purchase?.total | currency }}</p></td>
                        <!-- <td >
                            <div *ngFor="let detail of purchase.details; trackBy: trackDetails">
                                <div *ngIf="!detail.isInCombo">
                                    <p>CONTPAQi {{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                </div>
                            </div>
                            <p class="negritas" *ngIf="checkCombo(purchase.details)">COMBO</p>
                            <div *ngFor="let detail of purchase.details; trackBy: trackDetails">
                                <div *ngIf="detail.isInCombo" class="tab-combo-content">
                                    <p>CONTPAQi {{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                </div>
                            </div> 
                        </td>-->
                        <!-- <td class="tarjeta"><p>Tarjeta: --{{purchase.card[purchase.card.length-2]}}{{purchase.card[purchase.card.length-1]}}</p></td> -->
                        <td class="tarjeta"><p>{{(purchase?.paymentMethod == 'debit')?'Tarjeta de Débito':((purchase?.paymentMethod == 'credit')?'Tarjeta de Crédito':'')}}</p></td>
                        <td class="fecha">
                            <div *ngIf="!purchase?.certificado;else certificado">
                                N/A
                            </div>
                            <ng-template #certificado>
                                <div *ngIf="purchase?.certificado; else pendiente">
                                    <button class="btn btn-primary" (click)="mostrarLicencias(purchase)">Descargar</button>
                                </div>
                                <ng-template #pendiente>
                                    <button class="btn btn-danger" disabled>Pendiente</button>
                                </ng-template>
                            </ng-template>
                        </td>
                        <td class="total"><img src="../../../../../assets/images/ver detalle.svg" alt="detalles" class="verDetalles" (click)="abrirModal(purchase)">
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Modal detalles -->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrar}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header"  style="justify-content: end;">
            <div class="col-1" ><img src="../../../../../assets/images/out_modal.svg" alt="" (click)="cerrar()"></div>
        </div>
        <section class="detalles">
            <div class="ajuste-detalle row">
                <div class="col-6"><h5 class="modal-title">Detalle de Compra</h5></div>
                <div class="col-4"><p class="modal-folio">Folio: {{purchaseModal.folio}}</p></div>
            </div>
            <div class="detalle-compra row">
                <div class="col-6">
                    <p class="detalle-info">Fecha: {{purchaseModal.date | date:'dd/MM/yyyy'}}</p>
                    <div class="separacion-pago">
                        <p class="detalle-info">
                            <span *ngIf="purchaseModal.paymentMethod === 'credit'">
                                Tarjeta de Crédito
                            </span>
                            <span *ngIf="purchaseModal.paymentMethod === 'debit'">
                                Tarjeta de Débito
                            </span>
                            {{checkCard(purchaseModal.card)}}****{{purchaseModal.card.substring(purchaseModal.card.length-4)}}</p>
                        <p class="detalle-info" *ngIf="purchaseModal.paymentMethod === 'credit'">{{purchaseModal.bank}} pago a {{purchaseModal.months}} meses</p>
                    </div>
                </div>
                <div class="col-4">
                    <p class="detalle-info"> RFC: {{purchaseModal.user.rfc}}</p>
                </div>
            </div>
            <div class="linea"></div>
            <div class="modal-title-adjustment">
                <h5 class="modal-title-style negritas" *ngIf="checkProducts(purchaseDetail)">Productos</h5>
            </div>
            <div *ngFor="let detail of purchaseModal.details">
                <div *ngIf="!detail.isInCombo;">
                    <div class="detalle-productos">
                        <table class="producto">
                            <tr class="row renglon-table">
                                <td class="col-1">
                                    <p class="detalle-info">{{detail.quantity}}</p>
                                </td>
                                <td *ngIf="!detail.purchaseType" class="col-6 ajuste-nombres">
                                    <p class="detalle-info">{{detail.name}}</p>
                                     <p class="detalle-info" *ngIf="!detail.isInCombo && detail.metadata"> {{getmetadata(detail.metadata)}}</p>
                                </td>
                                <td *ngIf="detail.purchaseType" class="col-6 ajuste-nombres">
                                    <p class="detalle-info">{{detail.name}} {{getLicenciamiento(detail.isMono)}}:</p>
                                    <p class="detalle-info">{{detail.users}} Usuario(s) [{{detail.purchaseType}}]</p>
                                    <p class="detalle-info" *ngIf="detail.license">Licencia: {{detail.license}} </p>
                                </td>
                                <td class="col-5 detalle-precios">
                                    <p class="detalle-info">{{(
                                        (detail.unitPrice + ((detail.users - 1) * (detail.usersCost || 0)))
                                        ) | currency}}</p>
                                    <p class="detalle-info">{{(
                                        (detail.unitPrice + ((detail.users - 1) * (detail.usersCost || 0)))*detail.quantity
                                        ) | currency}}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </div>
                </div>

            </div>
            
            <div class="modal-title-adjustment">
                <h5 class="modal-title-style negritas" *ngIf="checkCombo(purchaseDetail)">Combo</h5>
            </div>
            <div *ngFor="let detail of purchaseDetail">
                <div *ngIf="detail.isInCombo">
                    <div class="detalle-productos">
                        <table class="producto">
                            <tr class="row renglon-table">
                                <td class="col-1">
                                    <p class="detalle-info">{{detail.quantity}}</p>
                                </td>
                                <td class="col-6 ajuste-nombres">
                                    <p class="detalle-info">{{detail.name}} {{getLicenciamiento(detail.isMono)}}:</p>
                                    <p class="detalle-info">{{detail.users}} Usuario(s) [{{detail.purchaseType}}]</p>
                                    <p class="detalle-info" *ngIf="detail.license">Licencia: {{detail.license}} </p>
                                </td>
                                    
                                <td class="col-5 detalle-precios">
                                    <p class="detalle-info">{{(
                                        (detail.unitPrice + ((detail.users - 1) * (detail.usersCost || 0)))
                                        ) | currency}}</p>
                                    <p class="detalle-info">{{(
                                        (detail.unitPrice + ((detail.users - 1) * (detail.usersCost || 0)))*detail.quantity
                                        ) | currency}}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>

            <div class="linea"></div>
            <div class="row align-items-center">
                <div class="col-7"></div>
                <table class="detalle-toita col-3 align-self-end">
                    <tr>
                        <td class="etiquetas">
                            <p class="detalle-info">Subtotal:</p>
                        </td>
                        <td class="valores">
                            <p class="detalle-info">{{ (purchaseModal.total - purchaseModal.iva - comission + purchaseModal.discount) | currency}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="etiquetas">
                            <p class="detalle-info">Descuento:</p>
                        </td>
                        <td class="valores">
                            <p class="detalle-info">{{purchaseModal.discount | currency}}</p>
                        </td>
                    </tr>
                    <tr *ngIf="monthly">
                        <td class="etiquetas">
                            <p class="detalle-info">Comisión:</p>
                        </td>
                        <td class="valores">
                            <p class="detalle-info">{{comission | currency}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="etiquetas">
                            <p class="detalle-info">IVA:</p>
                        </td>
                        <td class="valores">
                            <p class="detalle-info">{{purchaseModal.iva | currency}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="etiquetas">
                            <p class="negritas detalle-info">Total: </p>
                        </td>
                        <td class="valores">
                            <p class="negritas detalle-info">{{purchaseModal.total | currency}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </section>


        <section class="botones factura-normal">
            <div class="row">
                <div class="col-12">
                    <p class="etiqueta-factura">Factura:</p>
                    <br>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="boton-recibo">
                        <app-button
                            class="full-width app-button-rounded app-button-secondary hover-button ajuste-recibos"
                            [label]="'Descargar Recibo de Pago' | translate"
                            type="submit"
                            (onClick)="redirigir()"
                        ></app-button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="opciones-factura">
                        <div class="barra-vertical"></div>
                        <div class="container">
                            <div class="row" *ngIf="!facturadoPDF && !facturadoXML">
                                <div class="col">
                                    <div class="callout callout-warning">
                                        <b>Estamos trabajando en tu factura, pronto estará disponible para descarga</b>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-6">
                                    <button
                                        [ngClass]="{'button-danger':facturadoPDF,'button-disabled':!facturadoPDF}"
                                        [disabled]="!facturadoPDF"
                                        label="PDF"
                                        type="submit"
                                        (click)="checkFacturadoPDF()"
                                    >
                                        {{"PDF"|translate}}
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button
                                        [ngClass]="{'button-success':facturadoXML,'button-disabled':!facturadoXML}"
                                        [disabled]="!facturadoXML"
                                        label="XML"
                                        type="submit"
                                        (click)="checkFacturadoXML()"
                                    >
                                        {{"XML"|translate}}
                                    </button>
                                </div>
                            </div>
                        </div>       
                    </div>
                </div>
            </div>
            <div class="row"></div>
            
            
          
        </section>
        <section class="factura-responsive">
            
            <div class="opciones-factura">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <p class="etiqueta-factura">Factura:</p>
                        </div>
                        
                    </div>
                    <div class=" row">
                        <div class="col-12">
                            <app-button
                            class="full-width app-button-rounded app-button-secondary hover-button ajuste-recibos"
                            [label]="'Descargar Recibo de Pago' | translate"
                            type="submit"
                            (onClick)="redirigir()"
                        ></app-button>
                        </div>
                        
                    </div>
                    <div class="row" *ngIf="!facturadoPDF && !facturadoXML">
                        <div class="col">
                            <div class="callout callout-warning">
                                <b>Estamos trabajando en tu factura, pronto estará disponible para descarga</b>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-6">
                            <button
                                [ngClass]="{'button-danger':facturadoPDF,'button-disabled':!facturadoPDF}"
                                [disabled]="!facturadoPDF"
                                label="PDF"
                                type="submit"
                                (click)="checkFacturadoPDF()"
                            >
                                {{"PDF"|translate}}
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                [ngClass]="{'button-success':facturadoXML,'button-disabled':!facturadoXML}"
                                [disabled]="!facturadoXML"
                                label="XML"
                                type="submit"
                                (click)="checkFacturadoXML()"
                            >
                                {{"XML"|translate}}
                            </button>
                        </div>
                    </div>
                </div>       
            </div>
        </section>
      </div>
    </div>
</div>
    
   
