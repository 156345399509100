<div class="row bgProductos" *ngIf="filteredBanners2.length == 0">
  <div class="d-flex justify-content-center align-items-center aspect-ratio-content">
    <div class="col-sm-6 col-xs-6">
      <div class="image-ilustration">
        <img src="/assets/images/ilustracion.png" alt="">
      </div>
    </div>
    <div class="col-sm-6 col-xs-6 title">
      <div class="titulos">
        <p class="encabezado"><span class="shop">SHOP SITHEC:</span> <br> <span class="dale">Dale </span> <span class="bold dale">Presición Digital <br> a Tu negocio.</span></p>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="filteredBanners2.length == 1" [hidden]="isMobileView">
  <img [src]="imageURL[0].urlImage" alt="" (click)="redirectTo(imageURL[0].urlRedirect)">
</div>

<div class="row" *ngIf="filteredBanners2.length == 1" [hidden]="!isMobileView">
  <img [src]="imageURL[0].urlImageMobile" alt="" (click)="redirectTo(imageURL[0].urlRedirect)">
</div>

<div class="row" *ngIf="filteredBanners2.length > 1" [hidden]="isMobileView">
  <div id="carouselExampleIndicators" class="carousel slide without-space">
    <ol class="carousel-indicators-modify">
      <li *ngFor="let image of imageURL; let i = index" [ngClass]="{'active': i === currentIndex}" (click)="currentIndex = i"></li>
    </ol>
    <div class="carousel-inner">
      <ng-container *ngFor="let image of imageURL; let i = index">
        <div class="carousel-item" [ngClass]="{'active': i === currentIndex}">
          <img class="d-block w-100" [src]="image.urlImage" alt="Banner" (click)="redirectTo(image.urlRedirect)" >
        </div>
      </ng-container>
    </div>
    <!-- <a class="carousel-control-prev" role="button" data-slide="prev" (click)="prevCarousel()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" role="button" data-slide="next" (click)="nextCarousel()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> -->
    <!-- <div class="carousel-controls">
      <button class="play-pause-btn" (click)="toggleCarousel()">
        <img *ngIf="!isPaused" src="/assets/images/carousel-play.svg" alt="">
        <img *ngIf="isPaused" src="/assets/images/carousel-paused.svg" alt="">
      </button> 
    </div> -->
  </div>
</div>

<div class="row" *ngIf="filteredBanners2.length > 1" [hidden]="!isMobileView">
  <div id="carouselExampleIndicators" class="carousel slide without-space">
    <ol class="carousel-indicators-modify-mobile">
      <li *ngFor="let image of imageURL; let i = index" [ngClass]="{'active': i === currentIndex}" (click)="currentIndex = i"></li>
    </ol>
    <div class="carousel-inner">
      <ng-container *ngFor="let image of imageURL; let i = index">
        <div class="carousel-item" [ngClass]="{'active': i === currentIndex}">
          <img class="d-block w-100" [src]="image.urlImageMobile" alt="Banner" (click)="redirectTo(image.urlRedirect)" >
        </div>
      </ng-container>
    </div>
    <!-- <a class="carousel-control-prev" role="button" data-slide="prev" (click)="prevCarousel()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" role="button" data-slide="next" (click)="nextCarousel()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
    <div class="carousel-controls" [hidden]="isMobileView">
      <button class="play-pause-btn" (click)="toggleCarousel()">
        <img *ngIf="!isPaused" src="/assets/images/carousel-play.svg" alt="">
        <img *ngIf="isPaused" src="/assets/images/carousel-paused.svg" alt="">
      </button> 
    </div> -->
  </div>
</div>

