<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 verify-email-container card mt-4">
   
      <div *ngIf="!requestSent && !showSuccesfullMessage" >

        <p class="title">{{ "AUTH.VERIFY_EMAIL" | translate }}</p>

        <div class="initial-text">
          <p class="text">
            {{ "AUTH.EMAIL_SENDED" | translate }} {{ userEmail }}
            {{ "AUTH.ENTER_NOW" | translate }}
          </p>
        </div>
        <div>
          <div class="otp-container">
            <ng-otp-input
              #verificationCodeInput
              (onInputChange)="onInputVerificationCodeChange($event)"
              [config]="codeInputConfiguration"
            >
            </ng-otp-input>
          </div>
          <div class="button-container">
      
   
            <app-button
            (onClickButton)="redirectToGmail()"
            class="app-button-rounded app-button-text"
            >
            <ng-template pTemplate="content">
              <img alt="logo" src="/assets/images/Gmail.svg"/>
          </ng-template> 
          </app-button>

                <app-button
                (onClickButton)="redirectToOutlook()"
                class="app-button-rounded app-button-text"
                >
                <ng-template pTemplate="content">
                  <img alt="logo" src="/assets/images/Outlook.svg"/>
              </ng-template>
              </app-button>

         
          </div>
        
      
        </div>

      </div>
      <div *ngIf="requestSent">
        <h2 class="title">
          {{ "AUTH.SENDED_CODE" | translate }}
        </h2>
        <div class="my-4">
          <p class="text">
            {{ "AUTH.EMAIL_SENDED" | translate }}
            <strong>
              {{ userEmail }}
            </strong>
          </p>
        </div>
        <app-button
          class="primary mt-4"
      
          [label]="'AUTH.NEW_CODE'"
          (onClickButton)="returnVerifyEmail()"
        >
        </app-button>
      </div>

      <div *ngIf="showSuccesfullMessage" class="card">
        <h2 class="title">
          {{ "AUTH.VERIFIED_ACCOUNT" | translate }}
        </h2>
        <app-button
          class="app-button full-width"
          (onClickButton)="redirectToDashboard()"
         
          [label]="'COMMON.RETURN_HOME'"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>