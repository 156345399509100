import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISwitch } from '../../../models/switch.model';

@Component({
  selector: 'form-switch',
  templateUrl: './form-switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSwitchComponent),
      multi: true,
    },
  ],
  styleUrls: ['./form-switch.component.less']
})
export class FormSwitchComponent implements ControlValueAccessor {
  
  @Input() value: any;
  @Input() disabled: boolean = false;
  @Input() leftLabel = '';
  @Input() rightLabel = '';
  @Input("class") className = '';
  @Input() name: string | number = '';
  @Input() control: any;
  
  @Output() onChangeElement: EventEmitter<ISwitch> = new EventEmitter<ISwitch>();

  public onChange(val: any): void {}

  constructor() { }

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  change(event: any): void { 
    this.value = event.target.checked;
      this.onChange(this.value);
      this.onChangeElement.emit({
        id: event.target.name,
        value: event.target.checked
      });
  }
}