import { Component, OnInit } from '@angular/core';
import { PlatformUtil } from 'src/app/models/platformUtil';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less']
})
export class LandingComponent implements OnInit { 
  constructor(private platformUtil: PlatformUtil) { 
    //console.log("LandingComponent")
  }

  ngOnInit(): void {
    if(this.platformUtil.isBrowser()){
      sessionStorage.removeItem("prodCatName")
      sessionStorage.removeItem("productID")
    }
  }

}
