import { Injectable } from '@angular/core';
import { IPurchaseData } from '../models/purchase.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInformationService {

  private purchase!: IPurchaseData

  PURCHASE = 'custom:last:purchase'

  constructor() { }

  setPurchase(purchase:IPurchaseData){
    this.purchase=purchase
    localStorage.setItem(this.PURCHASE, JSON.stringify(this.purchase)) 
  }
  getPurchase(){
    return this.purchase ? this.purchase : this.getFromLS(this.PURCHASE);
  }
  getFromLS(key:string){
    const obj = localStorage.getItem(key)
    if(!obj){
      return null;
    }
    return JSON.parse(obj)
  }
}
