import { Injectable } from '@angular/core';
import { PlatformUtil } from '../models/platformUtil';

@Injectable({
  providedIn: 'root'
})
export class CfdiServiceService {

  constructor(
    private platformUtil: PlatformUtil
  ) { }

  setCFDI(cfdi:string){
    localStorage.setItem("CFDI", JSON.stringify(cfdi)) 
  }

  getCFDI(){
    return this.getFromLS("CFDI") ? this.getFromLS("CFDI") : "Gastos en General";
  }
  getFromLS(key:string){
    if(this.platformUtil.isBrowser()){
      const obj = localStorage.getItem(key)
      if(!obj){
        return null;
      }
      return JSON.parse(obj)
    }else
      return null;
  }
}
