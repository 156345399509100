import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-chip',
  templateUrl: './form-chip.component.html',
  styleUrls: ['./form-chip.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormChipComponent),
      multi: true,
    },
  ],
})
export class FormChipComponent implements ControlValueAccessor {
  value: any = null;
  
  @Input() id: any;
  @Input("class") className = '';
  @Input() control: any;
  @Input() submitted: boolean = false;

  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() { }

  onChange(val: any): void {}
  onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(event: any): void {
    this.value = event?.target?.textContent;
    this.onChange(this.value);
    this.onTouched();
  }

  remove() {
    this.onRemove.emit(this.id);
  }

  get errorClass() {
    return this.hasErrorInput() ? ` is-invalid-input` : '';
  }

  hasErrorInput():boolean {
    return (
      (this.submitted && this.control?.invalid) ||
      (this.control &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }

}
