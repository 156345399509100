<div class="container-fluid forgot-password-container">
  <div class="row justify-content-center">
  

    <div class="col-lg-4 col-md-6 col-sm-12" *ngIf="!showRequest && !showCode ">
      
      <div class="card">
        <div class="d-flex justify-content-center my-2">
          <img src="/assets/images/logoSithecBlanco.png" alt="" class="image-login" />
    
        </div>
        <p class="title">
          {{ "AUTH.RECOVER_YOUR_PASSWORD" | translate }}
        </p>

        <form
          class="mt-5"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="submit()"
          novalidate
        >
          <form-input
            name="email"
            label="{{ 'AUTH.USER_EMAIL_PHONE' | translate }} "
            [placeholder]="'AUTH.USER_EMAIL_PHONE' | translate"
            type="email"
            formControlName="username"
            [control]="forgotPasswordForm.controls['username']"
            [submitted]="submitted"
            [class]="'center'"
          >
          </form-input>

          <app-button
            class="w-80 d-flex justify-content-center largo-extendido forgot"
            type="submit"
            [label]="'AUTH.RECOVER_YOUR_PASSWORD'"
          >
          </app-button>
          <app-button
          class=" app-button-link full-width "
            (onClick)="redirectToLogin()"
            [label]="'COMMON.CANCEL'"
           
          >
          </app-button>
        </form>
        <div class="account-option" >
          <p class="question">¿Tienes Problemas para Iniciar sesión?</p>
          <p class="question">Envíanos un WhatsApp +52 1 449 120 0294</p>
        </div>
      </div>
      
  </div>

  <div class="col-lg-4 col-md-6 col-sm-12" *ngIf="showCode">
          <div class="card"  [formGroup]="verifyForm" (ngSubmit)="submitCode()" novalidate>
            <div class="d-flex justify-content-center my-2">
              <img src="/assets/images/logoSithecBlanco.png" alt="" class="image-login" />
        
            </div>
              <p class="normal-text">Casi todo está listo...</p>
              <p class="bold-text">Verifica tu cuenta para continuar</p>
              <p class="little-normal-text">Enviamos un SMS para confirmar tu cuenta</p>
              <p class="question">Escribe el código que enviamos a tu celular: {{phoneNumber}}</p>
              <form-input
                    name="code"
                    type="number"
                    formControlName="code"
                    [control]="verifyForm.controls['code']"
                    [submitted]="submitted"
                  >
              </form-input> 
              <p class="little-normal-text">¿No recibiste el código?</p>
              <p class="little-normal-text hypertext" (click)="sendLoginCode()">Reenviar código</p>
              <button class="mail-button verify-button" (click)="verifyLoginCode()">Confirmar</button>
              <!-- <app-button
                  class="app-button full-width"
                  (onClickButton)="verifyLoginCode()"
                  [label]="'COMMON.CONTINUE'"
                >
                </app-button> -->

                <div class="account-option" >
                  <p class="question">¿Tienes Problemas para Iniciar sesión?</p>
                  <p class="question">Envíanos un WhatsApp +52 1 449 120 0294</p>
                </div>
          </div>
      
  </div>
  </div>
  
</div> <!--
<div class="container-fluid forgot-password-container">
  <div class="row justify-content-center">
  
    

    <div class="col-lg-4 col-md-6 col-sm-12" *ngIf="requestSent">
      <div class="card">
        <h2 class="title">
          {{ "AUTH.REQUEST_RECEIVED" | translate }}
        </h2>
        <div>
          <p class="text">
            {{ "AUTH.EMAIL_SEND_TITLE" | translate }}
          </p>
          <p class="text">
            {{ "AUTH.EMAIL_SEND_MESSAGE" | translate }}
          </p>
        </div>
        <app-button
        class="app-button full-width"
          (onClick)="redirectToResetPassword()"
          [label]="'COMMON.CONTINUE'"
       
        >
        </app-button>

      
      </div>
    </div> 
  </div>
</div>

-->
<div id="recaptcha-container-v2"></div>