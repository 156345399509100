<div class="contenido">
    <label *ngIf="!!leftLabel" class="form-check-label text">{{ leftLabel | translate }}</label>
    <div class="form-switch ">
        <input 
        class="form-check-input" 
        type="checkbox" 
        role="switch" 
        (change)="change($event)" 
        [name]="name" 
        [checked]="value"
        [disabled]="disabled">
    </div>
    <label *ngIf="!!rightLabel" class="form-check-label text">{{ rightLabel | translate }}</label>
</div> 
