<!-- Archivo: product-selected.component.html -->

<div *ngIf="!modeCONTPAQi" class="container-product-selected">
    <div class="product-top">
      <div class="go-back">
        <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()">
      </div>
      <div class="image-category">
        <img  id="productoImage" [src]="'../../../../../../assets/images/'+productType+'.svg'" [alt]="productType">
       
      </div>
    </div>
    <div class="product-down">
      <div class="row">
        <div class="col-6 left"  [hidden]="productSelected !== undefined && isMobileView">
          <div class="top indication">
            Selecciona tu producto
          </div>
          <div class="down row">
            <div class="col-6 item-product" *ngFor="let product of productList" (click)="selectProduct(product)">
              <div class="info-product" [ngClass]="{'selected':product.id == productSelected?.id}">
                <div class="name">
                  {{product?.name}}
                </div>
                <div class="price">
                  {{product?.price | currency}} MXN
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 right" [hidden]="productSelected === undefined  && isMobileView">
          <div class="top category-and-product">
            <div class="row">
              <div class="col-6 category-logo">
                <img [src]="productSelected?.headerImage" alt="Product" class="img-product">
              </div>
              <div class="col-6 product-name">
                {{productSelected?.name}}
              </div>
            </div>
          </div>
          <div class="down more-info">
            <div class="info">
              <div class="description">
                {{productSelected?.description}}
              </div>
              <div class="fields">
                <div class="field">
                  <div class="key">Periodicidad:</div>
                  <div class="value">{{toSpanish(productSelected?.lapse)}}</div>
                </div>
                <div class="field" *ngIf="productType == 'MICROSOFT'">
                  <div class="key">Cantidad:</div>
                  <div class="value">
                    <input [(ngModel)]="numberUsers" type="number" min="1">
                  </div>
                </div>
                <div class="field" *ngIf="productType == 'XML_SAT'">
                  <div class="key">IMEI:<img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="showTutorial()">
                  </div>
                  <div class="value">
                    <input [(ngModel)]="imei" type="text" placeholder="Capturar IMEI">
                  </div>
                </div>
                <div class="field" *ngIf="productType == 'EVALUATEC'">
                  <div class="key">Email:</div>
                  <div class="value">
                    <input [(ngModel)]="email" type="text" placeholder="Capturar email">
                  </div>
                </div>
              </div>
            </div>
            <div class="total">
              <div class="subtotal">
                Subtotal: {{getSubtotal | currency}} MXN + IVA
              </div>
              <div class="buttons">
                <button class="add" (click)="addKart()">Añadir al Carrito</button>
                <button class="buy" (click)="addKart()">Comprar Ahora</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="modeCONTPAQi" class="container-product-selected">
    <div class="product-top">
      <div class="go-back">
        <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()">
      </div>
      <div class="image-category">
        <img [src]="'../../../../../../assets/images/'+productType+'.svg'" alt="Product" id="imgProducto">
      </div>
    </div>
    <div class="product-down product-down-contpaq">
      <div class="row">
        <div class="col-3 left left-contpaq" id="filter"  [hidden]="productCategoryName !== '' && isMobileView">
          <div class="top indication" *ngIf="productType == 'SERVICIOS'">
            Selecciona tu producto
          </div>
          <div class="down">
            <div class="item-product" *ngFor="let productCategory of productCategoryList" (click)="changeFilterCategory(productCategory)">
              <div class="info-product-contpaq" [ngClass]="{'selected-contpaq': productCategory == productCategoryName}">
                <div class="name" *ngIf="productType == 'CONTPAQi' || productType == 'TS_PLUS' || productType == 'SERVICIOS'">
                  {{productCategory}}
                </div>
                <div class="img-contpaq" *ngIf="productType != 'TS_PLUS'">
                  <img [src]="'../../../../../../assets/images/'+productCategory+'.svg'" [alt]="productCategory">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 right right-contpaq" id="content"  [hidden]=" productCategoryName=== '' && isMobileView">
          <div class="top category-and-product">
            <div class="row">
              <div class="col-6 product-name product-name-contpaq" *ngIf="productType == 'CONTPAQi'">
                {{productCategoryName}}
              </div>
              <div class="col-6 product-name product-name-contpaq" *ngIf="productType != 'CONTPAQi'">
                <img *ngIf="productType != 'TS_PLUS'" [src]="'../../../../../../assets/images/'+productCategoryName+'_category.svg'" [alt]="productCategoryName">
                <img *ngIf="productType == 'TS_PLUS'" [src]="'../../../../../../assets/images/'+productType+'_category.svg'" [alt]="productCategoryName">
              </div>
              <div class="col-6 product-name product-name-contpaq" *ngIf="productType == 'TS_PLUS'">
                {{productCategoryName}}
              </div>
            </div>
          </div>
          <div class="down more-info-contpaq row">
            <div class="img-item-contpaq col-auto" *ngFor="let product of productContpaqList" (click)="goTo(product)">
              <img [src]="product.headerImage" [alt]="product.name" *ngIf="productType == 'CONTPAQi'" class="img-product">
              <div class="text-info-product" *ngIf="productType != 'CONTPAQi'">
                <div class="name">
                  {{product?.name}}
                </div>
                <div class="price">
                  {{product?.price | currency}} MXN
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  