import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthenticationPageComponent } from './authentication-page/authentication-page.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SithecTranslateModule } from '../translate/sithec-translate.module';
import { ControlsModule } from '../controls/controls.module';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { MessageService } from 'primeng/api';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { CountdownModule } from 'ngx-countdown';
import { NgOtpInputModule } from 'ng-otp-input';
import { ResetPasswordTokenComponent } from './reset-password-token/reset-password-token.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AuthenticationPageComponent,
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    RecaptchaComponent,
    ResetPasswordTokenComponent
  ],
  imports: [
    CommonModule,
    ControlsModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    CountdownModule, 
    SithecTranslateModule,
    NgOtpInputModule,
    TranslateModule
  ],
  exports:[
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    RecaptchaComponent,
    ResetPasswordTokenComponent
  ],
  providers:[
    MessageService
  ]
})
export class AuthenticationModule { }
