export const commonEnvironment = {
  //apiUrl: "https://api.shop.sithec.com.mx/api",
  apiUrl: "https://api.qaecommerce.sithec.com.mx/api",
  //apiUrl: 'http://localhost:7071/api',
  gmailUrl: "https://www.google.com/intl/es-419/gmail/about/",
  outlookUrl: "https://office.live.com/start/Outlook.aspx?ui=es-ES",
  facebookUrl: "https://www.facebook.com/SithecAgs/",
  linkedinUrl: "https://www.linkedin.com/company/sithec/mycompany/",
  pageUrl: "https://sithec.com.mx/Home/index",
  recaptcha: {
    apiUrl: "https://www.google.com/recaptcha/api.js",
    siteKey: "6Le1IS8fAAAAAFHLwQCP5ChZnQaH9T-I1bb8_F8D",
  },
  production: true,
  conekta: {
    key: "key_D5W0psQWB6eKAPm5smw4keB",
    //key: "key_L6EzUek6ipsrYKAx3h3Jw1v"
  },
  dataDogApplication: '7b2f2659-5237-447c-9797-5c4f1def5028',
  dataDogClient: 'pub83a88e198cfd895f0cd1d5d3c56b58b9',
  datadogEnv: 'dev'
};
