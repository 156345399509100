import {  Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { ICardInformationConfiguration } from '../../../models/product.model';
import { ToastrMessageService } from '../../../services/message.service';
import { ShoppingCarServiceService } from '../../../services/shopping-car-service.service';
import { filter, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AdminApiServiceService } from 'src/app/services/admin-api-service.service';

@Component({
  selector: 'app-product-selected',
  templateUrl: './product-selected.component.html',
  styleUrls: ['./product-selected.component.less'],

})
export class ProductSelectedComponent implements OnInit {

  productType = ""
  idProduct = ""
  modeCONTPAQi=false;
  numberUsers = 1;
  imei = "";
  email = "";
  productCategoryName = ""

  productList: ICardInformationConfiguration[] = []
  productContpaqList: ICardInformationConfiguration[] = []
  productCategoryList: string[] = []
  productSelected?: ICardInformationConfiguration;
  toastrService: any;
  //isResponsive: boolean = false;
  isFirstLoad: boolean = true;
  isMobileView: boolean = false;

  constructor(
    private router: Router,
    private routeActive: ActivatedRoute,
    private apiService:AdminApiServiceService,
    private shoppingCarService: ShoppingCarServiceService,
    private message:ToastrMessageService,
    private renderer: Renderer2
    ) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // this.goToNewProduct();
        }
      });
  }


  ngOnInit(): void {
    this.productType = this.routeActive.snapshot.params['productType'];
    this.idProduct = this.routeActive.snapshot.params['idProduct'];
    if(this.productType == "CONTPAQi"
      || this.productType == "ANTIVIRUS"
      || this.productType == "SERVICIOS"
      || this.productType == "TS_PLUS"){
      this.modeCONTPAQi = true;
    }
    this.checkViewport();
    const productList: any[] = JSON.parse(
      JSON.stringify(this.routeActive.snapshot.data['products'].elements.results)
    );
    if(productList){
      this.productList = productList;
      if(!this.isMobileView)
      this.productSelected = this.productList[0]
      this.productList.forEach((item,index)=>{
        this.productList[index].description?.replace(/"/g, '')
        if(item.headerImage){
          let name = item.headerImage?.split("/").reverse()[0];
          this.getImage(name, index)
        }
        if(item.productCategory?.name){
          if(!this.productCategoryList.includes(item.productCategory?.name)){
            this.productCategoryList.push(item.productCategory?.name)
          }
        }
        if(this.idProduct == item.id){
          this.selectProduct(item)
        }
      })
      var option = sessionStorage.getItem("busquedaProducto")
      if(option){
        var prod = JSON.parse(option)
        sessionStorage.removeItem("busquedaProducto")
        var aux = productList.find(producto => producto.id === prod.id)
        if(aux)
        this.productSelected = aux;
      }
    }
   
    if(this.modeCONTPAQi){
      // if(this.idProduct){
      //   this.router.navigate(['/products/information/nuevo/'+this.idProduct]);
      // }
      if(!this.isMobileView){
      var prodCatName = sessionStorage.getItem("prodCatName")
      if(prodCatName){
        this.changeFilterCategory(prodCatName);
      }
      else{
        this.changeFilterCategory(this.productCategoryList[0])
      }
      }
    }



    document.addEventListener('DOMContentLoaded', () => {
      const addToCartButton: HTMLButtonElement | null = document.querySelector('.add-to-cart');
      const shoppingCart: HTMLElement | null = document.querySelector('.shopping-cart');
      const productImage: HTMLImageElement | null = document.querySelector('.product-image');
  
      if (addToCartButton && shoppingCart && productImage) {
          addToCartButton.addEventListener('click', () => {
              // Clonar la imagen del producto
              const flyingImage: HTMLImageElement = productImage.cloneNode(true) as HTMLImageElement;
  
              // Obtener la posición actual de la imagen
              const rect: DOMRect = productImage.getBoundingClientRect();
              flyingImage.style.position = 'fixed';
              flyingImage.style.left = `${rect.left}px`;
              flyingImage.style.top = `${rect.top}px`;
              flyingImage.style.width = `${rect.width}px`;
              flyingImage.style.height = `${rect.height}px`;
              flyingImage.classList.add('flying-img');
  
              // Agregar la imagen clonada al body
              document.body.appendChild(flyingImage);
  
              // Calcular la posición del carrito
              const cartRect: DOMRect = shoppingCart.getBoundingClientRect();
              const x: number = cartRect.left - rect.left;
              const y: number = cartRect.top - rect.top;
  
              // Iniciar la animación
              flyingImage.style.setProperty('--left', `${x}px`);
              flyingImage.style.setProperty('--top', `${y}px`);
  
              // Eliminar la imagen clonada después de la animación
              flyingImage.addEventListener('animationend', () => {
                  flyingImage.remove();
              });
  
              // Aumentar el contador del carrito
              let productCount: number = parseInt(shoppingCart.getAttribute('data-product-count') || '0');
              shoppingCart.setAttribute('data-product-count', (productCount + 1).toString());
          });
      }
  });
  
  
  

  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }

  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }

  goToNewProduct(){
    this.productType = this.routeActive.snapshot.params['productType'];
    this.idProduct = this.routeActive.snapshot.params['idProduct'];
    if(this.productType == "CONTPAQi"
      || this.productType == "ANTIVIRUS"
      || this.productType == "SERVICIOS"
      || this.productType == "TS_PLUS"){
      this.modeCONTPAQi = true;
    }else{
      this.modeCONTPAQi = false;
    }
    if (this.productType) {
      this.apiService
        .getProductsResolver(this.productType)
        .then((d: any) => {
          if(d?.results){
            const productList: any[] = d?.results
            if(productList){
              this.productList = productList;
              this.productSelected = this.productList[0]
              this.productList.forEach((item,index)=>{
                this.productList[index].description?.replace(/"/g, '')
                if(item.headerImage){
                  let name = item.headerImage?.split("/").reverse()[0];
                  this.getImage(name, index)
                  
                }
                if(item.productCategory?.name){
                  if(!this.productCategoryList.includes(item.productCategory?.name)){
                    this.productCategoryList.push(item.productCategory?.name)
                  }
                }
                if(this.idProduct == item.id){
                  this.selectProduct(item)
                }
              })
            }
            if(this.modeCONTPAQi){
              // if(this.idProduct){
              //   this.router.navigate(['/products/information/nuevo/'+this.idProduct]);
              // }
              this.changeFilterCategory(this.productCategoryList[0])
            }
          }
        })
        .catch((_e) => {
          console.error(_e)
        }); 
    }
  }

  goBack(): void {
    if (this.isMobileView && this.productCategoryName !== "") {
      this.productCategoryName = "";
    } else if(this.isMobileView && this.productSelected !== undefined){
      this.productSelected= undefined;
      this.productCategoryName = "";
    } else{
     this.router.navigate(['/landing']);
    }
    
  }

  selectProduct(product: ICardInformationConfiguration){
    this.productSelected = product;
  }

  get getSubtotal(){
    return this.productSelected?.price! * this.numberUsers;
  }
  getImage(name: string, index: number){
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if(response){
            //Verificar que existe imagen
            this.productList[index].headerImage = response.result;
          } 
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
          // this.toastrService.showError(message)
        }
      );
  }

  changeFilterCategory(productCategory: string){
    this.productCategoryName = productCategory
    this.productContpaqList = this.productList.filter(item => item.productCategory?.name==this.productCategoryName)
    if(this.productContpaqList[0].productType === "SERVICIOS"){
       this.productContpaqList = this.productContpaqList.sort(this.comparePrices);
    }
  }
   comparePrices(productA: ICardInformationConfiguration, productB: ICardInformationConfiguration): number {
    var status = 0;
    // Verificar si alguno de los productos es nulo
    if (productA && productB && productA.price && productB.price) {
      if (productA?.price < productB?.price) {
        status = -1; // Retorna un número negativo si el precio de A es menor que el precio de B
    } else if (productA?.price > productB?.price) {
        status = 1; // Retorna un número positivo si el precio de A es mayor que el precio de B
    } else {
        status = 0; // Retorna 0 si los precios son iguales
    }

  }
  return status;
}
  goTo(product: ICardInformationConfiguration){
    var prodCatName = this.productCategoryName
    //console.log(prodCatName)
    if(prodCatName) sessionStorage.setItem("prodCatName", prodCatName)
    if(product){
      if(this.productType == 'CONTPAQi') {
        if(product.id)
        sessionStorage.setItem('productID',product.id)
        this.router.navigate(['/products/information/nuevo/'+product.id]);
      }
      else
        this.router.navigate(['/product/detail/'+product.id]);
    }
  }
    

  product: any = {};


  async addCard() {
    const image = document.getElementById('productoImage');
    if(image){
    image.style.transition = 'transform 2s ease';
    image.style.transform = 'translate(82vw, -4.5vh)'; // Move the image 200px to the right and 200px down
    
    // Cuando la animación de transformación termine, llame a la función de devolución de llamada
    image.addEventListener('transitionend', () => {
      //image.style.transition = '';
      //image.style.transform = '';
    });
    }
  
    
}

    resetImage(){
      const image = document.getElementById('productoImage');
      if (image) {
        image.style.transition = '';
        image.style.transform = ''; // Mueve la imagen 200px hacia la derecha y 200px hacia abajo
      }
    }
 async addKart() {
      //VerificarIMEI
      this.product.tipoLicencia = 0;
      this.product = this.productSelected
      this.product.quantity = this.numberUsers;
      this.product.usersQuantity = this.numberUsers;
      this.product.selectedCost = this.productSelected?.price;
      if(this.imei){
        if(this.shoppingCarService.getCartData().find(item => item.imei == this.imei)){
          this.message.showError('Producto con imei repetido');
          return;
        }
        this.product.imei = this.imei;
      }
      if(this.productType == 'XML_SAT'){
        if(!(this.imei.length >=52 && this.imei.length <= 54)){
          // this.message.showWarn('La longitud del IMEI no es válida');
          // return;
        }
      }
      if(this.email){
        this.product.email = this.email;
      }
      if(this.productType == 'EVALUATEC'){
        if(!this.validateEmail(this.email)){
          this.message.showWarn('Proporcionar un email válido');
          return;
        }
      }
      this.addCard()
      // this.product.costMonoRFCUA = this.usuarioMono
      // if(this.usuarioMono!=null){
      //   this.producto.costMonoRFCUA = this.usuarioMono
      // }else{
        this.product.costMonoRFCUA = 0;
      // }
      this.product.isMulti=false;
      this.product.isMono=true;
      this.shoppingCarService.setItemLocalStorage(this.product);
      this.router.navigate(['/cart']);
      this.message.showInfo('Producto añadido exitosamente');
      
    }
    showTutorial(){
      Swal.fire({
        html: `
        <style>
        .custom-swal-modal {
          background: rgba(0, 0, 0, 0.15);
          width: 80vw;
          height: 80vh;
        }
        .iframe {
          width: 75vw;
          height: 75vh;
        }
        </style>
        <iframe class="iframe" src="https://www.youtube.com/embed/NO2U7QQmNqE?si=gUI0quNtl088-C3H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        `,
        showConfirmButton: false, // Ocultar botón de confirmación
        showCloseButton: true, // Mostrar botón de cerrar
        customClass: {
          popup: 'custom-swal-modal', // Clase CSS personalizada para hacer el modal más ancho
        },
        heightAuto:true
      });
    
    }

    toSpanish(lapse: string = ''){
      switch(lapse){
        case 'Day':
          return 'Diaria';
        case 'Month':
          return 'Mensual';
        case 'Year':
          return 'Anual';
        case 'Permanent':
          return 'Permanente';
      }
      return ''
    }

  validateEmail(email: string): boolean {
    // Expresión regular para validar direcciones de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //console.log("emailRegex.test(email)",emailRegex.test(email))
    // Utilizamos el método test de la expresión regular para verificar si el email cumple el formato
    return emailRegex.test(email);
  }
  
}