import { Injectable } from "@angular/core";
import { activeCategories, IProductCategory } from "../models/product.model";
import { IPurchase, IPurchaseDetail } from "../models/purchase.model";
import { IPurchaseData } from "../models/purchase.model";
import { SithecCommonApiService } from "../services/sithec-common-api.service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ECommerceApiService extends SithecCommonApiService {
  getProductsAvailablePerCategory(): Observable<IProductCategory[]> {
    return this.httpClient
      .get<IProductCategory[]>(`${this.apiUrl}/product-categories`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  getProductsCategoryActive(): Observable<activeCategories> {
    return this.httpClient
      .get<activeCategories>(`${this.apiUrl}/productCategoryActives`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }


  postSavePurchase(purchaseDetails: IPurchase): Observable<IPurchase> {
    return this.httpClient
      .post<IPurchase>(`${this.apiUrl}/purchases`, purchaseDetails, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  /* Get Purchases */
  getPurchase(userID:string): Observable<IPurchaseData[]> {
    return this.httpClient.get<IPurchaseData[]>(`${this.apiUrl}/purchases/${userID}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  /* Get Purchase Details */
  getPurchaseDetails(userID:string, search=""): Observable<IPurchaseDetail[]> {
    let url = `${this.apiUrl}/purchasedetailsfilter/${userID}`
    if(search)
      url+= "?Search="+search;
    return this.httpClient.get<IPurchaseDetail[]>(url, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  /* Get Info Serial Number */
  getInfoSerialNumber(license:string, productID:number): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/GetLicenceInfo`,
    {
      "licenseOrLot": license,
      "productId": productID
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  /* Get Quote */
  getQuote(license:string, productID:number, purchaseType:number, newUsers:number, previousUsers:string, newCompany:number, previousCompany:string, licenseType:string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/GetQoute`,
    {
      "productId": productID,
      "purchaseTypeId": purchaseType,
      "licenseOrLot": license,
      "usersQuantity": newUsers,
      "licenseQuoteTypeId": licenseType,
      "companyType": newCompany,
      "baseProductGrowth_ProductId": productID,
      "baseProductGrowth_LicenseOrLot": license,
      "baseProductGrowth_LicenseTypeId": licenseType+"",
      "baseProductGrowth_CompanyType": previousCompany,
      "baseProductGrowth_UserQuantity": previousUsers
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
}
