import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PackagesAvailableComponent } from './packages-available/packages-available.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { SithecTranslateModule } from '../translate/sithec-translate.module';
import { ControlsModule } from '../controls/controls.module';

@NgModule({
  declarations: [
    FooterComponent, HeaderComponent, PackagesAvailableComponent
  ],
  imports: [
    CommonModule,
    ControlsModule,
    PrimeNgModule,
    FormsModule,
    SithecTranslateModule,
    ReactiveFormsModule
  ],
  exports:[
    FooterComponent, HeaderComponent, PackagesAvailableComponent
  ]
})
export class CommonComponentsModule { }
