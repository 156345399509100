import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../common-components/common-components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PrimeNgModule } from "../prime-ng/prime-ng.module";
import { SithecTranslateModule } from "../translate/sithec-translate.module";
import { ControlsModule } from "../controls/controls.module";
import { CardModule } from "ngx-card";
import { CartItemsComponent } from "./components/cart-items/cart-items.component";
import { CartOperationsComponent } from "./components/cart-operations/cart-operations.component";
import { CartRoutingModule } from "./cart-module.routing";
import { ShoppingCartComponent } from "./shopping-cart.component";
import { PayMethodComponent } from "./components/pay-method/pay-method.component";
import { CreditCardDirectivesModule } from "angular-cc-library";

@NgModule({
  declarations: [
    ShoppingCartComponent,
    CartItemsComponent,
    CartOperationsComponent,
    PayMethodComponent
  ],
  imports: [
    CommonModule,
    ControlsModule,
    CommonComponentsModule,
    PrimeNgModule,
    FormsModule,
    SithecTranslateModule,
    ReactiveFormsModule,
    CartRoutingModule,
    CardModule,
    CreditCardDirectivesModule,
  ],
  exports:[
    CartItemsComponent,
    CartOperationsComponent,
    PayMethodComponent,
  ]
})
export class ShoppingCartModule {}
