import { Component, OnInit } from '@angular/core';
import { IPurchaseDetail } from '../../models/purchase.model'; 
import { ECommerceApiService } from '../../services/e-commerce-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { ToastrMessageService } from "../../services/message.service";
import { debounceTime, finalize } from "rxjs/operators";
import { IErrorDefinition } from '../../models/error-definition.model';
import { UserService } from '../../services/user.service';
import { IFileRequest } from '../../models/file-request';
import { SithecCommonApiService } from '../../services/sithec-common-api.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.less']
})
export class CertificadosComponent implements OnInit {

  constructor(
    private apiService:ECommerceApiService, 
    private spinnerService:SpinnerService, 
    private toastrService:ToastrMessageService, 
    private userService:UserService, 
    private sithecCommonApiService:SithecCommonApiService
    ) {}

  purchaseDetail:IPurchaseDetail[]=[]
  search = new FormControl('');
  formSubscription!:Subscription

  ngOnInit(): void {
    this.spinnerService.show()
    this.getPurchaseDetails()
  }
  ngAfterViewInit(){
    this.formSubscription=this.search.valueChanges
    .pipe(
      debounceTime(500) // Esperar 500 milisegundos antes de continuar
    )
    .subscribe((data)=>{
      this.getPurchaseDetails()
    })
  } 
  ngOnDestroy(): void {
    if(this.formSubscription){
      this.formSubscription.unsubscribe()
    }
    
  }
  trackDetails(index:number, item:IPurchaseDetail){
    return item.productId
  }
  getIcon(certificado:boolean):string{
    return certificado? "../../../../../assets/images/descargar_icon.svg" : "../../../../../assets/images/descargar icon-gray.svg";
  }
  async checkCertificado(certificado:boolean, Id:string){
    if(certificado){
      this.toastrService.showInfo("Tu documento está siendo cargado")
      await this.getURLFile(Id+".pdf", 'certificados')
    }else{
      this.toastrService.showWarn("Este producto aún no ha sido certificado")
    }
  }

  async getURLFile(name:string, subdirectory:string){
    const file: IFileRequest= {
      objectKey: name,
      subDirectory: subdirectory,
      duration: 2
    };
    var url=""
    await this.sithecCommonApiService
    .getURLFile(file).subscribe((res) => {
      url=res
      //window.location.href=url
      window.open(url, '_blank');
    });
  }

  getPurchaseDetails(){
    const folio = localStorage.getItem("folioLicencia")
    if(folio !== null){
      this.apiService
      .getPurchaseDetails(this.userService.user.id,this.search.value!)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (purchases: any) => {
            //console.log(purchases)
            for(let purchase of purchases.results){
              if(purchase.purchase.folio.toString() === folio)
              this.purchaseDetail.push(purchase)
            }
            
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
        localStorage.removeItem("folioLicencia");
    }
    else{
      this.apiService
      .getPurchaseDetails(this.userService.user.id,this.search.value!)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (purchases: any) => {
            this.purchaseDetail=purchases.results
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
    }
  }
}
