
 <button [attr.type]="type" [attr.aria-label]="ariaLabel" [class]="styleClass" [ngStyle]="style" [disabled]="disabled || loading"
 [ngClass]="{'app-button':true,
             'app-button-icon-only': (icon && !label),
             'app-button-vertical': (iconPos === 'top' || iconPos === 'bottom') && label,
             'app-disabled': this.disabled || this.loading,
             'app-button-loading': this.loading,
             'app-button-loading-label-only': this.loading && !this.icon && this.label}"
             (click)="onClick.emit($event)" (focus)="onFocus.emit($event)" (blur)="onBlur.emit($event)" >
 <ng-content></ng-content>
 <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
 <span [ngClass]="{'app-button-icon ': true,
             'app-button-icon-left': iconPos === 'left' && label,
             'app-button-icon-right': iconPos === 'right' && label,
             'app-button-icon-top': iconPos === 'top' && label,
             'ap-button-icon-bottom': iconPos === 'bottom' && label,
              'material-icons':true
            }"
             [class]="loading ? 'app-button-loading-icon ' + loadingIcon : 't'" *ngIf="!contentTemplate && (icon||loading)"
             [attr.aria-hidden]="true">
            {{icon}}
            </span>
 <span class="app-button-label" [attr.aria-hidden]="icon && !label" *ngIf="!contentTemplate">{{label | translate}} </span>
 <span [ngClass]="badgeStyleClass()" [class]="badgeClass" *ngIf="!contentTemplate && badge">{{badge}}</span>
</button>