import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-purchase-accepted',
  templateUrl: './purchase-accepted.component.html',
  styleUrls: ['./purchase-accepted.component.less']
})
export class PurchaseAcceptedComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  redirigir(){
    this.router.navigate(['/landing']);
  }

  redirigirShopping(){
    this.router.navigate(['/compras']);
  }

}
