<div class="container-product-selected">
    <div class="product-top">
        <div class="go-back">
            <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()">
        </div>
        <div class="image-category">
            <img id="logo-detail" [src]="'../../../../../../assets/images/'+productType+'.svg'" [alt]="productType">
        </div>
    </div>
    <div class="product-down">
        <div class="row">
            <div class="col-12 right">
                <div class="top category-and-product">
                    <div class="row">
                        <div class="col-6 col-md-2 category-logo">
                            <img *ngIf="!showAnimation" id="logoDetail" [src]="productSelected?.headerImage" alt="Product" style="max-width: 100%;">
                            <img *ngIf="showAnimation" id="animatedImage" [src]="productSelected?.headerImage" alt="Product" class="animated-image" style="max-width: 50%;">
                        </div>
                        <div class="col-6 col-md-8 product-name">
                            {{productSelected?.name}}
                        </div>
                    </div>
                </div>
                <div class="down more-info">
                    <div class="info">
                        <div class="description">
                            <p>{{productSelected?.description}}</p>
                        </div>
                        <div class="fields" *ngIf="productType != 'XML_SAT'">
                            <div class="field">
                                <div class="key">Periodicidad:</div>
                                <div class="value">{{toSpanish(productSelected?.lapse)}}</div>
                            </div>
                            <div class="field">
                                <div class="key">Cantidad:</div>
                                <div class="value">
                                    <input [(ngModel)]="numberUsers" type="number" min="1">
                                </div>
                            </div>
                        </div>
                        <div class="fields" *ngIf="productType == 'XML_SAT'">
                            <div class="field">
                                <div class="key">Periodicidad:</div>
                                <div class="value">{{toSpanish(productSelected?.lapse)}}</div>
                            </div>
                            <div class="field">
                                <div class="key">Usuarios:</div>
                                <div class="value">
                                    <select [(ngModel)]="numberUsers">
                                        <option value="1" selected>N/A</option>
                                    </select>
                                </div>
                            </div>
                            <div class="field">
                                <div class="key">IMEI:</div>
                                <div class="value">
                                    <input [(ngModel)]="imei" type="text" placeholder="Capturar IMEI">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="total">
                        <div class="subtotal">
                            Subtotal: {{getSubtotal | currency}} MXN + IVA
                        </div>
                        <div class="buttons">
                            <button class="add animate__heartBeat" (click)="addCard()">Añadir al Carrito</button>
                            <button class="buy" (click)="payNow()">Comprar Ahora</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Imagen duplicada para la animación -->

