<div [ngClass]="['form-input', className]" >
  <label *ngIf="!!label" class="form-label">{{ label | translate }}</label>
  <select 
    class="form-select"
    [value]="value"
    (change)="change($event)"
    *ngIf="options.length > 0"
    [ngClass]="errorClass"
  >
    <!-- <option disabled selected value="null">
      {{ placeholder | translate }}
    </option> -->
    <ng-container *ngFor="let option of options">
      <div *ngIf="option.seleccionado; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <option [value]="option.value" selected> 
          <ng-container *ngTemplateOutlet="option.content"></ng-container>
        </option>
      </ng-template>
      <ng-template #elseBlock>
        <div *ngIf="option.unico; then inhabilitado else habilitado"></div>
        <ng-template #inhabilitado>
          <option [value]="option.value" disabled>
            <ng-container *ngTemplateOutlet="option.content"></ng-container>
          </option>
        </ng-template>
        <ng-template #habilitado>
          <option [value]="option.value">
            <ng-container *ngTemplateOutlet="option.content"></ng-container>
          </option>
        </ng-template>        
      </ng-template>

      
    </ng-container>
  </select>
  <app-form-errors
    *ngIf="hasErrorInput()"
    [control]="control"
    [submitted]="submitted"
  ></app-form-errors> 
</div>
