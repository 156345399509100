import { Injectable } from "@angular/core";
import { commonEnvironment } from "../../environments/common_environment";
import { ICardToken, ICreditCardDetails } from "../models/credit-card.model";


declare let Conekta: any;

@Injectable({
    providedIn: 'root'
  })
  export class ConektaService {
     getToken(creditCard: ICreditCardDetails): Promise<ICardToken> {
      Conekta.setPublicKey(commonEnvironment.conekta.key);

      return new Promise((resolve, reject) => {
        Conekta.Token.create({
          card: {
            number: creditCard.number,
            exp_month: +creditCard.exp_month,
            exp_year: +creditCard.exp_year,
            cvc: creditCard.cvc,
            name: creditCard.name,
            address_line1: creditCard.address,
            address_country: creditCard.country,
          }
        },
        (result: any) => {
          resolve({ token: result.id});
        },
        (error: any) => reject(new Error(error)))
      });
    } 
}