import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'form-option',
  templateUrl: './form-option.component.html',
})
export class FormOptionComponent {
  @ViewChild(TemplateRef) content!: TemplateRef<any>;
  @Input() value: any;
  @Input() seleccionado?: boolean;
  @Input() unico?: boolean;
}