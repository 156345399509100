import { NgModule } from "@angular/core";
import {CalendarModule} from 'primeng/calendar';
import {StepsModule} from 'primeng/steps';
import {MenubarModule} from 'primeng/menubar';
import { ButtonModule } from "primeng/button";
import {ToastModule} from 'primeng/toast';
import { ConfirmationService, MessageService } from "primeng/api";
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

  @NgModule({
    declarations: [
    ],
    imports: [
      CalendarModule,
      StepsModule,
      ButtonModule,
      ToastModule,
      SidebarModule,
      ConfirmDialogModule,
      DialogModule
    ],
    exports: [
      CalendarModule,
      StepsModule,
      MenubarModule,
      ButtonModule,
      ToastModule,
      SidebarModule,
      ConfirmDialogModule,
      DialogModule
    ],
    providers:[
      MessageService,
      ConfirmationService
    ]
  })

  export class PrimeNgModule {}