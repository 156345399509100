import { Component, EventEmitter, Input, Output, OnInit, HostListener, ElementRef } from "@angular/core";
import { ICardInformationConfiguration } from "../../../models/product.model";
import { ProductInformationService } from "../../../services/product-information.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { IErrorDefinition } from "../../../models/error-definition.model";
import { FormRadioButtonComponent } from "../../controls/form-radio-button/form-radio-button.component";
import { AdminApiServiceService } from "src/app/services/admin-api-service.service";

@Component({
  selector: 'lib-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.less']
})
export class ProductCardComponent implements OnInit {

  producto!: ICardInformationConfiguration;
  public nombre!: string;
  public descripcion!: string;
  public imagen!: string;
  public url!: string;
  learnMore:string = "";
  mostrarCambio!: boolean;
  mostrarRenovacion!: boolean;

  seleccionado!: string;
  transaccion!: string;

  alturaPagina!: number;

  idProduct = ""
  @Output() tipoLicenciaSeleccionada = new EventEmitter<string>();
  @Output() nombreProducto = new EventEmitter<string>();
  
  isChecked: boolean[] = [false, false, false];

  isMobileView:boolean = false;
  constructor(
    private productInformation: ProductInformationService,
    private router: Router,
    private routeActive: ActivatedRoute,
    private apiService: AdminApiServiceService,
    private elementRef: ElementRef
  ) {
    let id = sessionStorage.getItem('productID')
    if(id)
    this.idProduct = id.toString();
    if (this.idProduct) {
      this.getProductById();
      
    }

    // if (this.nombre == "XML en Línea+") {
    //   /* this.url = "https://sithec-ecommerce-dev.s3.amazonaws.com/assets/Fichas_productos/XML+en+L%C3%ADnea%2B.pdf"; */
    //   this.url = "https://sithec-ecommerce-public-assets.s3.amazonaws.com/Fichas_productos/XML+en+L%C3%ADnea%2B.pdf";
    //   this.mostrarCambio = false;
    //   this.mostrarRenovacion = true;
    // } else if (this.nombre == "CFDI Facturación en Línea+" || this.nombre == "Factura Electrónica") {
    //   /* this.url = "https://sithec-ecommerce-dev.s3.amazonaws.com/assets/Fichas_productos/CFDI%20Facturaci%C3%B3n%20en%20L%C3%ADnea%20%2B.pdf"; */
    //   this.url = "https://sithec-ecommerce-public-assets.s3.amazonaws.com/Fichas_productos/CFDI%20Facturaci%C3%B3n%20en%20L%C3%ADnea%20%2B.pdf";
    //   this.mostrarCambio = false;
    //   this.mostrarRenovacion = false;
    // } else {
    //   /* this.url = "https://sithec-ecommerce-dev.s3.amazonaws.com/assets/Fichas_productos/"+this.nombre+".pdf"; */
    //   this.url = "https://sithec-ecommerce-public-assets.s3.amazonaws.com/Fichas_productos/" + this.nombre + ".pdf";
    //   this.mostrarCambio = true;
    //   this.mostrarRenovacion = true;
    // }
  }

  ngOnInit(): void {

    this.transaccion = "Seleccione";
    this.isChecked[1] = true;
    var URLactual = window.location;
    var opciones = URLactual.toString().split("/");
    var opcionSeleccionada = opciones[opciones.length - 1];

    this.alturaPagina = window.innerWidth;

    switch (opcionSeleccionada) {
      case "nuevo":
        this.seleccionado = "2";
        this.transaccion = "Licencia Nueva"
        if (this.alturaPagina < 1229) {
          window.scrollBy(0, 500)
        }
        break;
      case "cambio":
        this.seleccionado = "3";
        this.transaccion = "Cambio de Características"
        if (this.alturaPagina < 1229) {
          window.scrollBy(0, 500)
        }
        break;
      case "renovacion":
        this.seleccionado = "4";
        this.transaccion = "Renovación"
        if (this.alturaPagina < 1229) {
          window.scrollBy(0, 500)
        }
        break;
    }

    this.checkViewport();
    this.cambiarSeleccion('nuevo');
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const details = document.getElementById('seleccion');
    if (details && !details.contains(event.target as Node)) {
      details.removeAttribute('open');
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }
  goBack(): void {
    this.router.navigate(['/product/CONTPAQi']);
  }
/*@HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    let selec = <HTMLDetailsElement>document.getElementById('seleccion')
    if(selec && selec.hasAttribute("open"))selec.removeAttribute("open")
      let selec2 = <HTMLElement>document.getElementById('seleccion2')
    if(selec2 && selec2.hasAttribute("open"))selec2.removeAttribute("open")
  }*/

  
  /*cambiarSeleccion(valor:any){
    /* this.seleccionado=valor;
     let ruta = ""
     switch(valor){
       case "1":
         this.transaccion="Seleccionar tipo de transacción"
         ruta = "opciones"
         break;
         case "2":
         this.transaccion="Licencia Nueva"
         ruta = "nuevo"
         break;
       case "3":
         this.transaccion="Cambio de Características"
         ruta = "cambio"
         break;
       case "4":
         this.transaccion="Renovación"
         ruta = "renovacion"
         break;
     }
     localStorage.setItem('transaccion', this.transaccion)
     let location_sliced=window.location.toString().split('/')
     let newlocation = 'http://'+location_sliced[2]+'/products/information/'+ruta; 
     window.location.href = newlocation;
     this.transaccion =  localStorage.getItem('transaccion') || ""
     window.scrollBy(0, 500) 
  }*/
  onChange(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      const value = event.target.value;
      this.cambiarSeleccion(value);
    }
  }
  cambiarSeleccion(ruta: string) {
    let form: FormRadioButtonComponent = new FormRadioButtonComponent();
    let selec = <HTMLElement>document.getElementById('seleccion')
    switch (ruta) {
      case 'nuevo':
        this.transaccion = 'Nueva Licencia';
        this.isChecked[0] = false;
        this.isChecked[1] = true;
        this.isChecked[2] = false;
        this.isChecked[3] = false;
        if(selec)selec.removeAttribute("open")
        break;
      case 'cambio':
        this.transaccion = 'Cambiar Características';
        this.isChecked[0] = false;
        this.isChecked[1] = false;
        this.isChecked[2] = true;
        this.isChecked[3] = false;
        if(selec)selec.removeAttribute("open")
        break;
      case 'renovacion':
        this.transaccion = 'Renovación';
        this.isChecked[0] = false;
        this.isChecked[1] = false;
        this.isChecked[2] = false;
        this.isChecked[3] = true;
        if(selec)selec.removeAttribute("open")
        break;
      default:
        if(selec)selec.removeAttribute("open")
        break;
    }
    
    this.tipoLicenciaSeleccionada.emit(ruta);
    
    // Navegar a la nueva URL

  }
  conoceMas() {
    if(this.producto){
      if(this.producto.id){
        let id = this.producto.id.toUpperCase();
        const pdfInfo = {
          "objectKey": id+".pdf",
          "subDirectory": "fichas",
          "duration": 30
        };
        try {
          this.apiService
          .getPublicImage(pdfInfo)
          .subscribe(
            (response: any) => {
              if(response){
                //Verificar que existe imagen
                // //console.log("response",response)
                window.open(response.result, '_blank');
              } 
            },
            ({ message }: IErrorDefinition) => {
              // this.toastr.error(message,'Error');
            }
          );
        } catch (error) {
          // //console.log("error",error)
        }
      }
    }
  }
  //<

  cerrarLista(): void {
    let selec = <HTMLElement>document.getElementById('seleccion')
    if(selec && selec.hasAttribute("open"))selec.removeAttribute("open")
      let selec2 = <HTMLElement>document.getElementById('seleccion2')
    if(selec2 && selec2.hasAttribute("open"))selec2.removeAttribute("open")
  }
  getProductById() {
    this.apiService
      .getProduct(this.idProduct)
      .subscribe(
        (product: any) => {
          if (product) {
            if (product.headerImage) {
              let name = product.headerImage?.split("/").reverse()[0];
              this.producto = product;
              this.productInformation.setProducto(this.producto);
              this.nombre = this.producto.name!;
              this.nombreProducto.emit(this.nombre);
              this.descripcion = this.producto.description!;
              this.learnMore = this.producto.learnMore!;
              this.getImage(name)
            }else{
              this.producto = product;
              this.productInformation.setProducto(this.producto);
              this.nombre = this.producto.name!;
              this.nombreProducto.emit(this.nombre);
              this.descripcion = this.producto.description!;
              this.learnMore = this.producto.learnMore!;
            }
            this.mostrarCambio = this.producto?.productFeatures?.changeCharacteristics? true: false;
            this.mostrarRenovacion = this.producto?.productFeatures?.renovation? true: false;
          }
        }
      );
  }
  /*getProductById(){
    let id:string = ''
    this.idProduct= sessionStorage.getItem('productId');
    if(this.idProduct !== null)
     id= this.idProduct
     this.apiService
     .getProduct(id)
     .subscribe(
         (product: any) => {
           if(product){
             if(product.headerImage){
               let name = product.headerImage?.split("/").reverse()[0];
               this.producto = product
               this.nombre = this.producto.name!;
               this.descripcion = this.producto.description!;
               this.getImage(name)
             }
           }
         }
       );
  }
getProductById(){
  this.apiService
        .getProduct(this.idProduct2)
        .subscribe(
            (product: any) => {
              if(product){
                if(product.headerImage){
                  let name = product.headerImage?.split("/").reverse()[0];
                  this.producto = product
                  this.nombre = this.producto.name!;
                  this.descripcion = this.producto.description!;
                  this.getImage(name)
                }
              }
            }
          );
}*/
  getImage(name: string) {
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if (response) {
            //Verificar que existe imagen
            this.producto.headerImage = response.result;
            this.imagen = this.producto.headerImage!;
          }
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
}
