<div class="contenido">
    <div class="recuadro">
     <img src="../../../../../../assets/images/paloma.svg">
      <h1 class="title">¡Listo!</h1>
      <p class="text">Tu contraseña ha sido Reestablecida con éxito</p>
      <img src="../../../../../../assets/images/siboth.svg">
      <app-button
        class="app-button ajuste color-claro"
        [label]="'Iniciar Sesión'"
        type="submit"
        (onClick)="redirectToLogin()"
      >
      </app-button>
    </div>
  </div>
  