import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import { SessionService } from './session.service';
import { PlatformUtil } from '../models/platformUtil';

@Injectable()
export class AuthenticationGuardService implements CanActivate {
  constructor(private session: SessionService, private router: Router, private platformUtil: PlatformUtil) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
    if(this.platformUtil.isBrowser())
    if (!this.session.hasValidSession) {
      return this.router.navigate(['/account/authentication']).then(() => false);
    }
    return true;
  }

  protected redirectToVerifyEmail(state: RouterStateSnapshot): void {
  }

  protected redirectToLogin(state: RouterStateSnapshot): void {
    
  }

  protected redirectToForbidden(): void {
   
  }
}
