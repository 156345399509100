import { Component,EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ICardInformationConfiguration } from '../../../models/product.model';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductInformationService } from '../../../services/product-information.service';
import { Router } from "@angular/router";
import { ToastrMessageService } from '../../../services/message.service';
import { ISwitch } from '../../../models/switch.model';
import { ShoppingCarServiceService } from '../../../services/shopping-car-service.service';
import { ECommerceApiService } from '../../../services/e-commerce-api.service';
import { finalize } from "rxjs/operators";
import { SpinnerService } from '../../../services/spinner.service';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { AdminApiServiceService } from 'src/app/services/admin-api-service.service';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.less']
})
export class ChangeComponent implements OnInit {
  
  productType =  [ 
    {
      name: "Nóminas",
      productID: 18
    },
    {
      name: "Contabilidad",
      productID: 11
    },
    {
      name: "Bancos",
      productID: 10
    },
    {
      name: "Comercial Start",
      productID:  9
    },
    {
      name: "Comercial Pro",
      productID: 7
    },
    {
      name: "Comercial Premium",
      productID: 8
    },
    {
      name: "Línea",
      productID: 14
    }
  ]
  producto!:ICardInformationConfiguration;
  precioMulti!:number;
  precioMono!:number;
  totalMulti!:number;
  totalMono!:number;
  usuarioMulti!:number;
  usuarioMono!:number;
  usuariosMulti!:number;
  usuariosMono!:number;
  mostrarMulti!:boolean;
  mostrarInformacionMulti!:boolean;
  mostrarUsuariosMulti!:boolean;
  mostrarMono!:boolean;
  mostrarInformacionMono!:boolean;
  mostrarUsuariosMono!:boolean;
  seleccion!:string;
  seleccionable!:boolean;
  seleccionado!:string;

  licencia!:string;
  productId!:number;

  propietario!:string;
  registro!:string;
  vigencia!:string;
  usuariosInfo!:string;
  empresas!:string;
  licenciamiento!:string;
  estatus!:boolean;

  precio!:number;
  total!:number;
  costoUsuarioAdicional!:number;
  usuarios!:number;
  mostrarLicencias!:boolean;

  encontrado!:boolean;
  cotizado!:boolean;

  action:string="el timbrado"

  licenseTypeId!:string

  multi:boolean = false

  productForm: FormGroup = new FormGroup(
    {
      licencia: new FormControl()
    }
  );


  isMobileView:boolean = false;
  @Output() selection = new EventEmitter<boolean>();
  imagen:string = "";

  showAnimation: boolean = false;
  constructor(
    private api2Service: AdminApiServiceService,
    private apiService:ECommerceApiService,
    private spinnerService:SpinnerService, 
    private shoppingCarService: ShoppingCarServiceService,
    private productService:ProductInformationService,
    private router:Router,
    private message:ToastrMessageService
    ) {
    this.encontrado=false
    this.cotizado=false
    this.producto=this.productService.getProducto();
    let name = this.producto.headerImage?.split("/").reverse()[0];
    if(name)
    this.getImage(name)
    this.producto.purchaseType="Cambio de Características";
    this.producto.isInCombo=false;
   }

  cambiarSeleccion(valor:any){
    this.seleccionado=this.productForm.controls['licencia'].value;
    this.alternar();
    this.cotizado=false
  }

  addQuantity(){
    this.usuarios++;
    this.total+=this.costoUsuarioAdicional
    this.cotizado=false
  }

  removeQuantity(){
    if(this.usuarios>1){
      this.usuarios--;
      this.total-=this.costoUsuarioAdicional
      this.cotizado=false
    }
  }

  public addCard() {
    if(this.seleccion == "Multiempresa"){
      this.producto.tipoLicencia = 3;
      this.producto.usersQuantity = this.usuarios;
      this.producto.selectedCost = this.total;
      if(this.usuarioMulti!=null){
        this.producto.costMultiRFCUA = this.usuarioMulti
      }else{
        this.producto.costMultiRFCUA = 0;
      }
      this.producto.isMulti=true;
      this.producto.isMono=false;
    }else{
      this.producto.tipoLicencia = 3;
      this.producto.usersQuantity = this.usuarios;
      this.producto.selectedCost = this.total;
      if(this.usuarioMono!=null){
        this.producto.costMonoRFCUA = this.usuarioMono
      }else{
        this.producto.costMonoRFCUA = 0;
      }
      this.producto.isMulti=false;
      this.producto.isMono=true;
    }
    this.shoppingCarService.setItemLocalStorage(this.producto);

    if(!this.isMobileView){
      this.showAnimation = true;
      setTimeout(() => {
        const animatedImage = document.getElementById('animatedImage');
        if (animatedImage) {
          animatedImage.classList.add('animate-move');
        }
      }, 50);
  
      setTimeout(() => {
        this.showAnimation = false;
        this.goBack2();
      }, 1050);
    }
    else{
      this.message.showInfo('Producto añadido exitosamente');
      this.goBack2();
    }
    
  }
  ngOnInit(): void {
    this.checkViewport();
  }
  cambiar(data:ISwitch){
    this.alternar();
  }
  alternar(){
    //console.log("Selección: "+this.seleccionado)
    if(this.seleccionado=="1"){
      this.mostrarMulti=true;
      this.mostrarMono=false;
      this.producto.isMono=false;
      this.producto.costMultiRFCUA = this.precioMulti;
      this.seleccion="Multiempresa"
      
    }else{
      this.mostrarMulti=false;
      this.mostrarMono=true;
      this.producto.isMono=true;
      this.producto.costMonoRFCUA = this.precioMono;
      this.seleccion="Monoempresa"
    }
  }
  getInfoSerialNumber(license:string){
    var buscar = true;
    var cartProducts = this.shoppingCarService.getPurchaseItems();
    cartProducts.forEach((element) => {
      if(element.license==license){
        this.message.showError("No es posible cotizar la serie ya que se encuentra agregada en el carrito.")
        buscar = false;  
      }
    });
    if(license.length!=16){
      this.message.showError("La serie debe contar con 16 dígitos alfanuméricos.")
    }else if(buscar){
      this.producto.License=license
      this.spinnerService.show()
      this.encontrado=false
      let productID = 0;
      this.licencia=license
      
      this.productType.forEach(item =>{
        if(this.isContain(this.producto.name!,item.name))
        {
          productID = item.productID
        }
      })
      this.productId=productID
      this.apiService
        .getInfoSerialNumber(license, productID)
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(
            (info:any) => {
              //console.log(info.value)
              //console.log(info.value.serialNumberInformation)
              //console.log(info.value.licenseInfo) 
              this.propietario=info.value.licenseInfo.registrado_a
              this.empresas=info.value.serialNumberInformation.empresas
              this.usuariosInfo=info.value.serialNumberInformation.no_usuarios
              this.usuarios=info.value.serialNumberInformation.no_usuarios
              this.registro=info.value.serialNumberInformation.fecha_activacion
              this.vigencia=info.value.serialNumberInformation.vigencia
              this.licenciamiento=info.value.serialNumberInformation.version
              this.estatus=(parseInt(info.value.serialNumberInformation.dias_restantes)>0)
              this.encontrado=this.estatus
              this.licenseTypeId=info.value.licenseTypeQuote
  
              if(!this.estatus){
                this.message.showError("Licencia caducada. Sólo se puede realizar cambio de características en licencias vigentes")
              }
              
              this.seleccion = this.empresas
              if(this.seleccion=="Multiempresa"){
                this.productForm.controls['licencia'].setValue('1');
                this.multi=true
              }else{
                this.productForm.controls['licencia'].setValue('2');
                this.multi=false
              }
              
            },
            ({ message }: IErrorDefinition) => this.message.showError(message)
          );
    }
  }
  cotizar(){
    this.spinnerService.show()
    this.cotizado=false
    //console.log(this.seleccion)
    //console.log(this.multi)
    if(this.multi && this.seleccion!="Multiempresa"){
      this.message.showWarn("No es posible cambiar a mono debido a que la licencia actual es multi")
      this.total=0
      this.spinnerService.hide()
    }else{
      this.apiService
      .getQuote(this.licencia, this.productId, 6, this.usuarios, this.usuariosInfo, this.seleccion=="Multiempresa"?1:2, this.empresas=="Multiempresa"?"1":"2", this.licenseTypeId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (info:any) => {
            //console.log(info)
            this.total=info.value.prices.productQuotePriceInclTax
            this.cotizado=true
          },
          ({ message }: IErrorDefinition) => this.message.showError(message)
        ); 
    }
    
  }

  isContain(name1: string, name2: string): boolean{
    //En minúsculas
    name1 = name1.toLocaleLowerCase();
    name2 = name2.toLocaleLowerCase();
    //Quitar acentos
    name1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    name2.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //Coincide?
    if(name1.includes(name2)){
      return true;
    }
    return false;
  }


  goBack(){
    this.selection.emit(false)
  }
  goBack2(){
    this.router.navigate(['/product/CONTPAQi']);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }

  getImage(name: string) {
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.api2Service
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if (response) {
            //Verificar que existe imagen
            this.producto.headerImage = response.result;
            this.imagen = this.producto.headerImage!;
          }
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
}
