import { NgModule } from "@angular/core";
import { SithecTranslateModule } from "../translate/sithec-translate.module"; 
import { ProductsRoutingModule } from "./products-routing.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
  ],
    imports: [
        SithecTranslateModule,
        ProductsRoutingModule
    ],
    exports: [
        ProductsRoutingModule
    ]
})

export class ProductsModule {}