import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationPageComponent } from "./authentication-page/authentication-page.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { ResetPasswordTokenComponent } from "./reset-password-token/reset-password-token.component";
import { CommonModule } from "@angular/common";

const routes: Routes = [
  
  {
    path: "authentication",
    component: AuthenticationPageComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "verify-email",
    component: VerifyEmailComponent,
  },
  {
    path: "success-reset",
    component: ResetPasswordTokenComponent,
  },
];

@NgModule({
  imports: [CommonModule,RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
