<div class="contenedor"> 
    <div class="distribution"  [formGroup]="verifyForm" (ngSubmit)="submit()" novalidate>
        <img src=".././../../../../assets/images/logo sithec_white.png" alt="" class="image-login" />
        <p class="normal-text">Casi todo está listo...</p>
        <p class="bold-text">Verifica tu cuenta para continuar</p>
        <p class="little-normal-text">Enviamos un SMS para confirmar tu cuenta</p>
        <p class="sub-title">Escribe el código que enviamos a tu celular: {{phoneNumber}}</p>
        <form-input
              name="code"
              type="number"
              formControlName="code"
              [control]="verifyForm.controls['code']"
              [submitted]="submitted"
            >
        </form-input> 
        <p class="little-normal-text">¿No recibiste el código?</p>
        <p class="little-normal-text hypertext" (click)="sendLoginCode()">Reenviar código</p>
        <button class="verify-button" (click)="verifyLoginCode()">Verificar código</button>
        <!-- <app-button
            class="app-button full-width"
            (onClickButton)="verifyLoginCode()"
            [label]="'COMMON.CONTINUE'"
          >
          </app-button> -->
    </div>
</div>
  
<div id="recaptcha-container"></div>

<!-- <input type="text" [(ngModel)]="phoneNumber" placeholder="Enter phone number">
  <button (click)="sendLoginCode()">Send Verification Code</button>

<input type="text" [(ngModel)]="verificationCode" placeholder="Enter verification code"> -->
