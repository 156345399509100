import { finalize } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CARD_ISSUER } from "../../../../enums/card-issuer";
import { ICreditCardDetails } from "../../../../models/credit-card.model";
import { ConektaService } from "../../../../services/conekta.service";
import { SpinnerService } from "../../../../services/spinner.service";
import { ECommerceApiService } from "../../../../services/e-commerce-api.service";
import { PricesService } from "../../../../services/prices.service";
import { CreditCardValidators } from "angular-cc-library";
import { ToastrMessageService } from "../../../../services/message.service";
import { ShoppingCarServiceService } from "../../../../services/shopping-car-service.service";
import { IErrorDefinition } from "../../../../models/error-definition.model";
import { IPurchase, IPurchaseItem } from "../../../../models/purchase.model";
import { Router } from "@angular/router";
import { CfdiServiceService } from "../../../../services/cfdi-service.service";
import { IUser } from "../../../../models/user.model";
import { UserService } from "../../../../services/user.service";

@Component({
  selector: "app-pay-method", 
  templateUrl: "./pay-method.component.html",
  styleUrls: ["./pay-method.component.less"],
})
export class PayMethodComponent implements OnInit {
  comision3: number = 0;
  comision6: number = 0;
  comision9: number = 0;
  comision12: number = 0;
  showCommission3: number = 0;
  showCommission6: number = 0;
  showCommission9: number = 0;
  showCommission12: number = 0;
  subtotal: number = 0;
  descuento: number = 0;
  comision: number = 0;
  iva: number = 0;
  totalCost: number = 0;
  preTotalCost: number = 0;
  cardSelected: boolean = false;
  creditCard: boolean = false;
  toMonths: boolean = false;
  Months: number = 0;

  percentage:number = 0;
  mostrar: boolean = false;

  cardOption:string = "Seleccionar"
  cardSend:string = ""
  bankOption:string = "Seleccionar"
  paymentOption:string = "Seleccionar"

  showRfcForm: boolean = false;
  submitted: boolean = false;

  facturable:boolean = false;

  userInformation!: IUser 

  newCardForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    country: new FormControl("Mexico", [Validators.required]),
    cfdi: new FormControl(""),
    address: new FormControl("", [Validators.required]),
    ccNumber: new FormControl("", [
      Validators.required,
      CreditCardValidators.validateCCNumber,
    ]),
    ccExp: new FormControl("", [
      Validators.required,
      CreditCardValidators.validateExpDate,
    ]),
    ccCVC: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4),
    ]),
  });


// Creación de instancias de TarjetaDeCredito
americanExpress:TarjetaDeCredito = new TarjetaDeCredito('American Express');
bancoBBVA:TarjetaDeCredito = new TarjetaDeCredito('Banco BBVA');
bancoCitibanamex:TarjetaDeCredito = new TarjetaDeCredito('Banco Citibanamex');
otrosBancos:TarjetaDeCredito = new TarjetaDeCredito('Otros Bancos');
bancos: TarjetaDeCredito[] = [];

  constructor(
    private userService:UserService,
    private conektaService: ConektaService,
    private apiService: ECommerceApiService,
    private spinnerService: SpinnerService,
    private priceService: PricesService,
    private toastrService: ToastrMessageService,
    private localStorageService: ShoppingCarServiceService,
    private router: Router,
    private cfdiService: CfdiServiceService
  ) {}

  ngOnInit(): void {
    
    this.userService.getUserObservable().subscribe((user) => {
      this.userInformation = user
      if(this.userInformation!=null){
        if(this.userInformation.rfc){
          this.facturable=true
        }
      }
    });
    this.priceService.getTotal$().subscribe((res: number) => {
      
      this.subtotal = this.priceService.fnCalculateTotals()._subtotal;
      this.descuento = this.priceService.fnCalculateTotals()._discount;
      if(this.descuento > 0){
        this.percentage = 15;
      }
      else{
        this.percentage = 0;
      }
      this.preTotalCost = res;
      this.iva = this.priceService.fnCalculateTotals()._iva;
      this.comision=0;
      this.bankOption='Seleccionar';
      this.paymentOption='Seleccionar';
    });
    this.newCardForm.controls['cfdi'].setValue(this.cfdiService.getCFDI());
    this.cardSelected=false;
    this.creditCard=false;

    this.comision3=0.035;
    this.comision6=0.065;
    this.comision9=0.095;
    this.comision12=0.120;
    this.percentage = 15;
    /* if(this.comision!=0){
      this.bankOption='Seleccionar';
      this.paymentOption='Seleccionar'
    } */

      this.americanExpress.agregarComision(3, 0.039);
      this.americanExpress.agregarComision(6, 0.069);
      this.americanExpress.agregarComision(9, 0.089);
      this.americanExpress.agregarComision(12, 0.109);
      this.bancoBBVA.agregarComision(3, 0.049);
      this.bancoBBVA.agregarComision(6, 0.084);
      this.bancoBBVA.agregarComision(9, 0.112);
      this.bancoBBVA.agregarComision(12, 0.141);
      this.bancoCitibanamex.agregarComision(3, 0.054);
      this.bancoCitibanamex.agregarComision(6, 0.078);
      this.bancoCitibanamex.agregarComision(9, 0.0875);
      this.bancoCitibanamex.agregarComision(12, 0.110);
      this.otrosBancos.agregarComision(3, 0.065);
      this.otrosBancos.agregarComision(6, 0.09);
      this.otrosBancos.agregarComision(9, 0.095);
      this.otrosBancos.agregarComision(12, 0.120);
      this.bancos = [this.americanExpress, this.bancoBBVA, this.bancoCitibanamex, this.otrosBancos];
  }

  selectCard(credit:boolean){
    this.setPago();
    this.cardSelected=true;
    this.creditCard=credit;
    this.cardSend="credit"
    if(!this.creditCard){
      this.cardOption="Tarjeta de Débito"
      this.cardSend = "debit"
      this.toMonths=false;
      this.Months=0;
      this.comision=0;
      this.bankOption="debit"
      this.paymentOption="debit"
      this.totalCost=this.preTotalCost+this.comision;
      this.iva = (this.subtotal-this.descuento+this.comision) *0.16;
      this.totalCost=this.preTotalCost;
    }
    else{
      this.cardOption="Tarjeta de Crédito"
      this.cardSend = "credit"
      this.bankOption = "Seleccionar"
      this.paymentOption="Seleccionar"
      this.generarComisiones();
    }
  }

  setPago()
  {
    var info = localStorage.getItem("infoPago");
      if(info){
      var info2 = JSON.parse(info)
      //console.log("pagos",info)
      if(info2 ){
        this.iva = info2.iva;
        this.descuento = info2.discount;
        this.subtotal = info2.subtotal;
        this.totalCost = info2.total;
      }
      
    }
  }
  abrir(){
    this.mostrar = true;
  }

  cerrar(){
    this.mostrar = false;
  }

  selectBankOption(option:string){
    this.bankOption = option;
    switch(option){
      case "Citibanamex":
        this.comision3=this.bancoCitibanamex.obtenerComisionPorMes(3);
        this.comision6=this.bancoCitibanamex.obtenerComisionPorMes(6);
        this.comision9=this.bancoCitibanamex.obtenerComisionPorMes(9);
        this.comision12=this.bancoCitibanamex.obtenerComisionPorMes(12);
        
        break;
      case "BBVA":
        this.comision3=this.bancoBBVA.obtenerComisionPorMes(3);
        this.comision6=this.bancoBBVA.obtenerComisionPorMes(6);
        this.comision9=this.bancoBBVA.obtenerComisionPorMes(9);
        this.comision12=this.bancoBBVA.obtenerComisionPorMes(12);
        break;
      case "American Express":
        this.comision3=this.americanExpress.obtenerComisionPorMes(3);
        this.comision6=this.americanExpress.obtenerComisionPorMes(6);
        this.comision9=this.americanExpress.obtenerComisionPorMes(9);
        this.comision12=this.americanExpress.obtenerComisionPorMes(12);
        break;
      case "Otro":
        this.comision3=this.otrosBancos.obtenerComisionPorMes(3);
        this.comision6=this.otrosBancos.obtenerComisionPorMes(6);
        this.comision9=this.otrosBancos.obtenerComisionPorMes(9);
        this.comision12=this.otrosBancos.obtenerComisionPorMes(12);
        break;
    }
    this.toMonths=false;
    this.Months=0;
    this.comision=0;
    this.totalCost = this.preTotalCost;
    this.paymentOption = "Seleccionar";
    this.generarComisiones();
  }
  generarComisiones(){
    this.showCommission3 = this.calculateCommission2(this.buscarComisionMasAlta(this.bancos,3),3);
    this.showCommission6 = this.calculateCommission2(this.buscarComisionMasAlta(this.bancos,6),6);
    this.showCommission9 = this.calculateCommission2(this.buscarComisionMasAlta(this.bancos,9),9);
    this.showCommission12 = this.calculateCommission2(this.buscarComisionMasAlta(this.bancos,12),12);
  }
  selectPaymentOption(option:string){
    this.paymentOption = option;
    switch(this.paymentOption){
      case "Pago en una sola exhibición":
        this.toMonths=false;
        this.Months=0;
        this.comision = 0;
        break;
      case "3 Meses":
        this.toMonths=true;
        this.Months=3;
        this.comision = this.showCommission3;
        break;
      case "6 Meses":
        this.toMonths=true;
        this.Months=6;
        this.comision = this.showCommission6;
        break;
      case "9 Meses":
        this.toMonths=true;
        this.Months=9;
        this.comision = this.showCommission9;
        break;
      case "12 Meses":
        this.toMonths=true;
        this.Months=12;
        this.comision = this.showCommission12;
        break;
        default:
        this.toMonths=false;
        this.Months=0;
        this.comision=0;
        break;
    }
    
    this.iva = (this.subtotal-this.descuento+this.comision) *0.16;
    this.totalCost=this.subtotal+this.comision+this.iva-this.descuento;
  }

  calculateCommission(commission:number, months:number){
    var tempComision = 0;
    var tempTotal = 0;
    var tempPre = this.preTotalCost;
    var noPreComiConekta = (tempPre * 0.034) + 3;
    var noComiConekta =noPreComiConekta*1.16;
    var caeCuenta = tempPre - noComiConekta;
    if(this.creditCard){
    var precomiConekta = (tempPre * 0.034) + 3 + (commission*tempPre);
    var comiConekta = precomiConekta*1.16;
    var comiCliente = comiConekta - noComiConekta;
    var retorno = tempPre - comiConekta;
    tempTotal = tempPre * caeCuenta / retorno;
    tempComision = (tempTotal - tempPre)/1.16;
    
  }
  
  return tempComision
  }
  calculateCommission2(commission:number, months:number){
    this.toMonths=true;
    this.Months=months;
    var tempComision = 0;
    var tempTotal = 0;
    var tempPre = this.preTotalCost;
    var noPreComiConekta = (tempPre * 0.034) + 3;
    var noComiConekta =noPreComiConekta*1.16;
    var caeCuenta = tempPre - noComiConekta;
    if(this.creditCard){
      var precomiConekta = (tempPre * 0.034) + 3 + (commission*tempPre);
      var comiConekta = precomiConekta*1.16;
      var comiCliente = comiConekta - noComiConekta;
      var retorno = tempPre - comiConekta;
      tempTotal = tempPre * caeCuenta / retorno;
      tempComision = (tempTotal - tempPre)/1.16;
  }
  return tempComision
  }


  submit() {
    //console.log("Se presionó")
    //console.log(this.toMonths,this.Months)
    if (this.newCardForm.invalid ) {
      
      this.submitted = true;
        return;
    }
    if(this.cardOption == "Tarjeta de Crédito" && (this.bankOption=="Seleccionar" || this.paymentOption=="Seleccionar")){
      if(this.bankOption=="Seleccionar"){
        //this.toastrService.showWarn("Seleccione banco")
        this.bankOption = "";
      }
      if(this.paymentOption=="Seleccionar"){
        this.toastrService.showWarn("Seleccione meses para pagar")
        
      }
      this.submitted = true;
      return;
    }
    this.submitted = false;
    this.spinnerService.show();
    const creditCard = this.getCreditCardData();

    let cfdiUser = ""
    if(this.userInformation.rfc){
      cfdiUser = this.cfdiService.getCFDI()
    }
    
    this.conektaService
      .getToken(creditCard)
      .then((res: any) => {
        const purchaseItems: IPurchaseItem[] =
          this.localStorageService.getPurchaseItems();
        //console.log(purchaseItems);
        const { _discount, _iva } = this.priceService.getObjTotalData();
        if(_discount <= 0){
          this.percentage = 0
        }
        else{
          this.percentage = 15;
        }
        var today = new Date();
        /* //console.log(today); */
        ////console.log(this.totalCost)
        const purchaseDetails: IPurchase = {
          purchaseDetails: purchaseItems,
          token: res.token,
          discount: _discount,
          iva: this.iva,
          total: this.totalCost, 
          card: creditCard.number,
          date: today,
          cfdi: cfdiUser,
          toMonths: this.toMonths,
          Months: this.Months,
          commission: this.comision,
          bank: this.bankOption,
          paymentMethod: this.cardSend,
          percentage:this.percentage // Cambiar cada vez que se cambie el descuento
        };
        ////console.log(purchaseDetails)
        ////console.log(purchaseItems)
        this.savePurchase(purchaseDetails);
        localStorage.removeItem("empresa");
      })
      
      .catch((rej) => {
        this.toastrService.showError("Tu compra ha sido declinada")
      });
  }

  private getCreditCardData() {
    let expirationDay: string[] = this.newCardForm!.get("ccExp")!.value!.split("/")!;
    let creditCard: ICreditCardDetails = {
      number: this.newCardForm.get("ccNumber")!.value!.split(" ").join(""),
      exp_month: expirationDay[0].trim(),
      exp_year: expirationDay[1].trim(),
      cvc: this.newCardForm.get("ccCVC")?.value!,
      name: this.newCardForm.get("name")?.value!,
      address: this.newCardForm.get("address")?.value!,
      country: this.newCardForm.get("country")?.value!,
    };

    return creditCard;
  }

  private savePurchase(purchase: IPurchase) {
    this.apiService
      .postSavePurchase(purchase)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (data) => {
          this.toastrService.showSuccess("Compra exitosa!");
          this.newCardForm.reset();
          this.newCardForm.get("country")?.setValue("Mexico");
          this.localStorageService.resetCart();
          /*Cambiar ruta*/
          this.router.navigate(["/aceptada"]);
        },
        ({ message }: IErrorDefinition) => {
          if(message=="Success"){
            this.toastrService.showSuccess("Compra exitosa!");
            this.newCardForm.reset();
            this.newCardForm.get("country")?.setValue("Mexico");
            this.localStorageService.resetCart();
            /*Cambiar ruta*/
            this.router.navigate(["/aceptada"]);
          }else{
            this.toastrService.showError(message)
          }
        }
        
      );
  } 

  switchRfcForm() {
    this.showRfcForm = !this.showRfcForm;
  }

  private getIssuer(firstNumber: number): CARD_ISSUER {
    if (firstNumber === CARD_ISSUER.AMEX) {
      return CARD_ISSUER.AMEX;
    } else if (firstNumber === CARD_ISSUER.VISA) {
      return CARD_ISSUER.VISA;
    } else if (firstNumber === CARD_ISSUER.MASTERCARD) {
      return CARD_ISSUER.MASTERCARD;
    }
    return CARD_ISSUER.UNKNOWN_CARD;
  }

  cambiarCFDI(e:any){
    //console.log(e.target.value)
    this.cfdiService.setCFDI(e.target.value)
  }

// Función para buscar la comisión más alta para un número específico de meses
 buscarComisionMasAlta(bancos: TarjetaDeCredito[], meses: number): number {
  let comisionMasAlta: number = 0;

  bancos.forEach(banco => {
    const comision = banco.obtenerComisionPorMes(meses);
    if (comision !== undefined && (!comisionMasAlta || comision > comisionMasAlta)) {
      comisionMasAlta =  comision ;
    }
  });
  //console.log("comision más alta",comisionMasAlta)
  return comisionMasAlta;
}


}
class ComisionPorMes {
  constructor(
    public meses: number,
    public comision: number // Cambiado a number para facilitar la comparación
  ) {}
}

// Clase para representar una tarjeta de crédito
class TarjetaDeCredito {
  nombre: string;
  comisiones: ComisionPorMes[];

  constructor(nombre: string) {
    this.nombre = nombre;
    this.comisiones = [];
  }

  // Método para agregar una comisión por mes
  agregarComision(meses: number, comision: number) {
    this.comisiones.push(new ComisionPorMes(meses, comision));
  }

  // Método para obtener la comisión por meses
  obtenerComisionPorMes(meses: number): number {
    let comi = 0;
    const comisionPorMes = this.comisiones.find(c => c.meses === meses);
    if(comisionPorMes)
       comi = comisionPorMes.comision;
    return  comi;
  }
}

