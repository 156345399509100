<div [ngClass]="['form-radio-check-button', className]" >
    <input
      type="radio"
      [id]="id"
      [ngClass]="isChecked ? 'checked' : 'not-checked'"
      [checked]="value"
      (input)="change($event)"
      [name]="name"
    />
    <div></div>
  </div>
