
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComponentsComponent } from "./components/components.component";
import { LandingComponent } from "./landing.component";
import { CommonModule } from "@angular/common";

const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
    },
    {
        path: 'components', 
        component: ComponentsComponent,
    },
    
];

@NgModule({
    imports: [CommonModule,RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LandingRoutingModule {
}