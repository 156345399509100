<div [ngClass]="['form-radio-button', className]">
    <label *ngIf="!!label" class="form-label-radio-button  ml-4"  [for]="id">
      {{ label | translate }}</label>
    <input
      type="radio"
      [id]="id"
      class="form-radio-button-input"
      [checked]="value"
      (input)="change($event)"
      [name]="name"
    />
</div>