<div class="contenedor" [ngClass]="{'ocultar': cardSelected}">
    <p class="subtitle subtitle-invisible">Seleccionar tipo de tarjeta</p>
    <div class="card-options">
        <div (click)="selectCard(false)" class="card-option">
            <p>Tarjeta de débito</p>
        </div>
        <div (click)="selectCard(true)" class="card-option">
            <p>Tarjeta de crédito</p>
        </div>
    </div>
    <img class="logo-sithec-mx" src="./../../../../../../../assets/images/sithec_MX.svg" alt="">
    <img class="logo-sithec-mx" src="./../../../../../../../assets/images/siboth-gray.svg" alt="">
</div>
<div class="container less-top row" [ngClass]="{'ocultar': !cardSelected}">
    <div class="col-md-10 col-12 mx-auto less-top">
        <div id="credit-card" class="tab-pane fade show active pt-3">
            <div class="card-options-info">
                <div (click)="selectCard(false)" class="card-option-info" [ngClass]="{'card-option-info-selected': !creditCard}">
                    <p>Tarjeta de débito</p>
                </div>
                <div (click)="selectCard(true)" class="card-option-info" [ngClass]="{'card-option-info-selected': creditCard}">
                    <p>Tarjeta de crédito</p>
                </div>
            </div>
            <div class="card-selector-responsive">
                <!-- <div class="card-option-info card-option-info-selected">
                    <p>{{creditCard?"Tarjeta de crédito":"Tarjeta de débito"}}</p>
                </div>
                <button class="button-change" (click)="abrir()">Cambiar</button> -->
                <div style="width: 100%;">
                    <label class="cvv-label" data-toggle="tooltip" title="Selecciona">
                        <h6 class="form-header">Tipo de Tarjeta</h6>
                    </label> 
                    <details [ngClass]="{'selection-detail': cardOption!='Seleccionar'}">
                        <summary><p class="subtitle" #seleccionTarjeta>{{cardOption}}</p></summary>
                        <table (click)="seleccionTarjeta.click()">
                            <tr class="option-card-info" (click)="selectCard(false)">
                                <td class="subtitle-black">Tarjeta de Débito</td>
                                <td><img src="./../../../../../../../assets/images/tarjeta_blue_002.svg" alt=""></td>
                            </tr>
                            <tr class="option-card-info" (click)="selectCard(true)">
                                <td class="subtitle-black">Tarjeta de Crédito</td>
                                <td><img src="./../../../../../../../assets/images/tarjeta_blue_002.svg" alt=""></td>
                            </tr>
                        </table>
                    </details>
                </div>
            </div>
            <form [formGroup]="newCardForm">
                <div class="row bank-info" [ngClass]="{'ocultar': !creditCard}">
                    <!--
                     <div class="col-sm-6 py-2">
                        <label class="cvv-label" data-toggle="tooltip" title="Selecciona">
                            <h6 class="form-header">Seleccionar Banco</h6>
                        </label> 
                        <details [ngClass]="{'selection-detail': bankOption!='Seleccionar', 'is-invalid-input': (bankOption=='Seleccionar' && submitted)}">
                            <summary><p class="subtitle" #seleccionBanco>{{bankOption}}</p></summary>
                            <table (click)="seleccionBanco.click()">
                                <tr class="option-bank-info" (click)="selectBankOption('Citibanamex')">
                                    <td class="subtitle-black">Citibanamex</td>
                                    <td><img src="./../../../../../../../assets/images/logo_banamex.png" alt=""></td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectBankOption('BBVA')">
                                    <td class="subtitle-black">BBVA</td>
                                    <td><img src="./../../../../../../../assets/images/logo_BBVA.svg" alt=""></td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectBankOption('American Express')">
                                    <td class="subtitle-black">American Express</td>
                                    <td><img src="./../../../../../../../assets/images/amex-logo.svg" alt=""></td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectBankOption('Otro')">
                                    <td class="subtitle-black">Otro</td>
                                    <td><img src="./../../../../../../../assets/images/other_logo.svg" alt=""></td>
                                </tr>
                            </table>
                        </details>
                    </div>
                    -->
                   

                    <div class="col-sm-12 py-2">
                        <label class="cvv-label" data-toggle="tooltip" title="Selecciona">
                            <h6  class="form-header">Opciones de Pago</h6>
                        </label> 
                        <details  [ngClass]="{'selection-detail': paymentOption!='Seleccionar', 'is-invalid-input': (paymentOption=='Seleccionar' && submitted)}">
                            <summary><p class="subtitle" #seleccion>{{paymentOption}}</p></summary>
                            <table (click)="seleccion.click()">
                                <tr>
                                    <td class="table-title">Opciones de Pago</td>
                                    <td class="table-title">Comisión</td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectPaymentOption('Pago en una sola exhibición')">
                                    <td class="subtitle-black">Pago en una sola exhibición</td>
                                    <td class="subtitle-black">$0.00</td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectPaymentOption('3 Meses')">
                                    <td class="subtitle-black">3 Meses</td>
                                    <td class="subtitle-black">{{ showCommission3 | currency}}</td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectPaymentOption('6 Meses')">
                                    <td class="subtitle-black">6 Meses</td>
                                    <td class="subtitle-black">{{ showCommission6 | currency}}</td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectPaymentOption('9 Meses')">
                                    <td class="subtitle-black">9 Meses</td>
                                    <td class="subtitle-black">{{ showCommission9 | currency}}</td>
                                </tr>
                                <tr class="option-bank-info" (click)="selectPaymentOption('12 Meses')">
                                    <td class="subtitle-black">12 Meses</td>
                                    <td class="subtitle-black">{{ showCommission12 | currency}}</td>
                                </tr>
                            </table>
                        </details>
                    </div>
                </div>
                <div class="py-2">
                    <form-input
                      label="CART.CARD_HOLDER"
                      placeholder="CART.CARD_HOLDER_PLACEHOLDER"
                      formControlName="name"
                      [control]="newCardForm.get('name')"
                      [submitted]="submitted"
                    ></form-input>
                </div>

                <div class="py-2">
                  <form-credit-card
                    type="ccNumber"
                    label="CART.CARD_NUMBER"
                    placeholder="CART.CARD_NUMBER_PLACEHOLDER"
                    formControlName="ccNumber"
                    [control]="newCardForm.get('ccNumber')"
                    [submitted]="submitted"
                  ></form-credit-card>
                </div>

                <div class="row">
                    <div class="col-sm-6 py-2">
                        <form-credit-card
                          type="ccExp"
                          label="CART.CARD_EXPIRATION"
                          formControlName="ccExp"
                          [control]="newCardForm.get('ccExp')"
                          [submitted]="submitted"
                        ></form-credit-card>
                    </div>
                    <div class="col-sm-6 py-2">
                      <label class="cvv-label" data-toggle="tooltip" title="Los tres digitos al reverso de tu tarjeta">
                        <h6 class="form-header">CVV <i class="fa fa-question-circle d-inline"></i></h6>
                      </label> 
                      <form-credit-card 
                        type="ccCVC"
                        formControlName="ccCVC"
                        [control]="newCardForm.get('ccCVC')" 
                        [submitted]="submitted"
                      ></form-credit-card>
                    </div>
                    <div class="row py-2" [ngClass]="{'no-facturable': !facturable}">
                        <div class="col-sm-6 py-2 form-header" >
                            <form-credit-card
                              placeholder="Código Postal"
                              label="Código Postal"
                              formControlName="address"
                              [control]="newCardForm.get('address')"
                              [submitted]="submitted"
                            ></form-credit-card>
                        </div>
                        <div class="col-sm-6 py-2">
                            <label class="cvv-label" data-toggle="tooltip" title="Selecciona tu uso de CFDI">
                                <h6 class="form-header">CFDI <i class="fa fa-question-circle d-inline"></i></h6>
                              </label> 
                            <select class="form-select" formControlName="cfdi" (change)="cambiarCFDI($event)">
                                <option value="Adquisición de Mecancías" selected>G01 Adquisición de Mercancías</option>
                                <option value="Gastos en General">G03 Gastos en General</option>
                                <option value="Equipo de Computo y Accesorios">I04 Equipo de Computo y Accesorios</option>
                                <option value="Sin Efectos Fiscales">S01 Sin Efectos Fiscales</option>
                            </select>
                        </div>
                        
                    </div>
                </div>
                <div class="row fix-mt" [ngClass]="{'no-facturable': facturable}">
                    <div class="col-12">
                        <div class="py-2">
                          <form-input
                            label="CART.ADDRESS"
                            placeholder="Código Postal"
                            formControlName="address"
                            [control]="newCardForm.get('address')"
                            [submitted]="submitted"
                          ></form-input>
                      </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="vista-normal">
            <div class="box">
                <div class="payment-info">
                    <div class="payment-info-row">
                        <p class="info">Subtotal:</p>
                        <p class="subtitle-black info-quantity info">{{subtotal | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="info">Descuento:</p>
                        <p class="subtitle-black info-quantity info">{{descuento | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="info">Comisión:</p>
                        <p class="subtitle-black info-quantity info">{{comision | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="info">IVA:</p>
                        <p class="subtitle-black info-quantity info">{{iva | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="subtitle info-quantity">Total:</p>
                        <p class="subtitle-black info-quantity info">{{totalCost | currency}}</p>
                    </div>
                </div>
            </div>
    
            <div class="btn-box" [ngClass]="{'entrance': showRfcForm}">
                <button class="btn btn-block" (click)="submit()">
                    {{'CART.CONFIRM_PAYMENT' | translate}} 
                </button>
            </div>
        </div>

        <div class="vista-responsive">
            <div class="box-responsive">
                <div class="payment-info-responsive">
                    <div class="payment-info-row">
                        <p class="info">Subtotal:</p>
                        <p class="subtitle-black info-quantity info">{{subtotal | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="info">Descuento:</p>
                        <p class="subtitle-black info-quantity info">{{descuento | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="info">Comisión:</p>
                        <p class="subtitle-black info-quantity info">{{comision | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class="info">IVA:</p>
                        <p class="subtitle-black info-quantity info">{{iva | currency}}</p>
                    </div>
                    <div class="payment-info-row">
                        <p class=" info subtitle info-quantity">Total:</p>
                        <p class="subtitle-black info-quantity info">{{totalCost | currency}}</p>
                    </div>
                </div>
            </div>
    
            <div class="btn-box" [ngClass]="{'entrance': showRfcForm}">
                <button class="btn btn-block" (click)="submit()">
                    {{'CART.CONFIRM_PAYMENT' | translate}} 
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrar}"> 
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Seleccionar tipo de tarjeta</h5>
            </div>
            <section class="modal-interior">
                <div class="modal-card-options">
                    <div class="modal-option" (click)="selectCard(false)">
                        <p>Tarjeta de débito</p>
                        <img [src]="!creditCard?'./../../../../../../../assets/images/selector_tradio_button_001.svg':'./../../../../../../../assets/images/radio_button_002.svg'" alt="">
                    </div>
                    <div class="modal-option" (click)="selectCard(true)">
                        <p>Tarjeta de crédito</p>
                        <img [src]="creditCard?'./../../../../../../../assets/images/selector_tradio_button_001.svg':'./../../../../../../../assets/images/radio_button_002.svg'">
                    </div>
                </div>
                <button class="button-accept" (click)="cerrar()">Aceptar</button>
            </section>
        </div>
    </div>
</div>    