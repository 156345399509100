<div class="container-fluid reset-password-container margen">
  <div class="row justify-content-center">
   
    <!-- <div class="col-lg-4 col-md-6 col-sm-12" *ngIf="!hasParameterErrors"> -->
    <div class="col-lg-6 col-md-6 col-sm-12">
      <!-- <div *ngIf="!requestSent" class="card"> -->
      <div class="card">  
        <h2 class="title">Reestablecer Contraseña</h2>
        <p class="text">Ingrese la nueva contraseña:</p>
        <form
          class="mt-2"
          [formGroup]="updatePasswordForm"
          (ngSubmit)="submit()"
          novalidate
        >
          
          
          <div class="password-input">
            <form-input-password
              name="password"
              label="{{ 'AUTH.NEW_PASSWORD' | translate }} *"
              placeholder="***********"
              type="password"
              formControlName="password"
              [control]="updatePasswordForm.controls['password']"
              [submitted]="submitted"
            >
            </form-input-password>

            <form-input-password
              name="passwordConfirm"
              label="{{ 'AUTH.CONFIRM_PASSWORD' | translate }} *"
              placeholder="***********"
              type="password"
              formControlName="passwordConfirm"
              [control]="updatePasswordForm.controls['passwordConfirm']"
              [submitted]="submitted"
            >
            </form-input-password>
          </div>
          
          <div class="botones">
            <app-button
              class="app-button ajuste color-claro"
              [label]="'Confirmar'"
              type="submit"
            >
            </app-button>
            <a (click)="redirectToLogin()" class="text">Cancelar</a>
          </div>
        </form>
      </div>
      <!-- <div  *ngIf="requestSent"> -->
      <div  *ngIf="requestSent">  
        <div>
          <h2 class="title">
            {{ "AUTH.UPDATE_PASSWORD_SUCCESSFUL" | translate }}
          </h2>
          <div>
            <p class="text">
              {{ "AUTH.SAVE_CHANGES" | translate }}
            </p>
            <p class="text">
              {{ "AUTH.UPDATE_PASSWORD_MESSAGE" | translate }}
            </p>
          </div>
          <app-button
            class="app-button full-width"
            (onClickButton)="redirectToLogin()"
            [label]="'COMMON.CONTINUE'"
          >
          </app-button>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-4 col-md-6 col-sm-12 my-3" *ngIf="hasParameterErrors">
      <div class="card">
        <h2 class="title">
          {{ 'AUTH.INVALID_REQUEST' | translate }}
        </h2>
        <p class="text">
          {{ 'AUTH.REQUEST_NEW_PASWORD_MESSAGE' | translate }}
        </p>
     
          <app-button
            class="app-button full-width"
            (onClickButton)="redirectToForgotPassword()"
            [label]="'COMMON.BACK'"
          >
          </app-button>
      </div>
     

    </div> -->
  </div>
</div>
