import { NgModule } from "@angular/core";

@NgModule({
    declarations: [
       
    ],
    exports: [
        
    ]
  })
  
  export class PipesModule {}