<div class="acomodo">
    <div class="elementos-arriba">
        <div class="titulo-carrito">
            <h2 class="estilos-titulo">{{ "CART.TITLE" | translate }}</h2>
            <img src="../../../../../assets/images/carrito.png">
        </div>
        <app-button
            class="full-width app-button-rounded app-button-secondary hover-button ajuste"
            [label]="'CART.SEE_MORE' | translate"
            type="submit"
            (onClick)="redirigir()" 
        ></app-button>
    </div>
    <div class="tabset">
        <label class="menuTab" [ngClass]="{'selected': cartSelected}" (click)="cartSelected = true">{{ "CART.OPT_CART" | translate }}</label>
        <label class="menuTab" [ngClass]="{'selected': !cartSelected}" (click)="checkForUserClickConfirm()">{{ "CART.OPT_PAY_METHOD" | translate }}</label>

        <div class="tab-panels">
            <div id="cart" class="tab-panel" [ngClass]="{'selected': cartSelected}">
                <div class="animated-box">
                    <app-cart-items [confirmAction]="confirmAction" (onNextStep)="checkForUser()"></app-cart-items>
                </div>
            </div>
            <div id="payment" class="tab-panel" [ngClass]="{'selected': !cartSelected}">
                <div class="animated-box">
                    <app-pay-method></app-pay-method>
                </div>
            </div>
        </div>
    </div>
</div>