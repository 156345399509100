<div class="forbidden">
    <div class="d-flex justify-content-center align-items-center">
        <div>
            <img src="assets/images/error-icon.png"/>
        </div>
        <div>
            <h1>403</h1>
            <p>
            {{ "ERROR.403" | translate }}
            </p>
        </div>
    </div>
</div>