import { activeCategories, IProductCategory } from '../../../models/product.model' ;
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { ToastrMessageService } from "../../../services/message.service";
import { SpinnerService } from "../../../services/spinner.service";
import { ECommerceApiService } from "../../../services/e-commerce-api.service";
import { ProductInformationService } from "../../../services/product-information.service";
import { Router } from "@angular/router";
import { PlatformUtil } from 'src/app/models/platformUtil';

@Component({
  selector: "app-packages-available",
  templateUrl: "./packages-available.component.html",
  styleUrls: ["./packages-available.component.less"],
})
export class PackagesAvailableComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer?: ElementRef;

  activeIndexSelected!: number;

  cardItems = [
     {
       name: "Evaluatec",
       urlImage: "/assets/images/evaluathec_logo.svg",
       productType: "EVALUATEC"
     },
     {
       name: "Microsoft",
       urlImage: "/assets/images/microsoft-landing.svg",
       productType: "MICROSOFT",
       color: "#FFF"
     },
    {
      name: "CONTPAQi®",
      urlImage: "/assets/images/contpaq logo.svg",
      productType: "CONTPAQi",
      color: "#FFF"
    },
     {
       name: "TS - Plus",
       urlImage: "/assets/images/ts plus.png",
       productType: "TS_PLUS",
       color: "#FFF"
     },
     {
       name: "XML SAT",
       urlImage: "/assets/images/XML SAT landing.svg",
      productType: "XML_SAT",
       color: "#FFF"
     },
     {
       name: "Antivirus",
       urlImage: "/assets/images/ANTIVIRUS_landing.svg",
       productType: "ANTIVIRUS",
       color: "#FFF"
    },
    {
      name: "Soporte/Asesoria",
      urlImage: "/assets/images/siboth soporte.svg",
      productType: "SERVICIOS",
      color: "#FFF"
    }
  ]
  packagesAvailable!:activeCategories;
  numerosVistas:number = 0;
  currentIndex = 0;
  currentItems: any[] = [];
  itemCount = 1;
  itemPackage!: IProductCategory[];
  constructor(
    private eCommerceApiService: ECommerceApiService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrMessageService, 
    private productInformation : ProductInformationService,
    private router: Router,
    private platformUtil: PlatformUtil
  ) {
    
    //console.log("--> PackagesAvailableComponent")
  }
   
  
  // Obtener los elementos filtrados
  filteredCardItems !:any;
  
  ngOnInit(): void {
    this.spinnerService.show();
    // this.eCommerceApiService
    //   .getProductsAvailablePerCategory()
    //   .pipe(finalize(() => this.spinnerService.hide()))
    //   .subscribe(
    //     (productCategory: IProductCategory[]) => {
    //       this.itemPackage = productCategory;
    //       this.activeIndexSelected = 0;
    //       //console.log(this.itemPackage)
    //     },
    //     ({ message }: IErrorDefinition) => this.toastrService.showError(message)
    //   );
    if(this.platformUtil.isBrowser()){
      this.getPackagesAvailable()
      this.getSize();
      this.updateItems();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.getSize()
  }

  getSize(){
    if(this.platformUtil.isBrowser()){
      this.currentIndex = this.numerosVistas;
        if (window.innerWidth <1005) {
          this.itemCount = 1;
        } else if (window.innerWidth < 1600) {
          if(this.packagesAvailable.count < 2){
            this.itemCount = this.packagesAvailable.count;
          }
          else
          this.itemCount = 2;
        } else if (window.innerWidth < 1860) {
          if(this.packagesAvailable.count < 3){
            this.itemCount = this.packagesAvailable.count;
          }
          else
          this.itemCount = 3;
        } else {
          if(this.packagesAvailable.count < 4){
            this.itemCount = this.packagesAvailable.count;
          }
          else
          this.itemCount = 4;
        }
      if(this.itemCount == 1){
        this.updateItems();
      }
      else if(this.itemCount == 2){
        this.updateItems();
      }
      else if(this.itemCount == 3){
        this.updateItems();
      }
      else if(this.itemCount == 4){
        this.updateItems();
      }
    }
  }
private updateItems() {
    this.currentItems = []

   // Obtener los elementos actuales basados en el índice actual y el número de elementos a mostrar
   for(let i=0;i<this.itemCount;i++){
    if(this.currentIndex > this.filteredCardItems.length-1){
      this.currentIndex = 0;
    }
    else if(this.currentIndex < 0){
       this.currentIndex = this.filteredCardItems.length-1;
    }
      this.currentItems.push(this.filteredCardItems[this.currentIndex])
      this.currentIndex++;
   }

}
getPackagesAvailable(){
  this.eCommerceApiService.getProductsCategoryActive()
  .subscribe(
    (response) =>{
      this.packagesAvailable = response;
      //console.log(this.packagesAvailable)
      this.filteredCardItems = this.filterCardItemsByServerResponse();
    //console.log(this.filteredCardItems)
      this.getSize();
    }
  )
}
    goNext() {
      this.numerosVistas++;
      if(this.numerosVistas > this.filteredCardItems.length-1){
        this.numerosVistas = 0;
      }
      else if(this.numerosVistas < 0){
         this.numerosVistas = this.filteredCardItems.length-1;
      }
      this.currentIndex = this.numerosVistas;
      this.updateItems();
    }

    goBack() {
      this.numerosVistas--;
      if(this.numerosVistas > this.filteredCardItems.length-1){
        this.numerosVistas = 0;
      }
      else if(this.numerosVistas < 0){
         this.numerosVistas = this.filteredCardItems.length-1;
      }
      this.currentIndex = this.numerosVistas;
      this.updateItems();
    }


  getBootstrapClasses(): string {
    let itemCount = 0;
    if (window.innerWidth <940) {
        itemCount = 1;
        this.filteredCardItems.slice(0, itemCount);
        return 'col-md-1'; // 3 columnas en pantallas pequeñas
        
    } else if (window.innerWidth < 1600) {
        itemCount = 2;
        this.filteredCardItems.slice(0, itemCount);
        return 'col-lg-2'; // 2 columnas en pantallas medianas
        
    } else if (window.innerWidth < 1800) {
        itemCount = 2;
        this.filteredCardItems.slice(0, itemCount);
        return 'col-xl-3'; // 2 columnas en pantallas grandes
    } else {
        itemCount = 4;
        this.filteredCardItems.slice(0, itemCount);
        return 'col-xl-4'; // 1 columna en pantallas extra grandes
    }
}
  selectedIndex(index: number): void {
    this.activeIndexSelected = index;
    this.productInformation.setIndex(index);
  }

  get activeItemsPackage() {
    return this.activeIndexSelected >= 0
      ? this.itemPackage[this.activeIndexSelected].productsList
      : [];
  }

  isActive(index: number) {
    return index == this.activeIndexSelected ? "active" : "";
  }

  goTo(item: any): void {
    this.router.navigate(['/product/'+item.productType]);
  }

  scrollDiv(scroll: number) {
    const containerElement = this.scrollContainer?.nativeElement as HTMLElement;
    containerElement.scrollLeft += scroll; // Ajusta la cantidad de desplazamiento según tus necesidades
  }

  filterCardItemsByServerResponse() {
    const serverNames = this.packagesAvailable.results.map(item => item.name);
    return this.cardItems.filter(item => serverNames.includes(item.productType));
  }
}
