import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'lib-authentication-page',
  templateUrl: './authentication-page.component.html',
  styleUrls: ['./authentication-page.component.less']
})
export class AuthenticationPageComponent {
  private returnUrl!: string;
  public showLogin: boolean = true;

  constructor(
    private activateRoute: ActivatedRoute,
    private spinnerService:SpinnerService
  ) {}

  public ngAfterViewInit(): void {
   /*  this.activateRoute.queryParams.subscribe((parameters) => {
      this.returnUrl = parameters[RETURN_URL_PARAMETER_KEY];
    }); */
  }

  public changeView(value:boolean):void {
    this.showLogin = value;
  }
}
