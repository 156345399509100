
<div class="recuadro">
    <div class="row responsive-goback" [hidden]="!isMobileView">
        <div class="go-back col-2" >
          <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()" id="imgCONTPAQi" >
        </div>
        <div class="col-10">
            <img *ngIf="!showAnimation" id="imgProducto" [src]="imagen" alt="Product" style="max-width: 100%;">
        </div>
      </div>
      <img  [hidden]="isMobileView" *ngIf="showAnimation" id="animatedImage" [src]="imagen" alt="Product" class="animated-image">
    <div id="renovacion" class="opciones" [formGroup]="productForm">
        <p class="titulo">Renovación de Licencia</p>
        <div class="informacion-general">    
            <p class="nombre-producto">{{producto.name}}</p>
            <p class="texto text-adjustment">Al seleccionar "RENOVACIÓN" dispondrás de 365 días extra a los que te resten a partir del día de tu compra</p>
            <div class="d-flex">
                <p class="subtitulo ajuste-subtitulo">Numero de serie:</p> <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="showTutorial()">
            </div><div class="buscador">
                <input class="campo-busqueda" type="text" style="text-transform:uppercase" #busqueda>
                <app-button
                    class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                    [label]="'Buscar' | translate"
                    type="submit"
                    (onClick)="getInfoSerialNumber(busqueda.value)"
                    >
                </app-button>
            </div>
            <div *ngIf="encontrado">
                <div class="separador"></div>
                <p class="texto">Fecha de registro: {{registro}}</p>
                <p class="texto">Vigencia: {{vigencia}}</p>
                <p class="texto">Usuarios: {{usuariosInfo}}</p>
                <p class="texto">Empresas: {{empresas}}</p>
                <p class="texto">Licenciamiento: {{licenciamiento}}</p>
                <div class="acomodo" *ngIf="!estatus">
                    <p class="texto">Número de Usuarios:</p>
                    <div class="container-quantity">
                        <span class="next" (click)="addQuantity()"></span>
                        <span class="prev" (click)="removeQuantity()"></span>
                        <div id="box">
                            <span>{{usuarios}}</span>
                        </div>
                    </div>
                </div>
                <p class="subtitulo ajuste-subtitulo">Precio:</p>
                <div class="selectores-precio" *ngIf="mostrarCombo">
                    <div class="selector-precio">
                        <input id="compra1" type="radio" value="1" name="compra" formControlName="compra" (change)="cambiarCombo($event)">
                        <label for="compra1">Quiero comprar clásica</label>
                        <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="mostrarInfo('Compra clásica: Se refiere a la compra individual de cualquier sistema o sistemas ligados a distintos RFC')">
                    </div>
                    <div class="selector-precio" *ngIf="producto?.comboEnable">
                        <input id="compra2" type="radio" value="2" name="compra" formControlName="compra" (change)="cambiarCombo($event)">
                        <label for="compra2">Quiero comprar en combo</label>
                        <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="mostrarInfo('Compra en combo: Al hacer tu compra de 2 o más licencias bajo una misma razón social obtienes 15% de descuento')">
                    </div>
                </div>
                <div class="calculo-precio">
                    <app-button
                    class="full-width app-button-rounded app-button-secondary hover-button ajuste ajuste-verde"
                    [label]="'Calcular'"
                    type="submit"
                    (onClick)="cotizar()"
                    >
                    </app-button>
                    <div class="acomodo margenes separacion-boton">
                        <p class="texto espaciado ajuste">Subtotal:</p>
                        <p class="subtitulo ajuste">{{total | currency}}</p>
                    </div>
                </div>
                <div *ngIf="cotizado">
                    <app-button
                        class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                        [label]="'CART.ADD' | translate"
                        type="submit"
                        (onClick)="addCard()"
                        >
                    </app-button>
                </div>
            </div>
        </div>     
    </div>
</div>
