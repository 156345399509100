<!-- <div class="vista_paquetes">
    <div class="container mt-5 ">
        <div class="row p-2 main-widget acomodo">
            <div class="opcion tab-contables" (click)="selectedIndex(0)" [ngClass]="isActive(0)">
                <img src="/assets/images/categoria_contables_active.png" class="imagen_opcion" alt="Contables"  />
                <p class="grey-text text-center h5 mt-2 tittle-contables letra">Contables</p>
            </div>
            <div class="opcion tab-contables" (click)="selectedIndex(1)" [ngClass]="isActive(1)">
                <img src="/assets/images/categoria_comerciales_active.png" class="imagen_opcion" alt="Contables"  />
                <p class="grey-text text-center h5 mt-2 tittle-contables letra">Comerciales</p>
            </div>
        </div>
    </div>
    
    <div class="container mt-5 productos-contables" >
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4">
            <ng-container *ngFor="let packageitemsSelected of activeItemsPackage;let i=index" > 
                <div class="col m-0 p-0 mb-4">
                    <app-card-information [configurationCard]="packageitemsSelected" [seleccion]="i"></app-card-information>
                </div>
            </ng-container>    
        </div>
    </div>
</div> -->

<div class="row views-packages">
    <div class="description">
        Tenemos las mejores herramientas para optimizar y potenciar tu proyecto
    </div>
    <div class="row justify-content-center">
        <div class="container-packages">
            <div class="go-back" (click)="goBack()">
                <img src="/assets/images/go-back.png" alt="">
            </div>
            <div class="container-package" #scrollContainer>
                <ng-container *ngFor="let itemCard of currentItems">
                    <div class="card-package" (click)="goTo(itemCard)"  [ngClass]="getBootstrapClasses()">
                        <div class="card-img"  style="background-color:{{itemCard.color}}">
                            <img class="card-img-top" [src]="itemCard.urlImage" alt="Card image cap">
                        </div>
                        <div class="card-body-package">
                            <div class="text">{{itemCard.name}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="go-next" (click)="goNext()">
                <img src="/assets/images/go-next.png" alt="">
            </div>
        </div>
    </div>
</div>