import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})

export class SpinnerComponent  {
  public showSpinner : Observable<boolean>;
    
  constructor(
      private spinnerService : SpinnerService) { 
      this.showSpinner = this.spinnerService
        .getShowSpinnerObservable();   
  }  
}
