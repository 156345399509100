import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-input-password',
  templateUrl: './form-input-password.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputPasswordComponent),
      multi: true
    }
  ],
  styleUrls: ['./form-input-password.component.less']
})
export class FormInputPasswordComponent implements ControlValueAccessor {
  public value: any = null;
  public type = 'password';
  @Input() label = '';
  @Input('class') className = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() control!:AbstractControl;
  @Input() submitted: boolean = false;
  fieldTextType: boolean = false;

  public onChange(val: any): void {}
  public onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(event: any): void {
    this.value = event.target.value;
    this.onChange(event.target.value);
    this.onTouched();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  getIconClass(): string {
    return this.fieldTextType ? 'visibility' : 'visibility_off';
  }

  get errorClass() {
    return this.hasErrorInput()
      ? `is-invalid-input`
      : '';
  }

  hasErrorInput():boolean {
    return (
      (this.submitted && this.control?.invalid) ||
      (this.control &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }
}