import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "form-input",
  templateUrl: "./form-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
  styleUrls: ["./form-input.component.less"],
})
export class FormInputComponent implements ControlValueAccessor {
  value: any = null;

  @Input() uppercase:boolean = false;
  @Input() type = 'text';
  @Input() label = '';
  @Input("class") className = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() control: any;
  @Input() submitted: boolean = false;
  @Input() appearance: 'standard' | 'fill' = 'standard';

  writeValue(val: any): void {
    this.value = val;
  }

  public onChange(val: any): void {}
  public onTouched(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(event: any): void {
    this.value = event?.target?.value;
    this.onChange(this.value);
    this.onTouched();
  }

  get errorClass() {
    return this.hasErrorInput()
      ? `${this.appearance} is-invalid-input`
      : this.appearance;
  }

  hasErrorInput():boolean {
    return (
      (this.submitted && this.control?.invalid) ||
      (this.control &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }
}
