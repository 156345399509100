import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ACCOUNT_VERIFICATION_STATUS_COMPLETED } from "../constants/signup.constants";
import { IAccountVerification } from "../models/account-verification.model";
import { IUser } from "../models/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _user: IUser | null = null;
  private userBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public static nullUser: IUser = {
    id: "",
    name: "",
    lastName: "",
    accountVerification: null,
    username: "",
    rfc:"",
    razon:"",
    certificado:false,
    calle:"",
    numero_exterior:"", 
    numero_interior:"",
    estado:"",
    regimen:"",
    cp:"",
    persona:""

  };

  constructor() {}

  public get user(): IUser {
    return this._user ?? UserService.nullUser;
  }

  public setUser(user: IUser) {
    this._user = user;
    this.userBehaviorSubject.next(this._user);
    this.saveLocalStorage(user);
  }

  public saveLocalStorage(user:IUser){
    localStorage.setItem('user',JSON.stringify(user))
  }

  public hasVerifiedAccount(): boolean {
    return (
      !!this.user &&
      !!this.user.accountVerification &&
      this.user.accountVerification.status ===
        ACCOUNT_VERIFICATION_STATUS_COMPLETED
    );
  }

  public getUserObservable(): Observable<IUser> {
    return this.userBehaviorSubject.asObservable();
  }

  public isAuthenticated(): boolean {
    return this.user && this.user != UserService.nullUser && !!this.user.id;
  }

  public updateAccountVerification(
    accountVerification: IAccountVerification
  ): void {
    this.user.accountVerification = accountVerification;
  }

}
