<div
  *ngIf="
    submitted ||
    (control && control.invalid && (control.dirty || control.touched))
  "
>
  <ng-container [ngSwitch]="getErrors()[0]">
    <span *ngSwitchCase="'required'"
      >*{{ 'INPUT_ERRORS.REQUIRED' | translate }}</span
    >
    <span *ngSwitchCase="'pattern'"
      >*Escribe al menos una letra en mayúscula, una en minúscula, un número y un carácter</span
    >
    <span *ngSwitchCase="'email'"
      >*{{ 'INPUT_ERRORS.INVALID_EMAIL' | translate }}</span
    >
    <span *ngSwitchCase="'min'"
      >*{{ 'INPUT_ERRORS.INVALID_QUANTITY' | translate }}</span
    >
    <span *ngSwitchCase="'max'"
      >*{{ 'INPUT_ERRORS.INVALID_QUANTITY' | translate }}</span
    >
    <span *ngSwitchCase="'minlength'"
      >*{{ 'INPUT_ERRORS.INVALID_TEXT_LENGTH' | translate }}</span
    >
    <span *ngSwitchCase="'maxlength'"
      >*{{ 'INPUT_ERRORS.INVALID_TEXT_LENGTH' | translate }}</span
    >
    <span *ngSwitchCase="'mustMatch'"
      >*El campo es diferente</span
    >
    <span *ngSwitchCase="'rangeSalary'"
      >*{{ 'INPUT_ERRORS.RANGE_SALARY' | translate }}</span
    >
    <span *ngSwitchCase="'ccNumber'"
      >*{{ 'INPUT_ERRORS.CREDIT_CARD_NUMBER' | translate }}</span
    >
    <span *ngSwitchCase="'expDate'"
      >*{{ 'INPUT_ERRORS.CREDIT_CARD_DATE' | translate }}</span
    >
  </ng-container>
</div>