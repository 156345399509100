<div class="contenido">
    <div  class="recuadro">
        <div class="arriba">
            <p class="titulo">Información de mi cuenta</p>
        </div>
        <!-- (ngSubmit)="submit()" -->
        <form [formGroup]="updateForm"  novalidate>
            <section class="campos">
                <aside class="campo">
                    <form-input
                        name="name"
                        label="{{ 'AUTH.USER_NAME' | translate }} *"
                        placeholder="AUTH.USER_NAME_PLACEHOLDER"
                        formControlName="name"
                        [control]="updateForm.controls['name']"
                        [submitted]="submitted"
                    >
                    </form-input>
                
                    <form-input
                        name="email"
                        label="{{ 'AUTH.USER_EMAIL' | translate }} *"
                        placeholder="AUTH.USER_EMAIL_PLACEHOLDER"
                        type="email"
                        formControlName="username"
                        [control]="updateForm.controls['username']"
                        [submitted]="submitted"
                    >
                    </form-input>
                    
                </aside>
                <aside class="campo">
                    <form-input
                        name="lastName"
                        label="{{ 'AUTH.USER_LAST_NAME' | translate }} *"
                        placeholder="AUTH.LAST_NAME_PLACEHOLDER"
                        formControlName="lastName"
                        [control]="updateForm.controls['lastName']"
                        [submitted]="submitted"
                    >
                    </form-input>
                    <form-input
                        name="phone"
                        label="No. Celular *"
                        placeholder="AUTH.USER_PHONE_PLACEHOLDER"
                        type="phone"
                        formControlName="phone"
                        [control]="updateForm.controls['phone']"
                        [submitted]="submitted"
                    >
                    </form-input> 
                </aside>
            </section>
            <section class="campos margen-abajo-campo">
                <aside class="campo">
                    <form-switch rightLabel="Deseo facturar" (onChangeElement)="solicitarDatosFactura($event)" [value]="comprobacionFactura"></form-switch>
                </aside>
            </section>
            <section class="campos" [ngClass]="{'ocultar': !solicitarDatos}">
                <aside class="campo">
                    <form-input
                    name="rfc"
                    label="{{ 'RFC*' | translate }}"
                    placeholder="RFC"
                    type="text"
                    formControlName="rfc"
                    [control]="updateForm.controls['rfc']"
                    [submitted]="submitted"
                    [uppercase]="true"
                  >
                  </form-input> 
                  
          
                  <form-input
                    name="cp"
                    label="{{ 'Código Postal*' | translate }}"
                    placeholder="Escribe tu código postal"
                    type="number"
                    formControlName="cp"
                    [control]="updateForm.controls['cp']"
                    [submitted]="submitted"
                  >
                  </form-input> 
          
                    
          
                <form-input
                    name="numero_interior"
                    label="{{ 'No. interior' | translate }}"
                    placeholder="Escribe tu numero interior"
                    type="text"
                    formControlName="numero_interior"
                    [control]="updateForm.controls['numero_interior']"
                    [submitted]="submitted"
                    class="size-adjustment"
                >
                </form-input> 
          
                <form-input
                        name="colonia"
                        label="{{ 'Colonia' | translate }}"
                        placeholder="Escribe tu colonia"
                        type="text"
                        formControlName="colonia"
                        [control]="updateForm.controls['colonia']"
                        [submitted]="submitted"
                    >
                    </form-input> 
                  
                  
                  <form-input
                        name="estado"
                        label="{{ 'Estado' | translate }}"
                        placeholder="Escribe tu estado"
                        type="text"
                        formControlName="estado"
                        [control]="updateForm.controls['estado']"
                        [submitted]="submitted"
                        >
                  </form-input> 
                  <div>
                    <label class="form-label margen-campo">Régimen Fiscal</label>
                    <div class="selectores-persona"> 
                      <div class="selector-persona">
                        <input type="radio" value="Persona Física" name="persona" formControlName="persona" (change)="cambiarSeleccionRazonSocial($event)">
                        <label>Persona Física</label>
                      </div>
                      <div class="selector-persona">
                        <input type="radio" value="Persona Moral" name="persona" formControlName="persona" (change)="cambiarSeleccionRazonSocial($event)">
                        <label>Persona Moral</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="form-label">Uso de CFDI (Predeterminado)</label>
                    <select class="form-select" name="cfdi" formControlName="cfdi" (change)="cambiarCFDI($event)" [ngClass]="{'no-facturable': !userInformation.rfc}">
                        <option value="Adquisición de Mercancías" selected>G01 Adquisición de Mercancías</option>
                        <option value="Gastos en General">G03 Gastos en General</option>
                        <option value="Equipo de Computo y Accesorios">I04 Equipo de Computo y Accesorios</option>
                        <option value="Sin Efectos Fiscales">S01 Sin Efectos Fiscales</option>
                      </select>
                  </div>
                </aside>
                <aside class="campo">
                    <form-input
                        name="razon"
                        label="{{ 'Razón Social*' | translate }}"
                        placeholder="Razón social"
                        type="text"
                        formControlName="razon"
                        [control]="updateForm.controls['razon']"
                        [submitted]="submitted"
                        [uppercase]="true"
                    >
                    </form-input> 
                    <form-input
                        name="calle"
                        label="{{ 'Calle' | translate }}"
                        placeholder="Escribe tu calle"
                        type="text"
                        formControlName="calle"
                        [control]="updateForm.controls['calle']"
                        [submitted]="submitted"
                        >
                    </form-input> 
                    <form-input
                      name="numero_exterior"
                      label="{{ 'No. exterior' | translate }}"
                      placeholder="Escribe tu numero exterior"
                      type="number"
                      formControlName="numero_exterior"
                      [control]="updateForm.controls['numero_exterior']"
                      [submitted]="submitted"
                      class="size-adjustment"
                    >
                    </form-input> 
                    <form-input
                        name="municipio"
                        label="{{ 'Municipio' | translate }}"
                        placeholder="Escribe tu municipio"
                        type="text"
                        formControlName="municipio"
                        [control]="updateForm.controls['municipio']"
                        [submitted]="submitted"
                    >
                    </form-input> 
                    
                    <div class="espaciado">
                        <div [ngClass]="{'ocultar': personaFisica}">
                            <select class="form-select" name="regimen" formControlName="regimen" >
                              <option value="General de Ley Personas Morales" selected>601 - General de Ley Personas Morales</option>
                              <option value="Personas Morales con Fines no Lucrativos">603 - Personas Morales con Fines no Lucrativos</option>
                              <option value="Régimen de Enajenación o Adquisición de Bienes">607 - Régimen de Enajenación o Adquisición de Bienes</option>
                              <option value="Consolidación">609 - Consolidación</option>
                              <option value="Sociedades Cooperativas de Producción que optan por diferir sus ingresos">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                              <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                              <option value="Opcional para Grupos de Sociedades">623 - Opcional para Grupos de Sociedades</option>
                              <option value="Coordinados">624 - Coordinados</option>
                              <option value="Hidrocarburos">628 - Hidrocarburos</option>
                            </select>
                          </div>
                          
                          <div [ngClass]="{'ocultar': !personaFisica}">
                            <select class="form-select" name="regimen" formControlName="regimen">
                              <option value="Sueldos y Salarios e Ingresos Asimilados a Salarios" selected>605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                              <option value="Arrendamiento">606 - Arrendamiento</option>
                              <option value="Demás ingresos">608 - Demás ingresos</option>
                              <option value="Ingresos por Dividendos (socios y accionistas)">611 - Ingresos por Dividendos (socios y accionistas)</option>
                              <option value="Personas Físicas con Actividades Empresariales y Profesionales">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
                              <option value="Ingresos por intereses">614 - Ingresos por intereses</option>
                              <option value="Regimen de los ingresos por obtención de premios">615 - Regimen de los ingresos por obtención de premios</option>
                              <option value="Sin obligaciones fiscales">616 - Sin obligaciones fiscales</option>
                              <option value="Incorporación Fiscal">621 - Incorporación Fiscal</option>
                              <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                              <option value="De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
                              <option value="Enajenación de acciones en bolsa de valores">630 - Enajenación de acciones en bolsa de valores</option>
                              <option value="Residentes en el Extranjero sin Establecimiento Permanente en México">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                              <option value="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                              <option value="Régimen Simplificado de Confianza">626 - Régimen Simplificado de Confianza</option>
                            </select>
                          </div>
                    </div>
                    
                    
                </aside>
            </section>
            <section class="campos margen-campo" [ngClass]="{'ocultar': !solicitarDatos}">
                <aside class="campo iconos">
                    <p class="texto-estatus"><span class="texto-rezaltado">Estatus de Constancia de Situación Fiscal:</span> {{verificarCarga()}}</p>
                    <img src="../../../../../assets/images/Botecito_eliminar.svg" alt="" class="icono-borrar" [ngClass]="{'ocultar':!this.userInformation.certificado}" (click)="eliminarArchivo()">
                    <img src="../../../../../assets/images/ver detalle_icon.svg" alt="" class="icono-ver" [ngClass]="{'ocultar':!this.userInformation.certificado}" (click)="redirigirConstancia()">
                </aside>
                <aside class="campo campo-botones" >
                    <div class="file-button" >
                        <input id="file" type="file" name="file" (change)="setFile($event)" accept="application/pdf" #fileInput >
                        <button mat-raised-button color="primary" (click)="fileInput.click()">
                            Cargar Constancia de Situación Fiscal
                        </button>
                    </div>
                    <div class="botones">
                        <!-- <app-button
                            class="full-width app-button-rounded app-button-grey hover-button ajuste margen"
                            [label]="'Cancelar' | translate"
                            type="submit"
                            (onClick)="setUserData(true)"
                        ></app-button> -->
                        <app-button
                            class="full-width app-button-rounded app-button-grey hover-button ajuste margen"
                            [label]="'Cancelar' | translate"
                            type="submit"
                            (onClick)="cancelar()"
                        ></app-button>
                        <app-button
                            class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                            [label]="'Actualizar' | translate"
                            type="submit"
                            (onClick)="submit()"
                        ></app-button>
                    </div>
                </aside>
            </section>     
            <section [ngClass]="{'ocultar': solicitarDatos}" class="margen-arriba">
                <div class="botones-sin-datos">
                    <!-- <app-button
                        class="full-width app-button-rounded app-button-grey hover-button ajuste margen"
                        [label]="'Cancelar' | translate"
                        type="submit"
                        (onClick)="setUserData(true)"
                    ></app-button> -->
                    <app-button
                        class="full-width app-button-rounded app-button-grey hover-button ajuste margen"
                        [label]="'Cancelar' | translate"
                        type="submit"
                        (onClick)="cancelar()"
                    ></app-button>
                    <div class="margen-izquierda">
                        <app-button
                            class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                            [label]="'Actualizar' | translate"
                            type="submit"
                            (onClick)="submit()"
                        ></app-button>
                    </div>
                </div>
            </section>       
        </form>
        
        <!-- <div class="contenedor-informacion" [formGroup]="updateForm">
            <div class="informacion-general">
                <div class="etiquetas">
                    <div class="etiqueta">
                        <label>Contacto:</label>
                    </div>
                    <div class="etiqueta">
                        <label>Empresa:</label>
                    </div>
                    <div class="etiqueta">
                        <label>Teléfono:</label>
                    </div>
                    <div class="etiqueta">
                        <label>Correo Electrónico:</label>
                    </div>
                    <div class="etiqueta">
                        <label>Domicilio:</label>
                    </div>
                    <div class="etiqueta"></div>
                    <div class="etiqueta">
                        <label>No. Exterior:</label>
                    </div>
                    <div class="etiqueta">
                        <label>RFC:</label>
                    </div>
                    <div class="etiqueta">
                        <label>Régimen Fiscal:</label>
                    </div>
                    <div class="etiqueta" [ngClass]="{'no-facturable': !userInformation.rfc}">
                        <label>CFDI:</label>
                    </div>
                </div>
                <div class="campos">
                    <div class="campos-dobles">
                        <input class="bloque-chico" type="text" formControlName="name">
                        <input class="bloque-grande" type="text" formControlName="lastName">
                    </div>
                    <input type="text" formControlName="razon">
                    <div class="campos-dobles">
                        <input class="bloque-grande" type="text" formControlName="phone">
                        <div class="etiqueta margen-relativo">
                            <label>CP:</label>
                        </div>
                        <input class="bloque-mediano" type="text" formControlName="cp">
                    </div>
                    <input type="text" formControlName="username">
                    <div class="campos-dobles">
                        <input class="bloque-chico" type="text" formControlName="calle">
                        <input class="bloque-grande" type="text" formControlName="colonia">
                    </div>
                    <div class="campos-dobles">
                        <input class="bloque-chico" type="text" formControlName="estado">
                        <input class="bloque-grande" type="text" formControlName="municipio">
                    </div>
                    <div class="campos-dobles">
                        <input class="bloque-mediano" type="text" formControlName="numero_exterior">
                        <div class="etiqueta margen-relativo">
                            <label class="etiqueta-fija">No. Interior:</label>
                        </div>
                        <input class="bloque-mediano" type="text" formControlName="numero_interior">
                    </div>
                    <div class="campos-dobles">
                        
                        <input class="bloque-mediano" type="text" formControlName="rfc">
                        <div class="selectores-persona bloque-mediano"> 
                            <div class="selector-persona">
                              <input type="radio" value="Persona Física" name="persona" formControlName="persona" (change)="cambiarSeleccionRazonSocial($event)">
                              <label>Persona Física</label>
                            </div>
                            <div class="selector-persona">
                              <input type="radio" value="Persona Moral" name="persona" formControlName="persona" (change)="cambiarSeleccionRazonSocial($event)">
                              <label>Persona Moral</label>
                            </div>
                          </div>
                    </div>
                    <div [ngClass]="{'ocultar': personaFisica}">
                        <select class="form-select" name="regimen" formControlName="regimen" >
                            <option value="General de Ley Personas Morales" selected>601 - General de Ley Personas Morales</option>
                            <option value="Personas Morales con Fines no Lucrativos">603 - Personas Morales con Fines no Lucrativos</option>
                            <option value="Régimen de Enajenación o Adquisición de Bienes">607 - Régimen de Enajenación o Adquisición de Bienes</option>
                            <option value="Consolidación">609 - Consolidación</option>
                            <option value="Sociedades Cooperativas de Producción que optan por diferir sus ingresos">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                            <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                            <option value="Opcional para Grupos de Sociedades">623 - Opcional para Grupos de Sociedades</option>
                            <option value="Coordinados">624 - Coordinados</option>
                            <option value="Hidrocarburos">628 - Hidrocarburos</option>
                        </select>
                    </div>
                        
                    <div [ngClass]="{'ocultar': !personaFisica}">
                        <select class="form-select" name="regimen" formControlName="regimen">
                            <option value="Sueldos y Salarios e Ingresos Asimilados a Salarios" selected>605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                            <option value="Arrendamiento">606 - Arrendamiento</option>
                            <option value="Demás ingresos">608 - Demás ingresos</option>
                            <option value="Ingresos por Dividendos (socios y accionistas)">611 - Ingresos por Dividendos (socios y accionistas)</option>
                            <option value="Personas Físicas con Actividades Empresariales y Profesionales">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
                            <option value="Ingresos por intereses">614 - Ingresos por intereses</option>
                            <option value="Regimen de los ingresos por obtención de premios">615 - Regimen de los ingresos por obtención de premios</option>
                            <option value="Sin obligaciones fiscales">616 - Sin obligaciones fiscales</option>
                            <option value="Incorporación Fiscal">621 - Incorporación Fiscal</option>
                            <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                            <option value="De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
                            <option value="Enajenación de acciones en bolsa de valores">630 - Enajenación de acciones en bolsa de valores</option>
                            <option value="Residentes en el Extranjero sin Establecimiento Permanente en México">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                            <option value="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                            <option value="Régimen Simplificado de Confianza">626 - Régimen Simplificado de Confianza</option>
                        </select>
                    </div>
                    <select class="form-select" name="cfdi" formControlName="cfdi" (change)="cambiarCFDI($event)" [ngClass]="{'no-facturable': !userInformation.rfc}">
                        <option value="Adquisición de Mecancías" selected>G01 Adquisición de Mecancías</option>
                        <option value="Gastos en General">G03 Gastos en General</option>
                        <option value="Equipo de Computo y Accesorios">I04 Equipo de Computo y Accesorios</option>
                        <option value="Sin Efectos Fiscales">S01 Sin Efectos Fiscales</option>
                    </select>
                </div>
            </div>
        </div>
        <section class="status">
            <p class="margen"><span class="texto-rezaltado">Estatus de Constancia de Situación Fiscal:</span> {{verificarCarga()}}</p>
            <app-button
                class="full-width app-button-rounded app-button-success hover-button ajuste-constancia"
                [label]="'Cargar Constancia de Situación Fiscal' | translate"
                type="submit"
                (onClick)="abrirModalCarga()"
            ></app-button>
        </section>
        <section class="botones">
            <app-button
                class="full-width app-button-rounded app-button-grey hover-button ajuste margen"
                [label]="'Cancelar' | translate"
                type="submit"
                (onClick)="setUserData(true)"
            ></app-button>
            <app-button
                class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                [label]="'Actualizar' | translate"
                type="submit"
                (onClick)="submit()"
            ></app-button>
        </section> -->
    </div>
</div>

<!-- Modal detalles -->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrarCarga}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <div class="ajuste-detalle">
                <h5 class="modal-title">Cargar Constancia</h5>
            </div>
        </div>
        <section class="file-information">
            <div class="file-button">
                <input id="file" type="file" name="file" (change)="setFile($event)" #fileInput >
                <button mat-raised-button color="primary" (click)="fileInput.click()">
                    {{(!file) ? 'Seleccionar Archivo' : 'Archivo: ' + file.name }}
                </button>
            </div>
            <div [ngClass]="{'warning':!mostrarWarning}">
                <p class="warning-p">{{message}}</p>
            </div>
        </section>
        <app-button
            class="full-width app-button-rounded app-button-secondary hover-button"
            [label]="'Aceptar' | translate"
            type="submit"
            (onClick)="cerrarModalCarga()"
        ></app-button>
      </div>
    </div>
</div>
