<div class="login-container">
  <form [formGroup]="loginForm" (ngSubmit)="submit()" novalidate>
    <form-input
      name="email"
      label="{{ 'AUTH.USER_EMAIL_PHONE' | translate }} *"
      placeholder="AUTH.USER_EMAIL_PHONE_PLACEHOLDER"
      type="email"
      formControlName="username"
      [control]="loginForm.controls['username']"
      [submitted]="submitted"
    >
    </form-input>
  
    <form-input-password
      name="password"
      label="{{ 'AUTH.USER_PASSWORD' | translate }} *"
      placeholder="*********"
      formControlName="password"
      [control]="loginForm.controls['password']"
      [submitted]="submitted"
    >
    </form-input-password>
  
    <label *ngIf="admin && validar" style="color: red;">Credenciales invalidas</label>

    <div class="d-flex flex-column my-1">
      <app-button
        class="full-width w-80 d-flex justify-content-center"
        [label]="'AUTH.ENTER_ACCOUNT'"
        type="submit"
      >
      </app-button>
    </div>

  </form>
  
  <div *ngIf="!admin" class="account-options">
    <div class="account-option">
      <p class="question">¿Olvidaste tu contraseña?</p>
      <a class="passwd" href="javascript:void(0)"   (click)="redirectForgotPassword()">  {{'AUTH.RECOVER_YOUR_PASSWORD' | translate}}</a>
    </div>
    <div class="account-option">
      <p class="question">¿Aún no tienes cuenta?</p>
      <a class="a-button" href="javascript:void(0)"  (click)="this.showSignUp.emit()">
        <p>{{"AUTH.SIGN_UP" | translate}}</p>
      </a>
    </div>
    <div class="account-option" style="margin-top: 15px;">
      <p class="question">¿Tienes Problemas para Iniciar sesión?</p>
      <p class="question">Envíanos un WhatsApp +52 1 449 120 0294</p>
    </div>
  </div>
</div>