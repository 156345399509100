<div class="items"> 
    <div  *ngIf="emptyCartBool" class="expansion">
        <h3 class="text-center">{{"CART.EMPTY" | translate}}</h3>
    </div>
    
    <div class="table-responsive d-none d-md-block ajustador">
        <table class="tabla vista-escritorio" *ngIf="!emptyCartBool">
            <thead>
                <tr>
                    <th class="ajuste-quitar" scope="col">Tipo de compra</th>
                    <th scope="col">{{ "CART.TD_PRODUCT" | translate }}</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">{{ "CART.TD_USERS" | translate }}</th>
                    <th class="ajuste-quitar" scope="col">Tipo de Licencia</th>
                    <th scope="col">Precio Unitario</th>
                    <th scope="col">Precio Total</th>
                    <th class="ajuste-quitar" scope="col">Quitar</th>
                    <th class="ajuste-quitar" scope="col">Detalles</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!emptyComboBool">
                    <td class="pt-td"></td>
                    <td>
                        <img src="../../../../../../../assets/images/COMPAQi COMBO nuevo icono.svg" alt="product" class="img-product" >
                    </td>
                    <td class="pt-td"><div id="box">
                        <span>1</span>
                    </div></td>
                    <td class="pt-td"></td>
                    <td class="pt-td"></td>
                    <td class="pt-td"></td>
                    <td class="price pt-td">{{calcularTotalCombo() | currency}}</td>  
                    <td class="pt-td">
                        <img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirmDeleteCombo()">
                    </td>
                    <td class="total"><img src="../../../../../assets/images/ver detalle.svg" alt="detalles" class="verDetalles"  (click)="abrirCombo()">
                </tr>
                <tr *ngFor="let product of products[0]; let i = index;">
                    <ng-container *ngIf="product.productType !== 'CONTPAQi' ;else TipoCompraNoCONTPAQi">
                        <td class="purchase-type"><p>{{product.name}}</p></td>
                    </ng-container>
                    <ng-template #TipoCompraNoCONTPAQi>
                        <td class="purchase-type"><p>{{retornarLicencia(product.tipoLicencia,product.productType)}}</p></td>
                    </ng-template>
                    <td>
                        <img src="{{product.headerImage}}" alt="product" class="img-product">
                    </td>
                    <td class="pt-td">
                        
                        <ng-container *ngIf="product.usersQuantity === 0 && product.productType !== 'XML_SAT'">
                            <div id="box">
                                <span>{{product.quantity}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="product.usersQuantity != 0  &&( product.productType === 'CONTPAQi'  || product.productType === 'TS_PLUS' || product.productType === 'MICROSOFT')">
                            <div class="container-quantity" >
                                <span class="next" (click)="addQuantity(product.identificador, product.name, product.purchaseType, 0)"></span>
                                <span class="prev" (click)="removeQuantity(product.identificador, product.purchaseType, 0)"></span>
                                <div id="box">
                                    <span>{{product.quantity}}</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="( product.productType !== 'CONTPAQi'  && product.productType !== 'TS_PLUS' && product.productType !== 'MICROSOFT') ">
                            <div id="box">
                                <span>{{product.quantity}}</span>
                            </div>
                        </ng-container>
                    </td>
                    <td class="pt-td">
                        <ng-container *ngIf="product.usersQuantity === 0 || product.productType !== 'CONTPAQi'  ">
                            <div id="box">
                                <span>N/A</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="product.usersQuantity != 0 && product.productType === 'CONTPAQi'  ">
                            <div class="container-quantity" *ngIf="isModifiable(product.purchaseType); else userNotModifiable">
                                <span class="next" (click)="addQuantity(product.identificador, product.name, product.purchaseType, 1)"></span>
                                <span class="prev" (click)="removeQuantity(product.identificador, product.purchaseType, 1)"></span>
                                <div id="box">
                                    <span>{{product.usersQuantity}}</span>
                                </div>
                            </div>
                            <ng-template #userNotModifiable>
                                <div id="type">
                                    <span>{{product.usersQuantity}}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </td>
                    <td class="pt-td">
                        <ng-container *ngIf="!product.name?.toLowerCase()?.includes('contpaq')">
                            <div id="box">
                                <span>N/A</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="product.name?.toLowerCase()?.includes('contpaq')">
                            <ng-container *ngIf="product.productFeatures.annualLicenseMultiRFC && product.productFeatures.annualLicense1RFC; else NoCambio">
                               <form-select [control]="select" (change)="cambiarSeleccion($event, product.identificador)" *ngIf="isModifiable(product.purchaseType); else selectNotModifiable"> 
                                    <form-option [value]="'Mono'" [seleccionado]="product.isMono" [unico]="product.isUnique">Mono</form-option>
                                    <form-option [value]="'Multi'" [seleccionado]="product.isMulti" [unico]="product.isUnique">Multi</form-option>
                                </ form-select>
                            </ng-container>
                            <ng-template #NoCambio>
                                <div id="type">
                                    <span *ngIf="product.isMulti">Multi</span>
                                    <span *ngIf="product.isMono">Mono</span>
                                </div>
                                
                            </ng-template>
                        </ng-container> 
                        
                        <ng-template #selectNotModifiable class="not-selectable">
                            <div id="type">
                                <span>
                                    {{product.isMono ? "Mono":"Multi"}}
                                </span>
                            </div>
                            <!-- <form-select [control]="select" (change)="cambiarSeleccion($event, product.identificador)"> 
                                <form-option [value]="'Mono'" [seleccionado]="product.isMono" [unico]="true">Mono</form-option>
                                <form-option [value]="'Multi'" [seleccionado]="product.isMulti" [unico]="true">Multi</form-option>
                            </form-select> -->
                        </ng-template>
                    </td>
                    <td class="price pt-td">{{calcularSubtotal(product) | currency}}</td>
                    <td class="price pt-td">{{calcularCosto(product, product.quantity) | currency}}</td>  
                    <td class="pt-td">
                        <img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirm(product.identificador)">
                        <!-- <app-button class="app-button-danger" label="COMMON.DELETE" (onClick)="confirm(product.identificador)"></app-button> -->
                    </td>
                    <td class="total"><img src="../../../../../assets/images/ver detalle.svg" alt="detalles" class="verDetalles" (click)="abrirModal(product)">
                </tr>
            </tbody>
        </table>
        <p-confirmDialog *ngIf="this.platformUtil.isBrowser()" [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
        <p-toast position="bottom-center"></p-toast>
    </div>
    <div class="vista-responsive" *ngIf="!emptyCartBool">
        <div *ngFor="let product of products[0]; let i = index;">
            <table>
                <tr>
                    <th>Tipo de compra</th>
                    <th>Producto</th>
                    <th>Precio U.</th>
                    <th>Total</th>
                </tr>
                <tr>
                    <td><img class="produto" src="{{product.headerImage}}" alt="product"></td>
                    <td class="produto pt-td"> <p>{{product.name}}</p></td>
                    <td class="price pt-td">{{calcularSubtotal(product) | currency}}</td>
                    <td class="price pt-td">{{calcularCosto(product, product.quantity) | currency}}</td>
                    
                </tr>
            </table>
            <table>
                <tr>
                    <th>Licencias</th>
                    <th>Usuarios</th>
                    <th *ngIf="product.type=='CONTPAQi'">Tipo licencia</th>
                    <th>Quitar</th>
                    <!-- <td class="edicion" rowspan="2">
                        <td class="pt-td"><img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirm(product.identificador)"></td>
                        <img class="boton-editar" src="../../../../../../../assets/images/editar_icon.png" (click)="abrir(product.identificador, this.product.name, product.quantity, product.usersQuantity, product.isUnique, product.isMulti, product.isMono)">
                        <p>Editar</p>
                    </td> -->
                </tr>
                <tr>
                    <td class="price pt-td">{{product.quantity}}</td>
                    <td class="price pt-td">{{product.usersQuantity}}</td>
                    <td class="price pt-td"*ngIf="product.productType=='CONTPAQi'">{{tipoLicencia(product.isMono, product.isMulti)}}</td>
                    <td class="edicion" rowspan="2">
                        <img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirm(product.identificador)">
                        <img *ngIf="product.productType =='CONTPAQi'" class="boton-editar" src="../../../../../../../assets/images/editar_icon.png" (click)="abrir(product)">
                        <img *ngIf="product.productType !='CONTPAQi'" class="boton-editar" src="../../../../../assets/images/ver detalle.svg" alt="detalles"  (click)="abrirModal(product)">
                    </td>
                </tr>
            </table>
            <div class="tablita"></div>
        </div>
        <table *ngIf="!emptyComboBool">
            <tr>
                <th scope="col">Producto</th>
                <th scope="col">Total</th>
                <th class="ajuste-quitar" scope="col">Quitar</th>
            </tr>
            <tr>
                <td class="combo-column">
                   <img src="../../../../../../../assets/images/COMPAQi COMBO nuevo icono.svg" alt="product" class="img-product cursor" width="70%">
                </td>
                <td class="price pt-td">{{calcularTotalCombo() | currency}}</td>  
                <td class="edicion" rowspan="2">
                    <img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirmDeleteCombo()">
                    <img src="../../../../../assets/images/ver detalle.svg" alt="detalles" class="boton-editar"  (click)="abrirCombo()">
                </td>
            </tr>
        </table>
        <p-confirmDialog *ngIf="this.platformUtil.isBrowser()" [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
        <p-toast position="bottom-center"></p-toast>
    </div>

    <hr class="decorador">
    <app-cart-operations class="ajuste-detalles" [confirmAction]="confirmActionChild" [productsCart]="priceDataArray" (onNextStep)="nextStep()" *ngIf="!emptyCartBool"></app-cart-operations>
</div>


<!--Modal responsivo para productos tipo contpaq-->
<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrar}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <div class="row">
                <h5 class="modal-title2"><img src="../../../../../../../assets/images/editar_icon.png" alt=""> Editar Producto</h5>
            </div>
            <div class="row">
                 <img src="{{productModal.headerImage}}" alt="product" class="img-product">
            </div>
          
         
        </div>
       
        <section class="seccionModal">
            <div class="row">
                <div class="col-6"><p class="resaltado">Licencia</p></div>
                <div class="col-6"><p class="center resaltado2">{{retornarLicencia(productModal.tipoLicencia,productModal.productType)}}</p></div>
            </div>
            
            
        </section>
        <section class="numero-licencias">
            <div class="row">
                <div class="col-6"> <p>Licencias</p></div>
                <div class="col-6">
                    <ng-container *ngIf="productModal.usersQuantity != 0  &&( productModal.productType === 'CONTPAQi'  || productModal.productType === 'TS_PLUS' || productModal.productType === 'MICROSOFT')">
                        <div class="container-quantity" >
                            <span class="next2" (click)="addQuantity(productModal.identificador, productModal.name, productModal.purchaseType, 0)"></span>
                            <span class="prev2" (click)="removeQuantity(productModal.identificador, productModal.purchaseType, 0)"></span>
                            <div id="box2">
                                <span>{{productModal.quantity}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
           
            
            
        </section>
        <section class="numero-licencias">
            <div class="row">
                <div class="col-6"><p>Usuarios</p></div>
                <div class="col-6">
                    <ng-container *ngIf="productModal.usersQuantity != 0 && productModal.productType === 'CONTPAQi'  ">
                        <div class="container-quantity" *ngIf="isModifiable(productModal.purchaseType); else userNotModifiable">
                            <span class="next2" (click)="addQuantity(productModal.identificador, productModal.name, productModal.purchaseType, 1)"></span>
                            <span class="prev2" (click)="removeQuantity(productModal.identificador, productModal.purchaseType, 1)"></span>
                            <div id="box2">
                                <span>{{productModal.usersQuantity}}</span>
                            </div>
                        </div>
                        <ng-template #userNotModifiable>
                            <div id="type">
                                <span>{{productModal.usersQuantity}}</span>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            
            
        </section>
        <hr>
        <section>
            <section class="seccionModal">
                <div class="row">
                    <div class="col-6"><p class="resaltado">Licencia</p></div>
                </div>
            </section>
            <div class="seleccion-licencia">
                <ng-container *ngIf="productModal.productFeatures?.annualLicenseMultiRFC && productModal.productFeatures?.annualLicense1RFC; else NoCambio">
                    <div class="row">
                        <div class="col-6">
                            <label class="RadioModal">
                                <input type="radio" [checked]="productModal.isMono" (change)="onRadioChange('mono')" />
                                Mono
                            </label>
                        </div>
                        <div class="col-6">
                            <label class="RadioModal">
                                <input type="radio" [checked]="productModal.isMulti" (change)="onRadioChange('multi')" />
                                Multi
                            </label>
                        </div>
                        
                        
                      </div>
                 </ng-container>
                 <ng-template #NoCambio>
                     <div class="RadioModal" >
                         <span *ngIf="productModal.isMulti">Multi</span>
                         <span *ngIf="productModal.isMono">Mono</span>
                     </div>
                     
                 </ng-template>
                
            </div>
          
        </section>
        <hr>
        <section class="boton">
            <app-button
                class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                [label]="'Listo'"
                type="submit"
                (onClick)="cerrar()"
            >
            </app-button>
        </section>
        <hr>
      </div>
    </div>
</div>

<!-- Modal detalles Combo -->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrarCombo}" id="abrirCombo" *ngIf="!isMobileView"> 
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header  modal-d-header">
                <div class="ajuste-detalle">
                    <img src="../../../../../../../assets/images/COMPAQi COMBO nuevo icono.svg" alt="product" class="img-product">
                    <img src="../../../../../assets/images/out_modal.svg" class="cursor" alt="" (click)="cerrarCombo()">
                </div>
            </div>
            <div class="productos-combo row justify-content-center ">
                <div *ngFor="let product of combo[0]; let i = index;" class="producto-combo">
                    <div class="col-3 imgComboCenter background-black">
                        <img src="{{product?.headerImage}}" class="img-product img-product2">
                    </div>
                    
                    <div class="caracteristicas-combo col-6">
                        <div class="row"><p  class="d-title">{{product.name}}</p></div>
                        <div class="row">
                            <div class="col-6">
                                <p  class="d-extra">Usuarios: </p>
                                <p class="d-extra">Licencias: </p>
                                <p class="d-extra">Empresas: </p>
                                <p class="d-extra">Tipo de Licencia:</p>
                                <p class="d-extra" *ngIf="product.License">No. Serie: </p>
                                <p class="d-extra" >Precio: </p>
                            </div>
                            <div class="col-6">
                                <p  class="d-extra">{{product.usersQuantity}}</p>
                                <p class="d-extra">{{product.quantity}}</p>
                                <p class="d-extra">{{product.isMulti?"Multiempresa":"Monoempresa"}}</p>
                                <p class="d-extra">{{product.purchaseType}}</p>
                                <p class="d-extra" *ngIf="product.License"> {{product.License}} </p>
                                <p class="d-extra">
                                    {{
                                        (
                                        product.isMulti? 
                                            (product.selectedCost || 0) + ((product.usersQuantity || 0) -1) * (product.costMultiRFCUA || 0) : 
                                            (product.selectedCost || 0) + ((product.usersQuantity || 0) -1) * (product.costMonoRFCUA || 0)  
                                        ) | currency 
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 imgComboCenter">
                        <img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirmCombo(product.identificador)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Modal responsivo para combo-->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block': mostrarCombo}" id="abrirComboResponsive" *ngIf="isMobileView">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header modal-d-header">
                <div class="ajuste-detalle">
                    <img src="../../../../../../../assets/images/COMPAQi COMBO nuevo icono.svg" alt="product" class="img-product">
                    <img src="../../../../../assets/images/out_modal.svg" class="cursor" alt="" (click)="cerrarCombo()">
                </div>
            </div>
            <div class="productos-combo row justify-content-center">
                <div *ngFor="let product of combo[0]; let i = index;" class="producto-combo w-100">
                    <div class="row w-100">
                        <div class="col-12 col-md-3 imgComboCenter background-black">
                            <img src="{{product?.headerImage}}" class="img-product img-product2">
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 caracteristicas-combo">
                            <div class="row">
                                <div class="col-10"><p class="d-title">{{product.name}}</p></div>
                                <div class="col-2"><img class="boton-eliminar" src="../../../../../../../assets/images/delete_eliminar_carrito de compras.svg" (click)="confirmCombo(product.identificador)"></div>
                                </div>
                            <div class="row">
                                <div class="col-12">
                                    <p class="d-extra">Usuarios: {{product.usersQuantity}}</p>
                                    <p class="d-extra">Licencias: {{product.quantity}}</p>
                                    <p class="d-extra">Empresas: {{product.isMulti ? "Multiempresa" : "Monoempresa"}}</p>
                                    <p class="d-extra">Tipo de Licencia: {{product.purchaseType}}</p>
                                    <p class="d-extra" *ngIf="product.License">No. Serie: {{product.License}}</p>
                                    <p class="d-extra">Precio: {{
                                        (
                                        product.isMulti? 
                                            (product.selectedCost || 0) + ((product.usersQuantity || 0) -1) * (product.costMultiRFCUA || 0) : 
                                            (product.selectedCost || 0) + ((product.usersQuantity || 0) -1) * (product.costMonoRFCUA || 0)  
                                        ) | currency }}
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal detalles productos 
   -->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrarDetalle}" id="abrirCombo"> 
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header modal-d-header">
                <div class="ajuste-detalle">
                    <img src="{{productModal.headerImage}}" alt="product" class="img-product">
                    <img src="../../../../../assets/images/out_modal.svg" class="cursor" alt="" (click)="cerrarModal()">
                </div>
            </div>
            <div class="productos-combo">
                    <div class="d-content">
                        <p class="d-title">{{productModal.name}}</p>
                        <p class="d-sub-title2">{{productModal.purchaseType}}</p>
                        <table class="d-extra">
                            <!--
                            <tr>
                                <td><p class="d-extra">Correo Electronico: </p></td>
                                <td><p class="d-extra">{{productModal?.purchaseType}}</p></td>
                            </tr>-->
                            <tr>
                                <td><p class="d-extra">Periodicidad </p></td>
                                <td><p class="d-extra">{{toSpanish(productModal.lapse)}}</p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType=='CONTPAQi'">
                                <td><p class="d-extra">Usuarios: </p></td>
                                <td><p class="d-extra">{{productModal.usersQuantity}}</p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType=='CONTPAQi' && (productModal?.purchaseType=='Cambio de Características' || productModal?.purchaseType=='Renovación')">
                                <td><p class="d-extra">No de Serie: </p></td>
                                <td><p class="d-extra">{{productModal.License}}</p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType=='CONTPAQi' && (productModal?.isMono || productModal?.isMulti) ">
                                <td><p class="d-extra">Empresa: </p></td>
                                <td *ngIf="productModal.isMono"><p class="d-extra">Mono-usuario</p></td>
                                <td *ngIf="productModal.isMulti"><p class="d-extra">Multi-empresa</p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType=='CONTPAQi' && productModal.purchaseType">
                                <td><p class="d-extra">Tipo de Licencia: </p></td>
                                <td><p class="d-extra">{{productModal.purchaseType}} </p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType!='CONTPAQi' && productModal?.productType!='EVALUATEC'">
                                <td><p class="d-extra">Cantidad: </p></td>
                                <td><p class="d-extra">{{productModal.quantity}}</p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType=='XML_SAT'">
                                <td><p class="d-extra">IMEI: </p></td>
                                <td><p class="d-extra">{{productModal.imei}}</p></td>
                            </tr>
                            <tr *ngIf="productModal?.productType=='EVALUATEC'">
                                <td><p class="d-extra">Email: </p></td>
                                <td><p class="d-extra">{{productModal.email}}</p></td>
                            </tr>
                        </table>
                        
                    </div>
            </div>
        </div>
    </div>
</div>
    
