import { Component, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SithecCommonApiService } from "../../../services/sithec-common-api.service";
import { CountdownComponent, CountdownEvent } from "ngx-countdown"; 
import { finalize } from "rxjs/operators";
import { IErrorDefinition } from "../../../models/error-definition.model";
import { SpinnerService } from "../../../services/spinner.service";
import { RecaptchaComponent } from "../recaptcha/recaptcha.component";
import { Auth, RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from '@angular/fire/auth';
import { ToastrMessageService } from "../../../services/message.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.less"],
})
export class ForgotPasswordComponent {
  center: string =`text-align: center;`
  public submitted = false;

  public requestSent: boolean = false;

  @ViewChild("recaptcha")
  public recaptcha!: RecaptchaComponent;


  forgotPasswordForm: FormGroup = new FormGroup({
    username: new FormControl("", [Validators.required, this.emailOrPhoneValidator() ])
  });


   emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   phonePattern = /^\d{10}$/;

   private recaptchaVerifier!: RecaptchaVerifier;
   confirmationResult: ConfirmationResult | undefined;
   phoneNumber:string = "";
   verifyForm: FormGroup = new FormGroup(
      {
        code: new FormControl('', Validators.required)
      }
    );

   showRequest:boolean = false;
   showCode:boolean = false;
   placeholder:string = "";
   label:string = "";
  constructor(
    private router: Router,
    private sithecApiService: SithecCommonApiService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrMessageService,
    private auth: Auth,
  ) {}

   public executeRecaptcha(): void {
    if (this.forgotPasswordForm.invalid) {
      this.submitted = true;
      return;
    }
    this.submitted = false;
    this.spinnerService.show();

    this.recaptcha.execute().then(
      () => this.spinnerService.hide(),
      () => this.spinnerService.hide()
    );
  }
 
  public submit(): void {
   
    if(this.forgotPasswordForm.invalid){ 
      
      this.submitted = true;
      return;
     
    }
    this.submitted = false;
      const isValidEmail = this.emailPattern.test(this.forgotPasswordForm.controls['username'].value);
      const isValidPhone = this.phonePattern.test(this.forgotPasswordForm.controls['username'].value);
     
      

      if(isValidEmail){
        const aux = JSON.stringify(this.forgotPasswordForm.controls['username'].value)
        sessionStorage.setItem("usernamePR", aux)
        this.sithecApiService
      .resetPassword(this.forgotPasswordForm.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (respond) => {
          const data =respond;
          const info = JSON.stringify(data)
          this.phoneNumber = this.forgotPasswordForm.controls['username'].value
          this.showCode = true
          sessionStorage.setItem("resetPassword",info)
          
        },
        (errorDefinition) => {
          this.handleError(errorDefinition);
        }
      );
      }
      else if(isValidPhone){
        let body = {
          phone: this.forgotPasswordForm.controls['username'].value
        }
      this.sithecApiService.validateUser(body)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe( (response) =>{
        const aux = JSON.stringify(this.forgotPasswordForm.controls['username'].value)
        sessionStorage.setItem("usernamePR", aux)
          this.phoneNumber = "+52"+this.forgotPasswordForm.controls['username'].value
              this.showCode = true
              if (!this.forgotPasswordForm.invalid) {
                this.setUpRecaptcha();
                this.sendLoginCode();
              }
          },
          (error) =>{
            //console.log()
          }
      );
        
    
      }
    
    
  }

  setUpRecaptcha() {
    try {
      this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-v2', {
        'size': 'invisible',
        'callback': (response: any) => {
          //console.log("reCAPTCHA solved, proceed with phone number verification");
        }
      }, this.auth);

      this.recaptchaVerifier.render().then((widgetId) => {
        //console.log('reCAPTCHA rendered with widget ID:', widgetId);
      });
    } catch (error) {
      console.error("Error setting up reCAPTCHA:", error);
    }
  }

  sendLoginCode() {
    if(this.isEmailOrPhone(this.forgotPasswordForm.controls['username'].value)){
      if (!this.auth) {
        this.toastrService.showError("Authentication service is not initialized");
        return;
      }
      else{
      signInWithPhoneNumber(this.auth, this.phoneNumber, this.recaptchaVerifier)
        .then(confirmationResult => {
          //console.log(confirmationResult)
          this.confirmationResult = confirmationResult;
        })
        .catch(error => {
          //console.log(error)
          this.toastrService.showError("Hubo un error");
        });}
    }
    else{
      this.sithecApiService
      .resetPassword(this.forgotPasswordForm.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (respond) => {
          const data =respond;
          const info = JSON.stringify(data)
          this.phoneNumber = this.forgotPasswordForm.controls['username'].value
          this.showCode = true
          sessionStorage.setItem("resetPassword",info)
          
        },
        (errorDefinition) => {
          this.handleError(errorDefinition);
        }
      );
    }
  }

  verifyLoginCode() {
    
    if(this.isEmailOrPhone(this.forgotPasswordForm.controls['username'].value)){
      
      if (!this.confirmationResult) {
        //console.log('No confirmationResult available');
        return;
      }

      this.confirmationResult.confirm(this.verifyForm.get('code')?.value)
        .then(userCredential => {
          //console.log(userCredential);
          
          this.sithecApiService
          .resetPassword(this.forgotPasswordForm.value)
          .pipe(finalize(() => this.spinnerService.hide()))
          .subscribe(
            (respond) => {
              const data =respond;
              const userAux:any = userCredential
              let data2 = "";
              if(userAux._tokenResponse.idToken){
              data2 = userAux._tokenResponse.idToken;}
              const info = JSON.stringify(data)
              const info2 = JSON.stringify(data2)
              sessionStorage.setItem("resetPassword",info)
              sessionStorage.setItem("resetPhonePassword",info2)
              this.router.navigate(["account/reset-password"]);
              
            },
            (errorDefinition) => {
              this.handleError(errorDefinition);
            }
          );
        })
        .catch(error => {
          //console.log(error);
          this.toastrService.showError(error.message);
        });
    }
    else{
      const data = JSON.stringify(this.verifyForm.get('code')?.value)
      sessionStorage.setItem("resetPhonePassword",data)
      this.router.navigate(["account/reset-password"]);
    }
  }


  public handleError({ message }: IErrorDefinition): void {
    this.toastrService.showError(message)
  }

  public redirectToLogin(): void {
    this.router.navigate(["account/authentication"]);
  }
  public redirectToResetPassword(): void {
    this.router.navigate(["account/reset-password"]);
  }
  emailOrPhoneValidator(): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
  
      const isValidEmail = this.emailPattern.test(value);
      const isValidPhone = this.phonePattern.test(value);
  
      if (isValidEmail || isValidPhone) {
        return null;  // Es válido
      } else {
        return { emailOrPhone: true };  // No es válido
      }
    };
  }
  setVerification(value:string){
    this.showRequest = true;
    if(value === "phone"){
      this.placeholder = "AUTH.USER_PHONE_PLACEHOLDER2"
      this.label = "AUTH.ENTER_YOUR_PHONE"
    }
    else if(value === "mail"){
      this.placeholder = "AUTH.USER_EMAIL_PLACEHOLDER"
      this.label = "AUTH.ENTER_YOUR_EMAIL"
    }
  }
  submitCode(){
    
    if(this.verifyForm.invalid){
      this.submitted = true;
      return;
    }

    this.submitted = false;
    
    this.verifyLoginCode()
  }
  isEmailOrPhone(value: string): boolean {
    let decition: boolean = false;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{10}$/;
  
    const isValidEmail = emailPattern.test(value);
    const isValidPhone = phonePattern.test(value);
    
    if(isValidEmail){
      decition = false;
    }
    else if(isValidPhone){
      decition = true;
    }
    return decition;
  }
}
