import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ShoppingCarServiceService } from "./services/shopping-car-service.service";
import { DataDogService } from "./services/datadog.service";
import { PlatformUtil } from "./models/platformUtil";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent {
  title = "e-commerce-web";

  ngOnInit() {}

  constructor(
    private translate: TranslateService,
    private cartService: ShoppingCarServiceService,
    private dd: DataDogService,
    public platformUtil: PlatformUtil
  ) {
    this.translate.setDefaultLang("es");
    this.translate.use("es");
    this.dd.initScriptDataDog();
  }

  logout() {
    this.cartService.resetCart();
  }
}
