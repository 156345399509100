import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SithecBaseApiService } from "../../../services/sithec-base-api.service";
import { SithecCommonApiService } from "../../../services/sithec-common-api.service";
import { finalize } from "rxjs/operators";
import { SessionService } from "../../../services/session.service";
import { SpinnerService } from "../../../services/spinner.service";

@Component({
  selector: "logout",
  templateUrl: "logout.component.html",
})
export class LogoutComponent {
  //TODO: Move it to the HTML or keep it based on the new design.
  @Input("class") className: string = "button primary";
  @Output() onLogout: EventEmitter<any> = new EventEmitter();

  constructor(
    private sithecApiService: SithecCommonApiService,
    private sithecApiBaseService: SithecBaseApiService,
    private spinnerService: SpinnerService,
    private router: Router,
    private sessionService: SessionService
  ) {}

  click() {
    this.spinnerService.show();
    this.sithecApiService
      .logout()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(() => {
        this.sessionService.clearSession();
        this.onLogout.emit();
        this.navigateToLogin();
      });
    this.sessionService.clearSession();
    this.sithecApiBaseService.handleLogoutResponse();
    this.spinnerService.hide();
    this.navigateToLogin();
  }

  private navigateToLogin(): void {
    this.router.navigate(["/account/authentication"]);
  }
}
