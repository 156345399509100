<div class="fondo">
    <div class="arriba">
        <div class="contenedor-agradecimiento"> 
            <div class="logo">
                <img src="../../../../../assets/images/siboth_inicio.png" alt="">
            </div>
            <div class="texto">
                <p class="titulo">Gracias por</p>
                <p class="titulo">Comprar en Sithec</p>
                <p class="sub-titulo">Tu pedido ha sido confirmado</p>
                <p class="texto-base">Hemos enviado un correo electrónico con la información de tu compra.</p>
                <div class="centrado">
                    <app-button
                        class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                        [label]="'CART.SEE_MORE' | translate"
                        type="submit"
                        (onClick)="redirigir()"
                    ></app-button> 
                </div>
            </div> 
        </div>
        <div class="contenedor-imagen">
            <img src="../../../../../assets/images/box_buy.svg" alt="">
        </div>
    </div>
    <div class="abajo">
        <img src="../../../../../assets/images/logo sithec_white.png" alt="">
        <img class="imagen-aguste" src="../../../../../assets/images/contpaq_logo_.png" alt="">
    </div>
    <div class="vista-responsive">
        <div class="logos">
            <img class="logo1" src="../../../../../assets/images/ilustracion_confirmacion compra.png" alt="">
        </div>
        <div class="textos">
            <p class="titulo">Gracias por comprar en Sithec</p>
            <p class="sub-titulo">Tu pedido ha sido confirmado</p>
            <p class="texto-base">Hemos enviado un <span class="resaltado">correo electrónico</span> con la información de tu compra.</p>
        </div>
        <div class="marcas">
            <img class="marca1" src="../../../../../assets/images/Copia de contpaq.png" alt="">
            <img class="marca2" src="../../../../../assets/images/Copia de sithec.png" alt="">
        </div>
        <div class="centrado">
            <app-button
                class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                [label]="'CART.SEE_MORE' | translate"
                type="submit"
                (onClick)="redirigir()"
            ></app-button> 
        </div>
        <div class="centrado">
            <app-button
                class="full-width app-button-rounded app-button-secondary app-button-secondary-green hover-button ajuste"
                [label]="'CART.GO_SHOPPING' | translate"
                type="submit"
                (onClick)="redirigirShopping()"
            ></app-button> 
        </div>
    </div>
</div>
