import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-radio-button', 
  templateUrl: './form-radio-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioButtonComponent),
      multi: true,
    },
  ],
  styleUrls: ['./form-radio-button.component.less'],
})
export class FormRadioButtonComponent implements ControlValueAccessor {
  value: any = null;
  @Input() name: string = 'default-radio-button';
  @Input() label = '';
  @Input('class') className = '';
  @Input() id = '';

  public onChange(val: any): void {}
  public onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(val: any): void {
    this.value = val.target.value;
    this.onChange(val.target.value);
    this.onTouched();
  }
}