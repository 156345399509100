<div [ngClass]="['form-input', className]">
  <label *ngIf="!!label" class="form-label">{{ label | translate }}</label>
  <div class="input-group" >
    <input
      [value]="value"
      [name]="name"
      [type]="fieldTextType ? 'text' : 'password'"
      placeholder="{{ placeholder | translate }}"
      (input)="change($event)"
      class="form-control"
      [ngClass]="errorClass"
    />
    <div class="input-group-append">
      <span
        class="input-group-text"
        (click)="toggleFieldTextType()"
        [ngClass]="errorClass"
      >
        <span class="material-icons">
          {{ getIconClass() }}
        </span>
      </span>
    </div>
  </div>
  <app-form-errors
    *ngIf="hasErrorInput()"
    [control]="control"
    [submitted]="submitted"
  ></app-form-errors>
</div>
