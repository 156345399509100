import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  private datadogScript!: HTMLScriptElement;
  private datadogRum: any;
  private loaded = false;
  private readonly renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject('dataDogApplication') protected dataDogApplication: string,
    @Inject('dataDogClient') protected dataDogClient: string,
    @Inject('datadogEnv') protected datadogEnv: string,
    @Inject('datadogService') protected datadogService: string,
    @Inject('datadogRecording') protected datadogRecording: string,
    private readonly _renderer: RendererFactory2,
    private router:Router
  ) {
    this.renderer = _renderer.createRenderer(null, null);
  }

  config: any = {
    applicationId: this.dataDogApplication,
    clientToken: this.dataDogClient,
    site: 'us5.datadoghq.com',
    service: this.datadogService,
    env: this.datadogEnv,
    version: '5',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true
  };

  initScriptDataDog() {
    if (!this.loaded) {
      this.datadogScript = this.renderer.createElement('script');
      this.datadogScript.type = 'text/javascript';
      this.datadogScript.async = true;
      this.datadogScript.src =
        'https://www.datadoghq-browser-agent.com/datadog-rum-v5.js';

     
      this.datadogScript.onload = () => {
        this.loaded = true;
        this.datadogRum = (window as any)['DD_RUM'];
        this.datadogRum.init(this.config);
        //console.log(this.datadogRum)
        if (!!this.datadogRecording) {
          this.startSessionRecording();
        }

        
      };
      this.renderer.appendChild(this._document.body, this.datadogScript);
    }

  }

  startSessionRecording(){
    //console.log("start recording")
    this.datadogRum?.startSessionReplayRecording?.();
  }

  stopSessionRecording(){
    //console.log("stop recording")
    this.datadogRum?.stopSessionReplayRecording?.();
  }

  addError(
    error: {
      source?: string;
      message?: string;
      stack?: string;
      type: string;
    },
    context = {}
  ) {
    //console.log("send error")
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.addError(
        {
          source: error.source || '',
          message: (error.message || ''),
          stack: error.stack || '',
          type: error.type || ''
        },
        context
      );
    } catch (error) {
      
    }
  }

  setUserSession(id: string, email: string, name: string) {
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.setUser({
        id,
        name,
        email
      });
    } catch (error) {
      
    }
  }

  removeUserSession() {
    try {
      if (!this.datadogRum) {
        return;
      }

      this.datadogRum.removeUser();
    } catch (error) {
      
    }
  }

  addAction({
    action,
    description
  }: {
    action: string;
    description: string;
  }) {
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.addAction(action, {
        page: this.router.url,
        description: description
      });
    } catch (error) {
      
    }
  }

}
