import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { SpinnerService } from "../../../../services/spinner.service";
import { PricesService, PriceData } from "../../../../services/prices.service";
import { ToastrMessageService } from "../../../../services/message.service";
import Swal from 'sweetalert2';
import {
  ShoppingCarServiceService,
  BandType,
} from "../../../../services/shopping-car-service.service";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { ICardInformationConfiguration } from "../../../../models/product.model";
import { FormControl } from "@angular/forms";
import { IErrorDefinition } from "../../../../models/error-definition.model";
import { AdminApiServiceService } from "src/app/services/admin-api-service.service";
import { PlatformUtil } from "src/app/models/platformUtil";
@Component({
  selector: "app-cart-items",
  templateUrl: "./cart-items.component.html",
  styleUrls: ["./cart-items.component.less"],
})
export class CartItemsComponent implements OnInit {
  @Output() onNextStep: EventEmitter<any> = new EventEmitter();

  @Input() confirmAction: boolean = false;

  products: any[] = [];
  combo: any[] = [];
  priceDataArray: PriceData[] = [];
  emptyCartBool: boolean = false;
  emptyComboBool: boolean = false;
  select = new FormControl(null, []);
  mostrar: boolean = false;
  mostrarCombo: boolean = false;
  mostrarDetalle: boolean = false;
  identificador: string|any = "";
  name: string|any = "";
  cantidadLicencias: number|any = 0;
  cantidadUsuarios: number|any = 0;
  unico: boolean = false;
  confirmActionChild: boolean = false;
  seleccionado?: boolean;
  seleccionable?:boolean;
  opcion1?: string;
  opcion2?: string;
  licencia?:string;
  isMobileView:boolean = false;
  productModal:ICardInformationConfiguration = {
    id: '',
    headerImage: '',
    name: '',
    reference: '',
    textInformation: '',
    duration: '',
    footerText: '',
    primaryColorFrom: '',
    primaryColorTo: '',
    costMonoRFC: 0,
    costMonoRFCUA: 0,
    costMultiRFC: 0,
    costMultiRFCUA: 0,
    tipoLicencia: 0,
    selectedCost: 0,
    usersQuantity: 0,
    identificador: '',
    isMono: false,
    isMulti: false,
    isUnique: false,
    purchaseType: '',
    isInCombo: false,
    License: '',
    productType: '',
    price: 0,
    description: '',
    quantity: '',
    lapse: '',
    productCategory: undefined,
    learnMore: '',
    imei: '',
    email: '',
    comboEnable: false,
  }

  constructor(
    private spinnerService: SpinnerService,
    private apiService:AdminApiServiceService, 
    private localStorageService: ShoppingCarServiceService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private priceService: PricesService,
    private message:ToastrMessageService,
    public platformUtil: PlatformUtil
  ) {}

  ngOnChanges(): void {
    if (this.confirmAction) {
      this.confirmActionChild = true;
    }else{
      this.confirmActionChild = false;
    }
  }

  abrir(producto:ICardInformationConfiguration){
    this.productModal = producto;
    this.mostrar= true;

    if(producto.isMulti){
      this.licencia="Multi-RFC"
      this.opcion1 = "1";
      this.opcion2 = "2";
    }
    if(producto.isMono){
      this.licencia="Mono-RFC"
      this.opcion1 = "2";
      this.opcion2 = "1";
    }
  }
  cerrar(){
    this.mostrar= false;
  }
  abrirCombo(){
    this.mostrarCombo=true;
  }
  cerrarCombo(){
    this.mostrarCombo=false;
  }
  ngOnInit(): void {
    this.initFlow();
    this.checkViewport();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    if(this.platformUtil.isBrowser())
      this.isMobileView = window.innerWidth <= 768;
  }
  cambiarTipo(valor:any){
    //this.seleccionado=valor;
    if(this.opcion1=="1"){
      this.opcion1="2";
      this.opcion2="1";
    }else{
      this.opcion1="1";
      this.opcion2="2";
    }
    let aux = this.products[0].filter((product: ICardInformationConfiguration) => product.identificador == this.identificador);
    this.localStorageService.changeCost(this.products[0],  aux[0]);
    this.fnRefresh();
  }
  calcularSubtotal(producto:ICardInformationConfiguration){
    if(producto.tipoLicencia==0){
      return (producto.selectedCost!+(producto.usersQuantity!-1)*producto.costMonoRFCUA!);
    } else{
      return (producto.selectedCost!+(producto.usersQuantity!-1)*producto.costMultiRFCUA!);
    }
  }
  calcularCosto(producto:ICardInformationConfiguration, licencias:number){
    if(producto.tipoLicencia==0){
      return (producto.selectedCost!+(producto.usersQuantity!-1)*producto.costMonoRFCUA!)*(licencias);
    } else{
      return (producto.selectedCost!+(producto.usersQuantity!-1)*producto.costMultiRFCUA!)*(licencias);
    }
  }
  calcularTotalCombo(){
    let total:number = 0;
    this.combo[0].forEach((product:ICardInformationConfiguration) => {
      total+=product.selectedCost || 0
      if(product.isMulti){
        total+=((product.usersQuantity || 0) -1) * (product.costMultiRFCUA || 0)
      }
      if(product.isMono){
        total+=((product.usersQuantity || 0) -1) * (product.costMonoRFCUA || 0)
      }
    });
    return total
  }

  eliminarCombo(){
    this.combo=[[]]
    this.emptyComboBool=true
    this.updateLS(this.products[0]);
    localStorage.removeItem("empresa")
  }

  tipoLicencia(isMono:boolean, isMulti:boolean): string{
    if(isMono){
      return "Mono"
    }
    if(isMulti){
      return "Multi"
    }
    return "";
  }

  fnGetProductsAvailable() {
    let info:any[] = []
    this.localStorageService.getCarAsObservable().subscribe((item) => {
      info.push(item);
    });
    //console.log(info)
    this.combo = [[]]
    this.products = [[]]
    info[0].forEach((product:ICardInformationConfiguration) => {
      if(product.isInCombo){
        this.combo[0].push(product);
      }else{
        this.products[0].push(product);
      }
    });
    //console.log(this.combo)
    //console.log("this.products",this.products)
    this.products.forEach((ren: any[], i) => {
      ren?.forEach((col: any,j) =>{
        let name = col?.headerImage?.split("/")?.reverse()[0]
        if(name){
          name = name?.split("?")[0]
          this.getImage(name,i,j)
        }
      })
    })
    this.combo.forEach((ren: any[], i) => {
      ren?.forEach((col: any,j) =>{
        let name = col?.headerImage?.split("/")?.reverse()[0]
        if(name){
          name = name?.split("?")[0]
          this.getComboImage(name,i,j)
        }
      })
    })
    this.spinnerService.hide();
  }
  getComboImage(name: string,i: number,j:number){
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if(response){
            //Verificar que existe imagen
            this.combo[i][j].headerImage = response.result;
          } 
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
  getImage(name: string,i: number,j:number){
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if(response){
            //Verificar que existe imagen
            this.products[i][j].headerImage = response.result;
          } 
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }

  cambiarSeleccion(valor: any, identificador:string){
    let aux = this.products[0].filter((product: any) => product.identificador == identificador);
    this.localStorageService.changeCost(this.products[0],  aux[0]); 
    this.fnRefresh();
  }

  addQuantity(identificador: string, nombre:string, purchaseType:string, bandCol: BandType) {
    if(purchaseType=="Cambio de Características" || purchaseType=="Renovación"){
      if(this.platformUtil.isBrowser())
        this.message.showInfo('Para cambiar características de una renovación o cambio de características debes de hacerlo desde la selección de producto');
    }
    else{
      if (bandCol == BandType.Init) {
        let aux = this.products[0].filter((product: any) => product.identificador == identificador);
        this.localStorageService.checkHowToSumValue(
          this.products[0],
          aux[0],
          BandType.Quantity
        );
        this.fnRefresh();
      } else {
        if(nombre != "XML en Línea+" && nombre != "CFDI Facturación en Línea+"){
          let aux = this.products[0].filter((product: any) => product.identificador == identificador);
            this.localStorageService.checkHowToSumValue(
            this.products[0],
            aux[0],
            BandType.Users
          )
          this.fnRefresh();
        }else{
          let aux = this.products[0].filter((product: any) => product.identificador == identificador);
            this.localStorageService.checkHowToSumValue(
            this.products[0],
            aux[0],
            BandType.Unique
          )
        }
      }
      switch(bandCol){
        case 0:
          this.cantidadLicencias++;
          break;
        case 1:
          if(nombre != "XML en Línea+" && nombre != "CFDI Facturación en Línea+"){
            this.cantidadUsuarios++;
          }else{
            if(this.platformUtil.isBrowser())
              this.message.showInfo('No se permite añadir más usuarios a este producto');
          }
          break;  
      }
    }
  }

  isModifiable(purchaseType:string){
    if(purchaseType=="Cambio de Características" || purchaseType=="Renovación"){
      return false
    }
    return true
  }

  removeQuantity(identificador: string, purchaseType:string, bandCol: BandType) {
    if(purchaseType=="Cambio de Características" || purchaseType=="Renovación"){
      this.message.showInfo('Para cambiar características de una renovación o cambio de características debes de hacerlo desde la selección de producto');
    }
    else{
      if (bandCol == BandType.Init) {
        let aux = this.products[0].filter((product: any) => product.identificador == identificador);
        this.localStorageService.substractQuantity(
          this.products[0],
          aux[0],
          BandType.Quantity
        );
        this.fnRefresh();
      } else {
        let aux = this.products[0].filter((product: any) => product.identificador == identificador);
        this.localStorageService.substractQuantity(
          this.products[0],
          aux[0],
          BandType.Users
        );
        this.fnRefresh();
      }
      switch(bandCol){
        case 0:
          if(this.cantidadLicencias>1){
            this.cantidadLicencias--;
          }
          break;
        case 1:
          if(this.cantidadUsuarios>1){
            this.cantidadUsuarios--;
          }
          break;  
      }
    }
  }

  removeItem(identificador: string) {
    let completeProducts = []
    this.products[0] = this.products[0].filter(
      (product: any) => product.identificador != identificador
    );
    completeProducts = this.products[0].concat(this.combo[0]);
    //this.updateLS(this.products[0]);
    this.updateLS(completeProducts);
  }
  removeItemCombo(identificador: string) {
    let completeProducts = []
    this.combo[0] = this.combo[0].filter(
      (combo: any) => combo.identificador != identificador
    );
    completeProducts = this.products[0].concat(this.combo[0]);
    //this.updateLS(this.products[0]);
    this.updateLS(completeProducts);
  }
  updateLS(obj: any[]) {
    this.localStorageService.updateItemsLocalStorage(obj);
    this.fnRefresh();
  }

  async confirm(id: string) {
    await Swal.fire({
      title: 'Eliminar',
      text: "¿Deseas eliminar este producto de tu carrito?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        /* Swal.fire(
          'Eliminado',
          'Producto eliminado',
          'success'
        )
        this.removeItem(id);
        setTimeout(this.recargar, 1000); */
      } else{
        Swal.fire(
          'Eliminado',
          'Producto eliminado',
          'success'
        )
        this.removeItem(id);
        setTimeout(this.recargar, 1000);
        /* Swal.fire(
          'Operación cancelada',
          'Los cambios no se verán reflejados',
          'success'
        ) */
      }
    })
    
    /* this.confirmationService.confirm({
      message: "Deseas eliminar este producto de tu carrito?",
      header: "Eliminar",
      icon: "pi pi-info-circle",
      accept: () => {
        this.removeItem(id);
        this.messageService.add({
          severity: "info",
          summary: "Confirmar",
          detail: "Eliminado del carrito",
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: "error",
              summary: "Cancelar",
              detail: "Cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: "warn",
              summary: "",
              detail: "Cancelado",
            });
            break;
        }
      },
      
    }); */
  }
  async confirmCombo(id: string) {
    await Swal.fire({
      title: 'Eliminar',
      text: "¿Deseas eliminar este producto de tu carrito?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        /* Swal.fire(
          'Eliminado',
          'Producto eliminado',
          'success'
        )
        this.removeItem(id);
        setTimeout(this.recargar, 1000); */
      } else{
        Swal.fire(
          'Eliminado',
          'Producto eliminado',
          'success'
        )
        this.removeItemCombo(id);
        setTimeout(this.recargar, 1000);
        /* Swal.fire(
          'Operación cancelada',
          'Los cambios no se verán reflejados',
          'success'
        ) */
      }
    })
  }
  async confirmDeleteCombo() {
    await Swal.fire({
      title: 'Eliminar',
      text: "¿Deseas eliminar el combo de tu carrito?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {

      } else{
        Swal.fire(
          'Eliminado',
          'Combo eliminado',
          'success'
        )
        this.eliminarCombo()
        setTimeout(this.recargar, 1000);
      }
    })
  }
  
  recargar(){
    window.location.reload()
  }

  checkIsEmpty() {
    this.products[0].length == 0 && this.combo[0].length == 0
      ? (this.emptyCartBool = true)
      : (this.emptyCartBool = false);
  }

  checkComboIsEmpty() {
    this.combo[0].length == 0
      ? (this.emptyComboBool = true)
      : (this.emptyComboBool = false);
  }

  retornarLicencia(tipoLicencia:number,licenciaP:string){
    let licencia:string = "";
    switch(tipoLicencia){
      case 1:
        licencia = "Nueva Licencia"
        break;
      case 2:
        licencia = "Renovación de licencia"
        break;
      case 3:
        licencia = "Cambio de Licencia"
        break;
    }
    licenciaP = licencia;
    return licencia;
  }

  abrirModal(producto:ICardInformationConfiguration){
    this.mostrarDetalle = true;
    this.productModal = producto;
    //console.log("modal", this.productModal)
  }
  cerrarModal(){
    this.mostrarDetalle = false;
    
  }
  fnConvertToInterface(arrayProducts: any[]): PriceData[] {    
    let arrPriceData: PriceData[] = [];

    arrayProducts.forEach((objProduct: any) => {
      let aux: PriceData = {
        _idProduct: objProduct.id,
        _quantity: objProduct.quantity,
        _users: objProduct.usersQuantity,
        _cost: objProduct.selectedCost,
        _aditionalCost: objProduct.isMono ? objProduct.costMonoRFCUA : objProduct.costMultiRFCUA,
        _discountable: !(objProduct.name == "CFDI Facturación en Línea+"),
        _isInCombo: objProduct.isInCombo
      };

      arrPriceData.push(aux);
    });

    return arrPriceData;
  }

  fnRefresh() {
    let completeProducts = []
    completeProducts = this.products[0].concat(this.combo[0]);

    let priceDataArray: PriceData[] = this.fnConvertToInterface(
      completeProducts
    );
    
    this.priceService.updatePrice(priceDataArray);
    this.checkIsEmpty();
  }

  nextStep() {
    this.onNextStep.emit();
  }

  initFlow() {
    this.spinnerService.show();
    this.fnGetProductsAvailable();
    let completeProducts = []
    completeProducts = this.products[0].concat(this.combo[0]);
    this.priceDataArray = this.fnConvertToInterface(completeProducts);
    this.priceService.updatePrice(this.priceDataArray);
    this.checkIsEmpty();
    this.checkComboIsEmpty()
  }

  toSpanish(lapse: string = ''){
    switch(lapse){
      case 'Day':
        return 'Diaria';
      case 'Month':
        return 'Mensual';
      case 'Year':
        return 'Anual';
      case 'Permanent':
        return 'Permanente';
    }
    return ''
  }
  onRadioChange(value: string): void {
    if (value === 'mono') {
      this.productModal.isMono = true;
      this.productModal.isMulti = false;
    } else if (value === 'multi') {
      this.productModal.isMono = false;
      this.productModal.isMulti = true;
    }
  }
}
