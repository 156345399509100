import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.less'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormErrorsComponent {
  @Input() control: any = [];
  @Input() submitted: boolean = false;

  constructor() {}

  getErrors() {
    ////console.log(this.control?.errors ? Object.keys(this.control.errors) : [])
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }
}