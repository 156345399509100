<div [ngClass]="['form-input', className]">
  <label *ngIf="!!label" class="form-label">{{label | translate}}</label>

  <ng-container *ngIf="type === 'ccNumber'">
    <div class="input-group">
      <input
        type="tel"
        [value]="value" 
        [name]="name"
        placeholder="{{ placeholder | translate }}"
        (input)="change($event)"
        [ngClass]="errorClass"
        ccNumber
        #ccNumber="ccNumber"
        />
      <div class="input-group-append">
          <span class="input-group-text text-muted cards-icon">
            <i class="fab fa-cc-{{ccNumber.resolvedScheme$ | async}} mx-auto"></i>          
          </span>
      </div>
  </div>
    
  </ng-container>

  <ng-container *ngIf="type === 'ccExp'">
    <input
    type="tel"
    [value]="value"
    [name]="name"
    placeholder="MM / YY"
    (input)="change($event)"
    [ngClass]="errorClass"
    autocomplete="cc-exp"
    ccExp
    />
  </ng-container>

  <ng-container *ngIf="type === 'ccCVC'">
    <input
    type="tel"
    [value]="value"
    [name]="name"
    placeholder="{{ placeholder | translate }}"
    (input)="change($event)"
    [ngClass]="errorClass"
    autocomplete="off"
    ccCVC
    />
  </ng-container>

  <app-form-errors
    *ngIf="hasErrorInput()"
    [control]="control"
    [submitted]="submitted"
  ></app-form-errors>

</div>
