<div class="contenido">
    <div  class="recuadro">
        <h1>TÉRMINOS Y CONDICIONES DE SITHEC (HECTOR DANIEL HOLGUIN LOPEZ)</h1>
        <p>Al utilizar nuestro sitio web, usted acepta cumplir con estos términos y condiciones. Lea atentamente estos términos antes de utilizar nuestro sitio web. </p>
        <h2>USO DEL SITIO WEB</h2>
        <p>Nuestro sitio web solo se puede utilizar para fines legales y de buena fe. No se permite utilizar nuestro sitio web para actividades fraudulentas o ilegales.</p>
        <h2>PROPIEDAD INTELECTUAL</h2>
        <p>Todos los derechos de propiedad intelectual relacionados con nuestro sitio web, incluyendo, pero no limitado a marcas comerciales, nombres comerciales, logotipos y contenido, son propiedad exclusiva de SITHEC (HECTOR DANIEL HOLGUIN LOPEZ) o sus licenciantes.</p>
        <h2>COMPRA DE PRODUCTOS</h2>
        <p>Al realizar un pedido en nuestro sitio web, acepta los términos y condiciones de la venta, incluidos los precios, los gastos de envío y las políticas de devolución. Nos reservamos el derecho de cancelar o rechazar cualquier pedido por cualquier motivo. </p>
        <h2>ENVÍO Y ENTREGA</h2>
        <p>Nos esforzamos por enviar sus productos lo antes posible. Sin embargo, no garantizamos tiempos de entrega específicos y no somos responsables de los retrasos causados por eventos fuera de nuestro control. </p>
        <h2>DEVOLUCIONES Y REEMBOLSOS </h2>
        <p>Aceptamos devoluciones dentro de los 2 días hábiles siguientes a la recepción del producto. Los productos devueltos deben estar en su estado original y sin usar. Proporcionaremos un reembolso completo o un intercambio por un producto diferente, según su elección. </p>
        <h2>LIMITACIÓN DE RESPONSABILIDAD </h2>
        <p>No somos responsables de ningún daño directo, indirecto, incidental, especial o consecuente que surja de su uso de nuestro sitio web o de los productos que compre en nuestro sitio web. </p>
        <h2>CAMBIOS A ESTOS TÉRMINOS Y CONDICIONES </h2>
        <p>Podemos actualizar estos términos y condiciones en cualquier momento sin previo aviso. Al continuar utilizando nuestro sitio web después de cualquier cambio en estos términos y condiciones, acepta los términos y condiciones actualizados. </p>
        <h2>CONTÁCTENOS </h2>
        <p>Si tiene alguna pregunta o inquietud sobre nuestros términos y condiciones, puede ponerse en contacto con nosotros en cualquier momento a través de nuestro equipo de soporte al cliente.</p>
    </div>
</div> 