import { Component, OnInit, NgModule } from '@angular/core';
import { IPurchaseData, IPurchaseItem } from '../../models/purchase.model';
import { ECommerceApiService } from '../../services/e-commerce-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { ToastrMessageService } from '../../services/message.service';
import { finalize } from "rxjs/operators";
import { IErrorDefinition } from '../../models/error-definition.model';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { PurchaseInformationService } from '../../services/purchase-information.service';
import { IFileRequest } from '../../models/file-request';
import { SithecCommonApiService } from '../../services/sithec-common-api.service';

@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.less']
})
export class ComprasComponent implements OnInit {

  mostrar: boolean = false;
  purchasesData:IPurchaseData[]=[];
  purchaseDetail:IPurchaseItem[]=[];
  purchase!:IPurchaseData
  purchaseModal:IPurchaseData = {
    details: [],
    date: "",
    discount: 0,
    iva: 0,
    total: 0,
    card: "",
    id: "",
    certificado: false,
    facturadoPDF: false,
    facturadoXML: false,
    user:{
      id: "",
      name: "",
      lastName: "",
      username: "",
      rfc: "",
      phone: "",
      razon: "",
      certificado: false,
      calle: "",
      numero_exterior: "",
      numero_interior: "",
      estado: "",
      regimen: "",
      cp: "",
      colonia: "",
      municipio: ""
    },
    userId: "",
    toMonths: false,
    months: 0,
    commission: 0,
    bank: "",
    folio: 0,
  }

  monthly: boolean = false;
  months: number = 0;
  comission: number = 0;
  bank: string = ""
  fecha: String = "";
  subtotal: number = 0;
  descuento: number = 0;
  iva: number = 0;
  total: number = 0;
  card: String= "";
  busqueda="";
  facturadoPDF: boolean = false;
  facturadoXML: boolean = false;

  constructor(private apiService:ECommerceApiService, private spinnerService:SpinnerService, private toastrService:ToastrMessageService, private userService:UserService, private router:Router, private purchaseService:PurchaseInformationService, private sithecCommonApiService:SithecCommonApiService) {
    
   }

  ngOnInit(): void {
    this.spinnerService.show()
    this.apiService
        .getPurchase(this.userService.user.id)
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(
            (purchases: any) => {
              this.purchasesData=purchases.results;
              //console.log("Datos de la base de datos: ")
              //console.log(this.purchasesData)   
            },
            ({ message }: IErrorDefinition) => this.toastrService.showError(message)
          ); 
          const modalOpen = localStorage.getItem('modalOpen');
        
    if (modalOpen === 'true') {
      this.mostrar = true;

      localStorage.removeItem('modalOpen');
    }
    if(this.mostrar){
      const purchaseModalString = localStorage.getItem('purchaseModal');
      if(purchaseModalString !== null){
      this.purchaseModal = JSON.parse(purchaseModalString);
      this.abrirModal(this.purchaseModal);
      this.facturadoPDF = this.purchaseModal.facturadoPDF;
      this.facturadoXML = this.purchaseModal.facturadoXML;
      localStorage.removeItem('purchaseModal');
      }
      
    }
  }

  getLicenciamiento(type:boolean){
    return type? "Mono-empresa":"Multi-empresa"
  }

  checkCombo(list:IPurchaseItem[]){
    var thereIsCombo=false;
    list.forEach(product =>{
      if(product.isInCombo){
        thereIsCombo=true;
      }
    })
    return thereIsCombo;
  }

  checkProducts(list:IPurchaseItem[]){
    var thereIsNoCombo=false;
    list.forEach(product =>{
      if(!product.isInCombo){
        thereIsNoCombo=true;
      }
    })
    return thereIsNoCombo;
  }

  abrirModal(purchase: IPurchaseData){
    this.fecha = purchase.date?.split('T')[0];
    this.subtotal = (purchase.total-purchase.iva)+purchase.discount;
    this.descuento = purchase.discount;
    this.iva = purchase.iva;
    this.total = purchase.total;
    this.purchaseDetail = purchase.details;
    this.card=purchase.card;
    this.facturadoPDF=purchase.facturadoPDF;
    this.facturadoXML=purchase.facturadoXML;
    this.purchase=purchase
    this.monthly=purchase.toMonths
    this.months=purchase.months
    this.comission=purchase.commission
    this.bank=purchase.bank
    this.purchaseModal = purchase;
    this.mostrar= true;
    // //console.log("this.months="+this.months+" months= "+months)
  }
  cerrar(){
    this.mostrar= false;
  }

  trackDetails(index:number, item:IPurchaseItem){
    return item.productId
  }
  trackPurchase(index:number, item:IPurchaseData){
    return item.id;
  }
  redirigir(){
    this.purchaseService.setPurchase(this.purchase)
    const purchaseModalString = JSON.stringify(this.purchase);
      // Luego, guárdalo en el almacenamiento local
      
    localStorage.setItem('purchaseModal', purchaseModalString);
    this.router.navigate(['/recibo']);

  } 
  mostrarLicencias(purchase:IPurchaseData){
    if(purchase.folio)
    localStorage.setItem('folioLicencia',purchase.folio.toString())
    this.router.navigate(['/certificados']);
  }
  async checkFacturadoPDF(){
    if(this.purchaseModal.facturadoPDF){
      this.toastrService.showInfo("Tu documento está siendo cargado")
      await this.getURLFile(this.purchaseModal.id+".pdf", 'facturas')
    }else{
      this.toastrService.showWarn("Este producto aún no ha sido facturado")
    }
  }
  async checkFacturadoXML(){
    if(this.purchaseModal.facturadoXML){
      this.toastrService.showInfo("Tu documento está siendo cargado")
      await this.getURLFile(this.purchaseModal.id+".xml", 'xml')
    }else{
      this.toastrService.showWarn("Este producto aún no ha sido facturado")
    }
  }

  async getURLFile(name:string, subdirectory:string){
    //console.log({name,subdirectory})
    const file: IFileRequest= {
      objectKey: name,
      subDirectory: subdirectory,
      duration: 2
    };
    var url=""
    if(subdirectory=="xml"){
      await this.sithecCommonApiService
      .downloadURLFile(file).subscribe((res) => {
        if(res){
          this.downloadFile(res)
        }
        //window.location.href=url
        // window.open(url, '_blank');
      });
    }else{
      await this.sithecCommonApiService
      .getURLFile(file).subscribe((res) => {
        url=res
        //window.location.href=url
        window.open(url, '_blank');
      });
    }
    
  }

  downloadFile(file: any) {
    let fileContents: string = file.fileContents
    let fileDownloadName: string = file.fileDownloadName
    let contentType: string = file.contentType
    // Decodificar el contenido base64
    const decodedContent = atob(fileContents);
 
    // Convertir el contenido a un array de bytes
    const byteCharacters = new Array(decodedContent.length);
    for (let i = 0; i < decodedContent.length; i++) {
      byteCharacters[i] = decodedContent.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteCharacters);
 
    // Crear un Blob a partir del contenido
    const blob = new Blob([byteArray], { type: contentType });
 
    // Crear una URL para el Blob
    const url = window.URL.createObjectURL(blob);
 
    // Crear un enlace <a> en el DOM y simular un clic en él para iniciar la descarga
    const a = document.createElement('a');
    a.href = url;
    a.download = fileDownloadName;
    document.body.appendChild(a);
    a.click();
 
    // Liberar el objeto URL creado
    window.URL.revokeObjectURL(url);
  }
  getmetadata(meta:any){
    return meta;
  }

  isContpaq(name:string){
    return name.indexOf("CONTPAQi") !== -1;
  }
  checkCard(input: string): string {
    let tipo:string ="";
    const firstChar = input.charAt(0);
    if(firstChar === '4' ){
      tipo = "VISA";
    }
    if(firstChar === '5'){
      tipo = "MASTERCARD";
    }
    return tipo;
}
}
