import { Component, forwardRef, Input, Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-radio-check-button',
  templateUrl: './form-radio-check-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioCheckButtonComponent),
      multi: true,
    },
  ],
  styleUrls: ['./form-radio-check-button.component.less'],
})
export class FormRadioCheckButtonComponent implements ControlValueAccessor {
  value: any = null;
  seleccionado: boolean = false;
  identificador: string = "";
  //formInput: HTMLInputElement = <HTMLInputElement>document.getElementById("1");
  @Output() seleccion = new EventEmitter();
  @Input() name: string = 'default-radio-button';
  @Input() label = '';
  @Input('class') className = '';
  @Input() isChecked: boolean = false;
  @Input()
  public set seleccionActiva(valor:any){
    /* if(valor=="1"){
      if(this.identificador=="1"){
        this.seleccionado = true;
      }else if(this.identificador=="2"){
        this.seleccionado = false;
      }
    }else if(valor=="2"){
      if(this.identificador=="1"){
        this.seleccionado = false;
      }else if(this.identificador=="2"){
        this.seleccionado = true;
      }
    } */
    switch(valor){
      case "1":
        switch(this.identificador){
          case "1":
            //this.formInput =  <HTMLInputElement>document.getElementById("1");
            //this.formInput.checked = true;
            this.seleccionado=true;
            break;
          case "2":
          //  this.formInput =  <HTMLInputElement>document.getElementById("2");
           // this.formInput.checked = false;
            this.seleccionado=false;
            break; 
          case "3":
          //  this.formInput =  <HTMLInputElement>document.getElementById("3");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break;
          case "4":
          //  this.formInput =  <HTMLInputElement>document.getElementById("4");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break;      
        }
        break;
      case "2":
        switch(this.identificador){
          case "1":
          //  this.formInput =  <HTMLInputElement>document.getElementById("1");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break;
          case "2":
          //  this.formInput =  <HTMLInputElement>document.getElementById("2");
          //  this.formInput.checked = true;
            this.seleccionado=true;
            break; 
          case "3":
          //  this.formInput =  <HTMLInputElement>document.getElementById("3");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break;
          case "4":
          //  this.formInput =  <HTMLInputElement>document.getElementById("2");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break;      
        }
        break; 
      case "3":
        switch(this.identificador){
          case "1":
          //  this.formInput =  <HTMLInputElement>document.getElementById("1");
           // this.formInput.checked = false;
          this.seleccionado=false;
            break;
          case "2":
          //  this.formInput =  <HTMLInputElement>document.getElementById("2");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break; 
          case "3":
          //  this.formInput =  <HTMLInputElement>document.getElementById("3");
          //  this.formInput.checked = true;
            this.seleccionado=true;
            break;
          case "4":
          //  this.formInput =  <HTMLInputElement>document.getElementById("4");
          //  this.formInput.checked = false;
            this.seleccionado=false;
            break;      
        }
        break;
      case "4":
        switch(this.identificador){
          case "1":
            this.seleccionado=false;
            break;
          case "2":
            this.seleccionado=false;
            break; 
          case "3":
            this.seleccionado=false;
            break;
          case "4":
            this.seleccionado=true;
            break;      
        }
        break;      
    }
  }
  @Input()
  public set id(valor:any) 
  {
    this.identificador=valor;
    /* if(this.identificador=="1"){
      this.seleccionado = false;
    }else if(this.identificador=="2"){
      this.seleccionado = true;
    } */
    if(this.identificador=="1"){
      this.seleccionado = true;
    }else{
      this.seleccionado = false;
    }
  }


  public onChange(val: any): void {}
  public onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(val: any): void {
    this.value = val.target.value;
    this.onChange(val.target.value);
    this.onTouched();
  }

  cambio(){
    this.seleccion.emit(this.identificador);
    
  }
}