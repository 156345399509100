import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrMessageService } from '../../../services/message.service';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.less']
})
export class ComponentsComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });
  public submitted = false;

  submit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
  }
  constructor(
    private toastrMessageService:ToastrMessageService
  ) { }

  ngOnInit(): void {
  }

  
  showSuccess() {
    this.toastrMessageService.showSuccess();
  }
  showInfo() {
    this.toastrMessageService.showInfo();
  }
  showWarn() {
    this.toastrMessageService.showWarn();
  }
  showError() {
    this.toastrMessageService.showError();
  }

}
