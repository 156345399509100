import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AT_LEAST_ONE_NUMBER, AT_LEAST_ONE_CHARACTER_UPPERCASE, AT_LEAST_ONE_CHARACTER_LOWERCASE, AT_LEAST_ONE_SPECIAL_CHARACTER } from '../../../constants/passwordValidation.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ISwitch } from '../../../models/switch.model';
import { ToastrMessageService } from '../../../services/message.service';
import { SessionService } from '../../../services/session.service';
import { SithecCommonApiService } from '../../../services/sithec-common-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { finalize } from 'rxjs/operators';
import { DataDogService } from '../../../services/datadog.service';

@Component({
  selector: 'lib-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less']
})
export class SignUpComponent  {
  public solicitarDatos = false;
  public personaFisica = true;
  selectRazonSocial = new FormControl(null, []);
  selectRegimenFiscalMoral = new FormControl(null, []);
  selectRegimenFiscalFisica = new FormControl(null, []);
  public submitted = false;
  @Output() showLogin: EventEmitter<any> = new EventEmitter<any>();

  signUpForm: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone:new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      rfc: new FormControl(),
      cp: new FormControl(),
      calle:new FormControl(),
      numero_exterior:new FormControl(),
      numero_interior:new FormControl(),
      colonia:new FormControl(),
      municipio:new FormControl(),
      estado:new FormControl(),
      razon: new FormControl(),
      persona: new FormControl(),
      regimen: new FormControl(),
      email: new FormControl('', [Validators.required, Validators.email]),
      emailConfirm: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(AT_LEAST_ONE_NUMBER),
        Validators.pattern(AT_LEAST_ONE_CHARACTER_UPPERCASE),
        Validators.pattern(AT_LEAST_ONE_CHARACTER_LOWERCASE),
        Validators.pattern(AT_LEAST_ONE_SPECIAL_CHARACTER)
      ]),
      passwordConfirm: new FormControl('', Validators.required),
      cart: new FormControl(false)
    },
    [
      this.passwordMatchValidator,
      this.emailMatchValidator
    ]
  );

  private returnUrl: string | null = null;

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService,
    private sithecCommonApiService:SithecCommonApiService,
    private toastrService:ToastrMessageService,
    private session: SessionService,
    private dd: DataDogService
  ) {

    this.returnUrl = session.getURLSession();
  }

  public ngAfterViewInit(): void {
    this.dd.startSessionRecording()
  }

  public ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dd.stopSessionRecording()
  }

  private passwordMatchValidator(
    formGroup: AbstractControl
  ): ValidationErrors | null {
    const passwordConfirm = formGroup.get('passwordConfirm');

    if (formGroup.get('password')?.value === passwordConfirm?.value) {
      passwordConfirm?.setErrors(null);
      return null;
    }

    passwordConfirm?.setErrors({ mustMatch: true });
    return { invalid: true };
  }

  private emailMatchValidator(
    formGroup: AbstractControl
  ): ValidationErrors | null {
    const emailConfirm = formGroup.get('emailConfirm');

    if (formGroup.get('email')?.value === emailConfirm?.value) {
      emailConfirm?.setErrors(null);
      return null;
    }

    emailConfirm?.setErrors({ mustMatch: true });
    return { invalid: true };
  }

  submit() {
    if(this.signUpForm.invalid){
      this.submitted = true;
      /* if(this.signUpForm.get('code')?.hasError('required') || this.signUpForm.get('password')?.hasError('required') || this.signUpForm.get('passwordConfirm')?.hasError('required')){
        this.toastrService.showError("Hay campos vacíos") 
      }
      else if(this.signUpForm.get('password')?.value != this.signUpForm.get('passwordConfirm')?.value){
        this.toastrService.showError("Las contraseñas no coinciden") 
      }
      else{
        this.toastrService.showError("Verifica que la contraseña contenga al menos una letra en mayúscula, una en minúscula, un número y un carácter")
      } */
      return;
    }

    this.submitted = false;
    //this.spinnerService.show();

    if(localStorage.getItem("cart")){
      this.signUpForm.controls['cart'].setValue(true)
    }else{
      this.signUpForm.controls['cart'].setValue(false)
    }

    var signUpInformation = JSON.stringify(this.signUpForm.value);

    localStorage.setItem("SignUpInformation", signUpInformation)

    //this.redirectToEmailVerification()

    this.sithecCommonApiService
      .validateValues(this.signUpForm.controls['email'].value, this.signUpForm.controls['phone'].value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        () => this.redirectToEmailVerification(),
        (errorDefinition:any) => this.handleError(errorDefinition)
    );
   
  }

  handleError(error: any) { 
    this.toastrService.showError(error.message) 
  }

  private redirectToEmailVerification(): void {
    this.router.navigate(["/sms", "+52"+this.signUpForm.get('phone')?.value]);
    //this.router.navigate(["enviado"]);
  }

  solicitarDatosFactura(seleccion:ISwitch){
    this.solicitarDatos=seleccion.value
    if(this.solicitarDatos){
      /* //console.log("Solicitar") */
      this.signUpForm.controls['persona'].setValue('Persona Física');
      this.signUpForm.controls['regimen'].setValue('Sueldos y Salarios e Ingresos Asimilados a Salarios');
      this.signUpForm.get('rfc')!.setValidators([Validators.required]);
      this.signUpForm.get('cp')!.setValidators([Validators.required]);
      this.signUpForm.get('razon')!.setValidators([Validators.required]);
      this.signUpForm.get('persona')!.setValidators([Validators.required]);

      this.signUpForm.get('rfc')!.updateValueAndValidity();
      this.signUpForm.get('cp')!.updateValueAndValidity();
      this.signUpForm.get('razon')!.updateValueAndValidity();
      this.signUpForm.get('persona')!.setValidators([]);
    }else{
      /* //console.log("No solicitar") */
      this.signUpForm.controls['persona'].setValue('');
      this.signUpForm.controls['regimen'].setValue('');
      this.signUpForm.get('rfc')!.setValidators([]);
      this.signUpForm.get('cp')!.setValidators([]);
      this.signUpForm.get('razon')!.setValidators([]);
      this.signUpForm.get('persona')!.setValidators([]);
      this.signUpForm.get('rfc')!.updateValueAndValidity();
      this.signUpForm.get('cp')!.updateValueAndValidity();
      this.signUpForm.get('razon')!.updateValueAndValidity();
      this.signUpForm.get('razon')!.setValidators([]);
      this.signUpForm.get('persona')!.setValidators([]);
    }
  }

  cambiarSeleccionRazonSocial(e:any){
    /* //console.log(e.target.value); */
    
    if(e.target.value == "Persona Física"){
      this.personaFisica= true;
      this.signUpForm.controls['regimen'].setValue('Sueldos y Salarios e Ingresos Asimilados a Salarios');
    }else{
      this.personaFisica= false;
      this.signUpForm.controls['regimen'].setValue('General de Ley Personas Morales');
    }
    
  }

}
