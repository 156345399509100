<div class="row file-preview">
    <div class="col-6 text-center">
        <img src="data:image/png;base64,{{value}}" class="img-fluid preview" *ngIf="!!value"/>
    </div>
    <div class="col-6 text-center">
        <input class="form-control" type="file" (change)="handleFileSelect($event)" #file hidden>
    <!--     <app-button 
            [text]="'FILES.SELECT'" 
            [class]="'button primary button-full-width mt-4'"
            [leftIcon]="'file_upload'"
            (onClickButton)="selectImage()">
        </app-button> -->
        <label class="text">
        {{ 'FILES.LIMITS' | translate }}
        </label>
        <app-form-errors
            *ngIf="hasErrorInput()"
            [control]="control"
            [submitted]="submitted">
        </app-form-errors>
    </div>
</div>

