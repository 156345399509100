import { Pipe, PipeTransform } from '@angular/core';
import { IPurchaseData, IPurchaseDetail} from '../models/purchase.model';

@Pipe({
  name: 'filterDetail'
})
export class FilterDetailPipe implements PipeTransform {

  transform(items: IPurchaseDetail[], field:string): IPurchaseDetail[] {
    if (!items) return[]

    if(field==""){
      return items
    }else{
      field = field.toLocaleLowerCase();
 
      var name=""
      return items.filter( item=>{
        name = item.name.toLocaleLowerCase()
        if(name.includes(field)){
            return true
        }  
        

        if(item.purchase.date.includes(field))
          return true
          
        return false
      });
    }
    
  }

}
