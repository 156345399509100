import { Component, OnInit, HostListener, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { IUser } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { MenuItem } from "primeng/api";
import { SithecBaseApiService } from '../../../services/sithec-base-api.service';
import { SithecCommonApiService } from '../../../services/sithec-common-api.service';
import { finalize } from "rxjs/operators";
import { SessionService } from "../../../services/session.service";
import { SpinnerService } from "../../../services/spinner.service";

@Component({
  selector: 'lib-nav-bar-mobile',
  templateUrl: './nav-bar-mobile.component.html',
  styleUrls: ['./nav-bar-mobile.component.less']
})

export class NavBarMobileComponent implements OnInit {
  @Input() items!: MenuItem[];
  @Input() badgeItems!: number;
  @Input() admin!: boolean;
  @Output() showSideCart: EventEmitter<any> = new EventEmitter();
  @Output() onLogout: EventEmitter<any> = new EventEmitter();

  public user!: IUser;
  bandShowMenu: boolean = false;
  bandShowUserOptions: boolean = false;
  boolVisibleSelection: boolean = false;
  @Inject("sourceRequestor") protected sourceRequestorSource!: string
  constructor( 
    private router: Router,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private sessionService: SessionService,
    private sithecApiService: SithecCommonApiService,
    private sithecApiBaseService: SithecBaseApiService,
    ) {
      userService
      .getUserObservable()
      .subscribe((user) => {
        this.user = user;
      });

      if(this.sourceRequestorSource=="admin"){
        this.admin=true
      }else{
        this.admin=false
      }
    }

  ngOnInit(): void {
    this.onWindowScroll();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let element = document.querySelector(".navbar") as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add("navbar-scroll");
    } else {
      element.classList.remove("navbar-scroll");
    }
  }

  public routerNavbar(urlNavigate:any) {
    this.router.navigate([urlNavigate]);
  }

  showMobileMenu() {
    if(this.bandShowUserOptions) {
      this.bandShowMenu = false;
    } else {
      this.bandShowMenu = !this.bandShowMenu;
    }
  }

  showUserOptions() {
    if(this.bandShowMenu) {
      this.bandShowUserOptions = false;
    } else {
      this.bandShowUserOptions = !this.bandShowUserOptions;
    }
  }

  showCart() {
    this.showSideCart.emit();
  }
  abrirSeleccion(){
    this.boolVisibleSelection = !this.boolVisibleSelection;
    /* //console.log("informacion") */
  }
  salir(){
    this.boolVisibleSelection = false;
    this.spinnerService.show();
    this.sithecApiService
      .logout()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(() => {
        this.sessionService.clearSession();
        this.onLogout.emit();
        this.navigateToLogin();
      });
    this.sessionService.clearSession();
    this.sithecApiBaseService.handleLogoutResponse();
    this.spinnerService.hide();
    this.navigateToLogin();
    let location_sliced=window.location.toString().split('/')
    let newlocation = 'http://'+location_sliced[2]+'/account/authentication'; 
    window.location.href = newlocation;
  }
  private navigateToLogin(): void {
    this.router.navigate(["/account/authentication"]);
  }
}
