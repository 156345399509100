import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { AdminApiServiceService } from '../services/admin-api-service.service';
@Injectable({ providedIn: 'root' })
export class ProductsResolver implements Resolve<any> {
  toastrService: any;
  constructor(
    private apiService:AdminApiServiceService
    ) {}

  resolve(
    route: ActivatedRouteSnapshot, // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _state: RouterStateSnapshot
  ): Promise<any> {
    return new Promise((res) => {
      try {
        const productTypt = route.params['productType'];

        if (!!productTypt) {
          this.apiService
            .getProductsResolver(productTypt)
            .then((d: any) => {
              res({ elements: d });
            })
            .catch((_e: any) => {
              res(false);
            }); 
        } else {
          res(false);
        }
      } catch (error) {
        res(false);
      }
    });
  }
}
