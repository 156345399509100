import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform
} from 'ngx-image-cropper';

@Component({
  selector: 'app-modal-crop',
  templateUrl: './modal-crop.component.html',
  styleUrls: ['./modal-crop.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ModalCropComponent implements OnInit {
  @Input() title = 'titulo';
  @Input() text = 'texto';
  @Input() img1: any;
  @Input() img2: any;
  @Input() imageFile: any;
  @Input() ratio: any;
  @Output() success = new EventEmitter<any>();
  @Output() disableCrop = new EventEmitter<boolean>();
  @Output() newItemEvent = new EventEmitter<File>();

  // imgURL: string = this.apiURL + '/downloadFile/';

  constructor(
    // public modal: NgbModal,
    // public imageSVC: ImageService,
    // @Inject('apiURL') protected apiURL: string
  ) {}

  ngOnInit(): void {
    this.imageChangedEvent = this.imageFile;
    // this.imageName = this.imageFile.name;
  }

  images: string[] = [];
  base64 = '';
  imageName = 'name.png';
  imageBlob = this.dataURItoBlob(this.base64);
  imageFileAsFile = new File([this.imageBlob], this.imageName, {
    type: 'image/*'
  });

  emitEvent(event: any) {
    this.success.emit(event);
  }

  get imagen1() {
    return "";
    // return this.imgURL + this.img1;
  }

  get imagen2() {
    return ""
    // return this.imgURL + this.img2;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  acceptImage() {
    let arr = this.croppedImage.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const [type, ext] = mime.split('/');
    const file = new File([u8arr], `${this.uuid()}.${ext}`, { type: mime });

    this.imageFileAsFile = file;
    this.newItemEvent.emit(this.imageFileAsFile);
    this.disableCrop.emit(false);
    // this.modal.dismissAll();
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {}

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  close() {
    // this.modal.dismissAll();
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
