import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISwitch } from '../../../models/switch.model';

@Component({
  selector: 'form-check-box',
  templateUrl: './form-check-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckBoxComponent),
      multi: true,
    },
  ],
  styleUrls: ['./form-check-box.component.less'],
})
export class FormCheckBoxComponent implements ControlValueAccessor {
  value: any = null;
  @Input() label = '';
  @Input('class') className = '';
  @Input() id = '';
  @Input() selected = false;

  @Output() onChangeElement: EventEmitter<ISwitch> = new EventEmitter<ISwitch>();
  
  public onChange(val: any): void {}
  public onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(val: any): void {
    this.value = val.target.checked;
    this.onChange(val.target.checked);
    this.onTouched();
    this.onChangeElement.emit({
      id: this.id,
      value: val.target.checked
    });
  }
}