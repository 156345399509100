import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";  
import { PrimeNgModule } from "../prime-ng/prime-ng.module"; 
import { SithecTranslateModule } from "../translate/sithec-translate.module";  
import { ButtonComponent } from "./button/button.component";
import { CardInformationComponent } from "./card-information/card-information.component"; 
import { FilePreviewComponent } from "./file-preview/file-preview.component";
import { FormCheckBoxComponent } from "./form-check-box/form-check-box.component";  
import { FormChipComponent } from "./form-chip/form-chip.component"; 
import { FormErrorsComponent } from "./form-errors/form-errors.component"; 
import { FormInputPasswordComponent } from "./form-input-password/form-input-password.component"; 
import { FormInputComponent } from "./form-input/form-input.component";
import { FormOptionComponent } from "./form-option/form-option.component"; 
import { FormRadioButtonComponent } from "./form-radio-button/form-radio-button.component";
import { FormRadioCheckButtonComponent } from "./form-radio-check-button/form-radio-check-button.component";
import { FormSelectComponent } from "./form-select/form-select.component";
import { FormSwitchComponent } from "./form-switch/form-switch.component";
import { LogoutComponent } from "./log-out/logout.component";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { NavBarMobileComponent } from "./nav-bar-mobile/nav-bar-mobile.component";
import { FormCreditCardComponent } from "./form-credit-card/form-credit-card.component";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { ImageCropperModule } from "ngx-image-cropper";
import { ModalCropComponent } from "./modal-crop/modal-crop.component";
import { AutocompleteComponent } from "./autocomplete/autocomplete.component";
import { ErrorPageModule } from "./error-page/error-page.module";

@NgModule({
  declarations: [
    ButtonComponent,
    CardInformationComponent,
    FormCheckBoxComponent,
    FormErrorsComponent,
    FormInputComponent,
    FormInputPasswordComponent,
    FormOptionComponent,
    FormRadioButtonComponent,
    FormRadioCheckButtonComponent,
    FormSelectComponent,
    SpinnerComponent,
    FormChipComponent,
    FormSwitchComponent,
    FilePreviewComponent,
    NavBarComponent,
    LogoutComponent,
    NavBarMobileComponent,
    FormCreditCardComponent,
    ModalCropComponent,
    AutocompleteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SithecTranslateModule,
    ReactiveFormsModule,
    PrimeNgModule,
    CreditCardDirectivesModule,
    ImageCropperModule,
    ErrorPageModule
  ],
  exports: [
    ButtonComponent,
    CardInformationComponent,
    FormCheckBoxComponent,
    FormErrorsComponent,
    FormInputComponent,
    FormInputPasswordComponent,
    FormOptionComponent,
    FormRadioButtonComponent,
    FormRadioCheckButtonComponent,
    FormSelectComponent,
    SpinnerComponent,
    FormChipComponent,
    FormSwitchComponent,
    FilePreviewComponent,
    NavBarComponent,
    LogoutComponent,
    FormCreditCardComponent,
    ModalCropComponent,
    AutocompleteComponent
  ],
})
export class ControlsModule {}
