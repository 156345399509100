import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationGuardService } from "./authentication-guard.service";

@Injectable()
export class WebAuthenticationGuardService extends AuthenticationGuardService {

    /* override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return true;
      } */
}