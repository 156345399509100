import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAuthenticationResponse } from '../../models/authentication-response.model';
import { ToastrMessageService } from '../../services/message.service';
import { SithecCommonApiService } from '../../services/sithec-common-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { SessionService } from '../../services/session.service'; 
import { MessageService } from "primeng/api";
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-verificar',
  templateUrl: './verificar.component.html',
  styleUrls: ['./verificar.component.less']
})
export class VerificarComponent implements OnInit {

  constructor(
    private router: Router,
    private sithecApiService: SithecCommonApiService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrMessageService,
    private route: ActivatedRoute,
    private session: SessionService,
    private messageService: ToastrMessageService
  ) { 
    this.returnUrl = session.getURLSession();
  }

  token:string  = ""
  userId:string = ""
  cart:string = ""
  private returnUrl: string | null = null;

  ngOnInit(): void {
    const paramToken = this.route.snapshot.queryParamMap.get('token');
    const paramId = this.route.snapshot.queryParamMap.get('id');
    const cart = this.route.snapshot.queryParamMap.get('cart');
    if(paramToken){
      this.token=paramToken
    }
    if(paramId){
      this.userId=paramId
    }
    if(cart){
      this.cart=cart
    }
    //console.log(this.token);
    //console.log(this.userId);
  }

  verificar(){

    this.spinnerService.show();

    this.sithecApiService
      .verify(this.userId, this.token)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (response: IAuthenticationResponse) => {
          this.handleSuccessfulLogin();
          localStorage.removeItem("empresa")
          this.messageService.showSuccess("Se inicio sesión");
        },
        (res:any) => this.toastrService.showError(res.message)
      );
    
  }

  private handleSuccessfulLogin(): void {
    this.session.setURLSession("");
    /* //console.log(this.returnUrl) */
    localStorage.removeItem("cart")
    const url = this.cart=="1" ? "/cart" : "/landing";
    this.router.navigateByUrl(url);
  }
}
