<div class="resumen escritorio"> 
    <h2 class="estilos-titulo">Resumen de Compra</h2>

    <div class="detalles">
        <div>
            <p class="detalles-texto">Subtotal:</p>
            <div class="linea"></div>
            <div class="descuento-detalles">
                <p class="detalles-texto">Descuento:</p>
                <img class="descuento-icon" src="./../../../../../../../assets/images/info_001.svg" alt="" (click)="informacionDescuento()">
            </div>
            <div class="linea"></div>
            <p class="detalles-texto">IVA:</p>
            <div class="linea"></div>
            <p class="detalles-texto">Total:</p>
            <div class="linea"></div>
        </div>
        <div>
            <p class="detalles-cantidad">{{subtotal | currency}}</p>
            <div class="linea"></div>
            <div class="descuento-dato">
                <p class="detalles-cantidad" [ngClass]="{'discount': boolDiscount}">{{discount | currency}}</p>
            </div>
            <div class="linea"></div>
            <p class="detalles-cantidad">{{iva | currency}}</p>
            <div class="linea"></div>
            <p class="detalles-cantidad">{{total | currency}}</p>
            <div class="linea"></div>
        </div>
    </div>
    
    <div class="acomodo-izquierda">
        <app-button
        class="full-width ajuste add-car"
        [label]="'Confirmar'"
        type="submit"
        (onClick)="confirm()"
      >
    </app-button>
    </div>
</div>

<div class="resumen responsivo"> 
    

    <div class=" row"><h2 class="estilos-titulo">Resumen de Compra</h2>
        <div class="col-6">
            <p class="detalles-texto">Subtotal:</p>
            <div class="descuento-detalles-movil">
                <p class="detalles-texto">Descuento:</p>
                <img src="./../../../../../../../assets/images/info_001.svg" alt="" (click)="informacionDescuento()">
            </div>
            <p class="detalles-texto">IVA:</p>
            <p class="detalles-texto">Total:</p>
        </div>
        <div class="col-6">
            <p class="detalles-cantidad">{{subtotal | currency}}</p>
            <div class="linea"></div>
            <p class="detalles-cantidad" [ngClass]="{'discount': boolDiscount}">{{discount | currency}}</p>
            <div class="linea"></div>
            <p class="detalles-cantidad">{{iva | currency}}</p>
            <div class="linea"></div>
            <p class="detalles-cantidad">{{total | currency}}</p>
            <div class="linea"></div>
        </div>
<div class=" row">
        <app-button
        class="full-width ajuste add-car"
        [label]="'Confirmar'"
        type="submit"
        (onClick)="confirm()"
      >
    </app-button>
    </div>
    </div>
    
    
</div>