import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-garantia',
  templateUrl: './garantia.component.html',
  styleUrls: ['./garantia.component.less']
})
export class GarantiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
