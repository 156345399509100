import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, forwardRef, Input, OnInit } from "@angular/core";

@Component({
  selector: "form-credit-card",
  templateUrl: "./form-credit-card.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCreditCardComponent),
      multi: true,
    },
  ],
  styleUrls: ["./form-credit-card.component.less"],
})
export class FormCreditCardComponent implements ControlValueAccessor {
  value: any = null;

  @Input() type: "ccNumber" | "ccExp" | "ccCVC" = "ccNumber";
  @Input() label = "";
  @Input("class") className = "";
  @Input() placeholder = "";
  @Input() name = "";
  @Input() control: any;
  @Input() submitted: boolean = false;
  @Input() appearance: "standard" | "fill" = "standard";

  writeValue(val: any): void {
    this.value = val;
  }

  onChange(val: any): void {}
  onTouched(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(event: any): void {
    this.value = event?.target?.value;
    this.onChange(this.value);
    this.onTouched();
  }

  get errorClass() {
    return this.hasErrorInput()
      ? `${this.appearance} is-invalid-input`
      : this.appearance;
  }

  hasErrorInput(): boolean {
    return (
      (this.submitted && this.control?.invalid) ||
      (this.control &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }
}
