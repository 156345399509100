<div class="fullscreen">
<div class="container-fluid content">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-12 authentication-container">
            <div class="card">
              <div class="d-flex justify-content-center">
                <img src="../../../../../../assets/images/logoSithecBlanco.png" alt="" class="image-login" />
              </div>
              <ng-container *ngTemplateOutlet="showLogin ? login : signUp">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-template #login>
        <div class="title-container">
          <p class="title">{{ "AUTH.LOGIN" | translate }}</p>
        </div>
        <lib-login
        (showSignUp)="changeView(false)"></lib-login>
      </ng-template>
      
      <ng-template #signUp>
        <p class="title">{{ "AUTH.SIGN_UP" | translate }}</p>
        <lib-sign-up (showLogin)="changeView(true)"></lib-sign-up>
      </ng-template>
</div>
