import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { ICardInformationConfiguration } from '../../../models/product.model';
import { ToastrMessageService } from '../../../services/message.service';
import { ShoppingCarServiceService } from '../../../services/shopping-car-service.service';
import { AdminApiServiceService } from 'src/app/services/admin-api-service.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.less']
})
export class ProductDetailComponent implements OnInit {

  productType = ""
  modeCONTPAQi=false;
  numberUsers = 1;
  imei = "";
  productCategoryName = ""
  showAnimation: boolean = false;
  productList: ICardInformationConfiguration[] = []
  productContpaqList: ICardInformationConfiguration[] = []
  productCategoryList: string[] = []
  productSelected?: ICardInformationConfiguration;
  toastrService: any;
  isMobileView:boolean = false;
  constructor(
    private router: Router,
    private routeActive: ActivatedRoute,
    private apiService:AdminApiServiceService,
    private shoppingCarService: ShoppingCarServiceService,
    private message:ToastrMessageService
    ) {
  }

  ngOnInit(): void {
    this.productSelected = JSON.parse(
      JSON.stringify(this.routeActive.snapshot.data['products'].elements) //this.routeActive.snapshot.data['id'].elements.results
    );
    this.getImage();  
    this.productType = this.productSelected?.productType!;
    this.productSelected!.description = this.productSelected?.description?.replace(/"/g, '');
    //console.log(this.productSelected)
    /*if(this.productSelected){
      this.productList = productList;
      this.productSelected = this.productList[0]
      this.productList.forEach((item,index)=>{
        if(item.headerImage){
          let name = item.headerImage?.split("/").reverse()[0];
          this.getImage(name, index)
        }
        if(item.productCategory?.name){
          if(!this.productCategoryList.includes(item.productCategory?.name)){
            this.productCategoryList.push(item.productCategory?.name)
          }
        }
      })
    }*/
      this.checkViewport();
  }

  goBack(): void {
    this.router.navigate(['/product/'+this.productSelected?.productType]);
  }

  selectProduct(product: ICardInformationConfiguration){
    this.productSelected = product;
  }

  get getSubtotal(){
    return this.productSelected?.price! * this.numberUsers;
  }
  getImage(){
    let name = this.productSelected!.headerImage?.split("/").reverse()[0];
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if(response){
            //Verificar que existe imagen
            this.productSelected!.headerImage = response.result;
          } 
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
          // this.toastrService.showError(message)
        }
      );
  }

  changeFilterCategory(productCategory: string){
    this.productCategoryName = productCategory
    this.productContpaqList = this.productList.filter(item => item.productCategory?.name==this.productCategoryName)
  }

  goTo(product: ICardInformationConfiguration){
    this.router.navigate(['/products/information/nuevo/'+product.id]);
  }

  product: any = {};


  async addCard() {
    //VerificarIMEI
    if(this.numberUsers<=0){
      this.message.showError('La cantidad debe ser mayor o igual a 1');
      return;
    }
    this.product.tipoLicencia = 0;
    this.product = this.productSelected
    this.product.quantity = this.numberUsers;
    this.product.usersQuantity = this.numberUsers;
    this.product.selectedCost = this.productSelected?.price;
    if(this.imei){
      if(this.shoppingCarService.getCartData().find(item => item.imei == this.imei)){
        this.message.showError('Producto con imei repetido');
        return;
      }
      this.product.imei = this.imei;
    }
    if(this.productType == 'XML_SAT'){
      if(!(this.imei.length >=52 && this.imei.length <= 54)){
        // this.message.showError('La longitud del IMEI no es válida');
        // return;
      }
    }
    // this.product.costMonoRFCUA = this.usuarioMono
    // if(this.usuarioMono!=null){
    //   this.producto.costMonoRFCUA = this.usuarioMono
    // }else{
      this.product.costMonoRFCUA = 0;
    // }
    this.product.isMulti=false;
    this.product.isMono=true;
    this.shoppingCarService.setItemLocalStorage(this.product);
    //this.router.navigate(['/cart']);
    if(!this.isMobileView){
    this.showAnimation = true;
    setTimeout(() => {
      const animatedImage = document.getElementById('animatedImage');
      if (animatedImage) {
        animatedImage.classList.add('animate-move');
      }
    }, 50);

    setTimeout(() => {
      this.showAnimation = false;
      window.location.reload()
    }, 1050);}
    else{
      this.message.showInfo('Producto añadido exitosamente');
      window.location.reload()
    }
    
  }

  async payNow() {
    //VerificarIMEI
    this.product.tipoLicencia = 0;
    this.product = this.productSelected
    this.product.quantity = this.numberUsers;
    this.product.usersQuantity = this.numberUsers;
    this.product.selectedCost = this.productSelected?.price;
    if(this.imei){
      if(this.shoppingCarService.getCartData().find(item => item.imei == this.imei)){
        this.message.showError('Producto con imei repetido');
        return;
      }
      this.product.imei = this.imei;
    }
    if(this.productType == 'XML_SAT'){
      if(!(this.imei.length >=52 && this.imei.length <= 54)){
        // this.message.showError('La longitud del IMEI no es válida');
        // return;
      }
    }
    // this.product.costMonoRFCUA = this.usuarioMono
    // if(this.usuarioMono!=null){
    //   this.producto.costMonoRFCUA = this.usuarioMono
    // }else{
      this.product.costMonoRFCUA = 0;
    // }
    this.product.isMulti=false;
    this.product.isMono=true;
    this.shoppingCarService.setItemLocalStorage(this.product);
    if(!this.isMobileView){
    this.showAnimation = true;
    setTimeout(() => {
      const animatedImage = document.getElementById('animatedImage');
      if (animatedImage) {
        animatedImage.classList.add('animate-move');
      }
    }, 50);

    setTimeout(() => {
      this.showAnimation = false;
      this.router.navigate(['/cart']);
    }, 1050);
    }
    else{
    this.message.showInfo('Producto añadido exitosamente');
    this.router.navigate(['/cart']);}
  }

  toSpanish(lapse: string = ''){
    switch(lapse){
      case 'Day':
        return 'Diaria';
      case 'Month':
        return 'Mensual';
      case 'Year':
        return 'Anual';
      case 'Permanent':
        return 'Permanente';
    }
    return ''
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    this.isMobileView = window.innerWidth <= 768;
  }
}
