import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { SithecCommonApiService } from './sithec-common-api.service';
import { IPurchaseData, IUser } from '../models/purchase.model';
import { IFileInformation } from '../models/file.model';
import { Filter } from '../models/filter';

@Injectable({
  providedIn: 'root'
})
export class AdminApiServiceService extends SithecCommonApiService {
  getPurchases(): Observable<IPurchaseData[]> {
    return this.httpClient.get<IPurchaseData[]>(`${this.apiUrl}/purchases`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  purchaseDetailsED(effectiveDate: any,purchaseDetailId: string): Observable<any> {
    return this.httpClient
      .put<any>(`${this.apiUrl}/purchaseDetailsED/${purchaseDetailId}`, effectiveDate, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  getPurchasesFiltered(filter: Filter): Observable<IPurchaseData[]> {
    let queryParams: string = `Product=${filter.search}&StartDate=${filter.initialDate}&EndDate=${filter.finalDate}`;
    if(!filter.search && !filter.initialDate && !filter.finalDate){
      queryParams += 'Status='
    }else{
      queryParams += '&Status='
    }
    if(filter.todo){
      queryParams = queryParams.replace("Status=","");
    }else{
      let status = ""
      if(filter.certificado)
        status+="c,"
      if(filter.pdf)
        status+="p,"
      if(filter.xml)
        status+="x"
      if(status.endsWith(",")){
        status = status.substring(0,status.length-1)
      }
      queryParams += `${status}`;
      if(!status){
        queryParams = queryParams.replace("Status=","");
      }
    }
    //Quitar filtros no utilizados
    if(!filter.search){
      queryParams = queryParams.replace("Product=","")
    }
    if(!filter.initialDate){
      queryParams = queryParams.replace("&StartDate=","")
    }
    if(!filter.finalDate){
      queryParams = queryParams.replace("&EndDate=","")
    }
    if(queryParams.startsWith("&"))
      queryParams = queryParams.substring(1)
    if(queryParams.endsWith("&")){
      queryParams = queryParams.substring(0,queryParams.length-1)
    }
    return this.httpClient.get<IPurchaseData[]>(`${this.apiUrl}/purchasesfilter?`+queryParams, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  postSaveFacturaPDF(fileData: IFileInformation): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/file/uploadFacturaPDF`, fileData, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  postSaveFacturaXML(fileData: IFileInformation): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/file/uploadFacturaXML`, fileData, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  postSaveCertificado(fileData: IFileInformation): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/file/uploadCertificado`, fileData, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  ///REVISAR ESTO PARA EL MARTES
  postUploadFileImage(fileData: IFileInformation): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/file/uploadFileImage`, fileData, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  async saveFileInS3(url:string, file:File){
    /* //console.log("url: "+url) */
    await this.httpClient.put(url, file).toPromise()
  }

  getUsers(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(`${this.apiUrl}/user`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  // -- Products
  getProducts(size?: number, bloque:number = 0, search=""): Observable<any[]> {
    let url = `${this.apiUrl}/productFilter?`;
    if(search)
      url+= "search="+search+"&";
    if(size){
      url+= "size="+size
      url+= "&bloque="+bloque
    }
    return this.httpClient.get<any[]>(url, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  getProductsBar(size?: number, bloque:number = 0): Observable<any[]> {
    let url = `${this.apiUrl}/product?`;
    if(size){
      url+= "size="+size
      url+= "&bloque="+bloque
    }
    return this.httpClient.get<any[]>(url, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  getProductsResolver(search: string="") {
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}/product?`;
      if(search)
        url+= "productType="+search
      this.httpClient.get(url, {
        headers: this.getHeaders(),
      }).subscribe((data) => {
        const response = data ? data : null;
        resolve(response);
      });
    });
  }

  getProductResolver(idProduct: string="") {
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}/product?`;
      this.httpClient.get(`${this.apiUrl}/product/${idProduct}`, {
        headers: this.getHeaders(),
      }).subscribe((data) => {
        const response = data ? data : null;
        resolve(response);
      });
    });
  }

  getProduct(idProduct: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiUrl}/product/${idProduct}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  createProduct(productData: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/product`, productData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  updateProduct(productData: any,idProduct: string): Observable<any> {
    return this.httpClient
      .put<any>(`${this.apiUrl}/product/${idProduct}`, productData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  deleteProduct(idProduct: string): Observable<any[]> {
    return this.httpClient.delete<any[]>(`${this.apiUrl}/product/${idProduct}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  // -- Categories
  getProductCategories(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiUrl}/productCategories`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  // -- Banners
  getBanners(size?: number, bloque:number = 0, search="", Web=0): Observable<any[]> {
    let url = `${this.apiUrl}/bannerFilter?`;
    if(search)
      url+= "search="+search+"&";
    if(size){
      url+= "size="+size;
      url+= "&bloque="+bloque;
    }
    if(Web==1){
      url+= "Web=1";
    }
    return this.httpClient.get<any[]>(url, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  getBanner(idBanner: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiUrl}/banner/${idBanner}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  createBanner(bannerData: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/banner`, bannerData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  
  updateBanner(bannerData: any,idBanner: string): Observable<any> {
    return this.httpClient
      .put<any>(`${this.apiUrl}/banner/${idBanner}`, bannerData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  deleteBanner(idBanner: string): Observable<any[]> {
    return this.httpClient.delete<any[]>(`${this.apiUrl}/banner/${idBanner}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  pausedBanner(paused: boolean,idBanner: string): Observable<any> {
    const pausedBody = {
      paused: paused
    }
    return this.httpClient
      .put<any>(`${this.apiUrl}/bannerpaused/${idBanner}`, pausedBody, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // Upload image
  getImage(itemInfo: any): Observable<any> {
    return this.httpClient
      .post(`${this.apiUrl}/file/getobjecturl`, itemInfo, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  getPublicImage(itemInfo: any): Observable<any> {
    return this.httpClient
      .post(`${this.apiUrl}/file/getpublicobjecturl`, itemInfo, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  uploadImage(itemData: FormData): Observable<any> {
    return this.httpClient
      .post(`${this.apiUrl}/file/uploadFileImage`, itemData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  getUserAdmin(): Observable<any> {
      return this.httpClient.get<any>(`${this.apiUrl}/userAdmin`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => this.handleError(error)));
    }
  createUserAdmin(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/userAdmin`, userData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  updateUserAdmin(idUser:string, userData:any ): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}/userAdmin/${idUser}`, userData, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  searchUserAdmin(id:string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/userAdmin/${id}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  searchAllUserAdmin(value:string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/userAdmin/?Search=${value}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  deleteUserAdmin(idUser: string): Observable<any[]> {
    return this.httpClient.delete<any[]>(`${this.apiUrl}/userAdmin/${idUser}`, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
  changePasswordAdmin(id:string, userData:any ): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}/changePasswordAdmin/${id}`, userData, {
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }
}
