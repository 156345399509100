<div class="barra-superior"></div>
<div class="barra-inferior">
  <div class="sub-espacio">
    <div class="espacio-logo">
      <img src="../../../../../../assets/images/logo footer.svg" alt="">
      <p class="sub-titulo">Sithec. México</p>
    </div>
  </div>
  <div class="sub-espacio">
    <div class="centrado">
      <div class="ajuste-reponsive">
        <p class="sub-titulo2">Contáctanos</p>
      </div>
      <div class="contacto-info">
        <div class="iconos">
          <img style="cursor: pointer;" src="../../../../../../assets/images/direccion_footer.svg" alt="" (click)="redirect('https://www.google.com/maps/dir/21.8825088,-102.2891321/sithec/@21.901496,-102.3196978,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8429effe2a3ee477:0x272d9a4d6a6e3ba6!2m2!1d-102.312751!2d21.9198595')">
          <a href="tel:+524491200294"><img src="../../../../../../assets/images/phone_footer.svg" alt=""></a>
          <a href="mailto:ventas@sithec.com.mx"><img src="../../../../../../assets/images/mailk_footer.svg" alt=""></a>
        </div>
        <div class="texto">
          <div style="cursor: pointer;" (click)="redirect('https://www.google.com/maps/dir/21.8825088,-102.2891321/sithec/@21.901496,-102.3196978,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8429effe2a3ee477:0x272d9a4d6a6e3ba6!2m2!1d-102.312751!2d21.9198595')">
            <p>Paseo Sierra Hermosa 128,</p>
            <p>int. 5, Los Bosques C.P. 20120</p>
          </div>
          <div>
            <a href="tel:+524491200294" class="footer-data">Ventas: 449-120-0294</a>
          </div>
          <a href="mailto:ventas@sithec.com.mx" class="footer-data">ventas@sithec.com.mx</a>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-espacio">
    <div class="centrado2">
      <div class="redes">
        <img src="../../../../../../assets/images/facebook_footer.svg" alt="" (click)="redirect('https://www.facebook.com/SithecAgs/')">
        <img src="../../../../../../assets/images/whatsapp_footer.svg" alt="" (click)="redirect('https://wa.me/message/QUGKPFYGA25AF1')">
        <img src="../../../../../../assets/images/linked_in_footer.svg" alt="" (click)="redirect('https://www.linkedin.com/company/sithec/mycompany/')">
        <img src="../../../../../../assets/images/icon_world.svg" alt="" (click)="redirect('https://sithec.com.mx/')">
      </div>
      <p class="texto-redes">© Sithec 2022</p>
      <p class="texto-redes">Consulta Nuestro <a class="texto-redes enlace-documentacion" routerLink="aviso">Aviso de Privacidad</a></p>
      <!-- <br> -->
      <a class="texto-redes enlace-documentacion" routerLink="terminos">Términos y Condiciones</a>
      <br>
      <a class="texto-redes enlace-documentacion" routerLink="garantia">Políticas de Garantía</a>
    </div>
  </div>
</div>