<div class="container-fluid forgot-password-container margen-arriba">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12">  
          <div class="card distribution">
            <div class="centrado">
              <img src="/assets/images/logo_sithec.png" alt="" class="image-login" />
            </div>
            <p class="title">Inicia sesión para confirmar tu cuenta</p>
            <app-button
                class="verify-button d-flex justify-content-center"
                [label]="'Iniciar Sesión'"
                (onClick)="verificar()"
            >
            </app-button>
          </div>
      </div>
    </div>
  </div>
  