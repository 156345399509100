import { Pipe, PipeTransform } from '@angular/core';
import { IPurchaseData } from '../models/purchase.model';

@Pipe({
  name: 'filterPurchase'
})
export class FilterPurchasePipe implements PipeTransform {

  transform(items: IPurchaseData[], field:string): IPurchaseData[] {
    /* if (!items) return[]
    const obj = items[0];
    obj.card;
    const {card, certificado} = obj; */

    if(field==""){
      return items
    }else{
      field = field.toLocaleLowerCase();

      var name=""
      return items.filter( item=>{
        for (const detail of item.details) {
          name = detail.name.toLocaleLowerCase()
          if(name.includes(field)){
            return true
          }    
        }

        if(item.date.includes(field))
          return true
        if(item.card.includes(field))
          return true
          
        return false
      });
    }
    
  }

}
