import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { IUser } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { CfdiServiceService } from '../../services/cfdi-service.service';
import { SithecCommonApiService } from '../../services/sithec-common-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { finalize } from 'rxjs/operators';
import { ToastrMessageService } from '../../services/message.service';
import { UpdateRequest } from '../../models/update-request';
import { ISwitch } from '../../models/switch.model';
import { IDeleteInformation } from '../../models/delete.model';
import { IFileRequest } from '../../models/file-request';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-informacion-usuario',
  templateUrl: './informacion-usuario.component.html',
  styleUrls: ['./informacion-usuario.component.less']
})
export class InformacionUsuarioComponent implements OnInit {

  mostrarCarga: boolean = false;
  mostrarWarning: boolean = false;
  file : any
  message: String = "";
  fileName: String = "";
  userInformation!: IUser 
  public personaFisica = true;
  public submitted = false;
  public solicitarDatos = false;
  facturar = false
  comprobacionFactura=""
  
  updateForm: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      //Se valida que el telefono tenga un mínimo de 13 carácteres
      phone:new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      rfc: new FormControl(),
      cp: new FormControl(),
      calle:new FormControl(),
      numero_exterior:new FormControl(),
      numero_interior:new FormControl(),
      colonia:new FormControl(),
      municipio:new FormControl(),
      estado:new FormControl(),
      razon: new FormControl(),
      persona: new FormControl(),
      regimen: new FormControl(),
      username: new FormControl('', [Validators.required, Validators.email]),
      cfdi: new FormControl()
    }
  );

  constructor(
    private userService:UserService, 
    private cdfi:CfdiServiceService, 
    private sithecCommonApiService:SithecCommonApiService,
    private spinnerService:SpinnerService,
    private toastrService:ToastrMessageService,
    private router:Router) { }

  ngOnInit(): void {
    this.userService.getUserObservable().subscribe((user) => {
      this.userInformation = user;
    });
    
    
    this.facturar=!this.userInformation.rfc
    this.solicitarDatos=!this.facturar
    this.comprobacionFactura=!this.facturar?"facturar":""
    
    this.setUserData()
  }

  setUserData(cancelar?:boolean){
    this.updateForm.controls['name'].setValue(this.userInformation.name);
    this.updateForm.controls['lastName'].setValue(this.userInformation.lastName);
    this.updateForm.controls['phone'].setValue(this.userInformation.phone);
    this.updateForm.controls['username'].setValue(this.userInformation.username);
    if(this.solicitarDatos){
      this.updateForm.controls['razon'].setValue(this.userInformation.razon);
      this.updateForm.controls['estado'].setValue(this.userInformation.estado);
      this.updateForm.controls['calle'].setValue(this.userInformation.calle);
      this.updateForm.controls['colonia'].setValue(this.userInformation.colonia);
      this.updateForm.controls['estado'].setValue(this.userInformation.estado);
      this.updateForm.controls['cp'].setValue(this.userInformation.cp);
      this.updateForm.controls['municipio'].setValue(this.userInformation.municipio);
      this.updateForm.controls['numero_exterior'].setValue(this.userInformation.numero_exterior);
      this.updateForm.controls['numero_interior'].setValue(this.userInformation.numero_interior);
      this.updateForm.controls['rfc'].setValue(this.userInformation.rfc);
      this.updateForm.controls['persona'].setValue(this.userInformation.persona);
      this.updateForm.controls['regimen'].setValue(this.userInformation.regimen);
      this.updateForm.controls['cfdi'].setValue(this.cdfi.getCFDI());
    }
    
    this.personaFisica = this.userInformation.persona=="Persona Física";

    if(this.userInformation.persona==""){
      //console.log("No hay selección")
      this.updateForm.controls['persona'].setValue('Persona Moral');
      this.updateForm.controls['regimen'].setValue('General de Ley Personas Morales');
    }else{
      //console.log(this.personaFisica)
    }
    if(cancelar){
      this.toastrService.showInfo("Información reestablecida")
    }
  }

  verificarCarga(){
    if(this.file==null)
      return this.userInformation.certificado ? "Cargado" : "No Cargado"
    else
      return this.file.name
  }

  async eliminarArchivo(){
    const file: IDeleteInformation = {
      objectKey: this.userInformation.id+".pdf",
      subDirectory: 'constancias'
    };
    await this.sithecCommonApiService
    .deleteFile(file)
    .toPromise();

    this.userInformation.certificado=false

    this.userService.setUser(this.userInformation)
    window.location.reload()
  }

  cambiarSeleccionRazonSocial(e?:any){
    if(e.target.value == "Persona Física"){
      this.personaFisica= true;
      this.updateForm.controls['regimen'].setValue('Sueldos y Salarios e Ingresos Asimilados a Salarios');
    }else{
      this.personaFisica= false;
      this.updateForm.controls['regimen'].setValue('General de Ley Personas Morales');
    }
    
  }

  submit(){
    if(this.updateForm.invalid){
      this.submitted = true;
      return;
    }
    this.submitted = false;
    var updateInformation = new UpdateRequest();

    if(!this.solicitarDatos){
      this.updateForm.controls['razon'].setValue("");
      this.updateForm.controls['estado'].setValue("");
      this.updateForm.controls['calle'].setValue("");
      this.updateForm.controls['colonia'].setValue("");
      this.updateForm.controls['estado'].setValue("");
      this.updateForm.controls['cp'].setValue("");
      this.updateForm.controls['municipio'].setValue("");
      this.updateForm.controls['numero_exterior'].setValue("");
      this.updateForm.controls['numero_interior'].setValue("");
      this.updateForm.controls['rfc'].setValue("");
      this.updateForm.controls['persona'].setValue("");
      this.updateForm.controls['regimen'].setValue("");
    }
    
    updateInformation.user = this.updateForm.value
    updateInformation.user.id = this.userInformation.id
    updateInformation.constancia = this.file
    updateInformation.constanciaCarga = this.userInformation.certificado!
    

    this.sithecCommonApiService
      .update(updateInformation)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (response) => {
          /* //console.log(response) */
          this.toastrService.showSuccess("Información actualizada correctamente")
          this.redirigir();
          //setTimeout(this.redirigir, 1000);
        },
        (errorDefinition:any) => this.handleError(errorDefinition)
      );
  }
  async cancelar(){
    await Swal.fire({
      title: '¿Está seguro?',
      text: "Si cancela se borrarán todos sus cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'No',
      cancelButtonText: 'Sí',
    }).then((result) => {
      if (result.isConfirmed) {
        /* Swal.fire(
          'Eliminado',
          'Producto eliminado',
          'success'
        ) */
      } else{
        /* Swal.fire(
          'Operación cancelada',
          'Los cambios no se verán reflejados',
          'success'
        ) */
        this.redirigir()
      }
    })
  }

  redirigir(){
    this.router.navigate(['/landing']);  
  }

  handleError(error: any) { 
    this.toastrService.showError(error.message) 
  }

  cambiarCFDI(e:any){
    //console.log(e.target.value)
    this.cdfi.setCFDI(e.target.value)
  }

  async setFile(event: any) {
    if (!event || !event.target || !event.target.files || event?.target?.files?.length == 0)
        return;

    this.file = event.target.files[0];
    this.mostrarWarning=false

    if(!this.file.type.includes('pdf'))
    {
      /* this.message="El archivo no es un PDF. Por favor, suba otro con el formato correcto." */
      this.toastrService.showError("El archivo no es un PDF. Por favor, suba otro con el formato correcto.") 
      /* this.mostrarWarning=true */
      this.file = null;
    }else{
      this.mostrarWarning=false
      this.fileName = this.file.name
      this.toastrService.showSuccess("Archivo cargado correctamente")
    }
      
  }

  solicitarDatosFactura(seleccion:ISwitch){
    this.solicitarDatos=seleccion.value
    if(this.solicitarDatos){
      //console.log("Solicitar")
      this.updateForm.controls['persona'].setValue('Persona Física');
      this.updateForm.controls['regimen'].setValue('Sueldos y Salarios e Ingresos Asimilados a Salarios');
      this.updateForm.get('rfc')!.setValidators([Validators.required]);
      this.updateForm.get('cp')!.setValidators([Validators.required]);
      this.updateForm.get('razon')!.setValidators([Validators.required]);
      this.updateForm.get('persona')!.setValidators([Validators.required]);
      //this.updateForm.get('estado')!.setValidators([Validators.required]);
      //this.updateForm.get('calle')!.setValidators([Validators.required]);
      //this.updateForm.get('colonia')!.setValidators([Validators.required]);
      //this.updateForm.get('estado')!.setValidators([Validators.required]);
      //this.updateForm.get('estado')!.setValidators([Validators.required]);
      this.updateForm.get('cp')!.setValidators([Validators.required]);
      //this.updateForm.get('municipio')!.setValidators([Validators.required]);
      //this.updateForm.get('numero_exterior')!.setValidators([Validators.required]);
      //this.updateForm.get('numero_interior')!.setValidators([Validators.required]);

      this.updateForm.get('rfc')!.updateValueAndValidity();
      this.updateForm.get('cp')!.updateValueAndValidity();
      this.updateForm.get('razon')!.updateValueAndValidity();
      this.updateForm.get('persona')!.updateValueAndValidity();
      this.updateForm.get('estado')!.updateValueAndValidity();
      this.updateForm.get('calle')!.updateValueAndValidity();
      this.updateForm.get('colonia')!.updateValueAndValidity();
      this.updateForm.get('estado')!.updateValueAndValidity();
      this.updateForm.get('cp')!.updateValueAndValidity();
      this.updateForm.get('municipio')!.updateValueAndValidity();
      this.updateForm.get('numero_exterior')!.updateValueAndValidity();
      this.updateForm.get('numero_interior')!.updateValueAndValidity();

      this.updateForm.get('razon')!.updateValueAndValidity();
      this.updateForm.get('persona')!.setValidators([]);
      this.setUserData()
    }else{
      //console.log("No solicitar")
      if(this.userInformation.rfc){
        this.toastrService.showWarn("Si desactivas la facturación al actualizar se borrará tu información")
      }
      this.updateForm.controls['persona'].setValue('');
      this.updateForm.controls['regimen'].setValue('');
      this.updateForm.get('rfc')!.setValidators([]);
      this.updateForm.get('cp')!.setValidators([]);
      this.updateForm.get('razon')!.setValidators([]);
      this.updateForm.get('persona')!.setValidators([]);
      this.updateForm.get('razon')!.setValidators([]);
      this.updateForm.get('estado')!.setValidators([]);
      this.updateForm.get('calle')!.setValidators([]);
      this.updateForm.get('colonia')!.setValidators([]);
      this.updateForm.get('razon')!.setValidators([]);
      this.updateForm.get('estado')!.setValidators([]);
      this.updateForm.get('estado')!.setValidators([]);
      this.updateForm.get('cp')!.setValidators([]);
      this.updateForm.get('municipio')!.setValidators([]);
      this.updateForm.get('numero_exterior')!.setValidators([]);
      this.updateForm.get('numero_interior')!.setValidators([]);

      this.updateForm.get('rfc')!.updateValueAndValidity();
      this.updateForm.get('cp')!.updateValueAndValidity();
      this.updateForm.get('razon')!.updateValueAndValidity();
      this.updateForm.get('persona')!.updateValueAndValidity();
      this.updateForm.get('estado')!.updateValueAndValidity();
      this.updateForm.get('calle')!.updateValueAndValidity();
      this.updateForm.get('colonia')!.updateValueAndValidity();
      this.updateForm.get('estado')!.updateValueAndValidity();
      this.updateForm.get('cp')!.updateValueAndValidity();
      this.updateForm.get('municipio')!.updateValueAndValidity();
      this.updateForm.get('numero_exterior')!.updateValueAndValidity();
      this.updateForm.get('numero_interior')!.updateValueAndValidity();
      
      this.updateForm.get('razon')!.setValidators([]);
      this.updateForm.get('persona')!.setValidators([]);
    }
  }

  abrirModalCarga(){
    this.mostrarCarga=true
  }

  cerrarModalCarga(){
    this.mostrarCarga=false
    this.mostrarWarning=false
  }

  async redirigirConstancia(){
    await this.getURLFile()
  }

  async getURLFile(){
    const file: IFileRequest= {
      objectKey: this.userInformation.rfc+".pdf",
      subDirectory: 'constancias',
      duration: 2
    };
    var url=""
    await this.sithecCommonApiService
    .getURLFile(file).subscribe((res) => {
      url=res
      //window.location.href=url
      window.open(url, '_blank');
    });
  }
}
