<div class="recuadro">
    <div class="row responsive-goback" [hidden]="!isMobileView">
        <div class="go-back col-2" >
          <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()" id="imgCONTPAQi" >
        </div>
        <div class="col-10">
            <img *ngIf="!showAnimation" id="imgProducto" [src]="imagen" alt="Product" style="max-width: 100%;">
        </div>
      </div>
      <img  [hidden]="isMobileView" *ngIf="showAnimation" id="animatedImage" [src]="imagen" alt="Product" class="animated-image">
   <div id="cambio" class="opciones" [formGroup]="productForm">
        <p class="titulo">Cambiar características de mi producto</p>
        <div class="informacion-general">    
            <p class="nombre-producto">{{producto.name}}</p>
            <p class="texto text-adjustment">Al seleccionar "CAMBIAR CARACTERÍSTICAS", las caracterísiticas elegidas serán aplicadas desde el día de hoy, hasta finalizar la vigencia atual de la licencia.</p>
            <p class="subtitulo ajuste-subtitulo">Numero de serie:</p>
            <div class="buscador">
                <input class="campo-busqueda" type="text" style="text-transform:uppercase" #busqueda>
                <app-button
                    class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                    [label]="'Buscar' | translate"
                    type="submit"
                    (onClick)="getInfoSerialNumber(busqueda.value)"
                    >
                </app-button>
            </div>
            <div *ngIf="encontrado">
                <div class="separador"></div>
                <p class="texto">Fecha de registro: {{registro}}</p>
                <p class="texto">Vigencia: {{vigencia}}</p>
                <p class="texto">Usuarios: {{usuariosInfo}}</p>
                <p class="texto">Empresas: {{empresas}}</p>
                <p class="texto">Licenciamiento: {{licenciamiento}}</p>
                <p class="subtitulo ajuste-subtitulo">Tipo de Licencia:</p>
                <div class="selectores-rfc subtitulo" >
                    <!-- *ngIf="producto?.productFeatures?.annualLicenseMultiRFC? true: false" -->
                    <div class="selector-rfc" >
                        <input id="licencia1" type="radio" value="1" name="licencia1" formControlName="licencia" (change)="cambiarSeleccion($event)">
                        <label for="licencia1">Multi-empresa</label>
                    </div>
                    <!-- *ngIf="producto?.productFeatures?.annualLicense1RFC? true: false" -->
                    <div class="selector-rfc" *ngIf="!(this.multi)">
                        <input id="licencia2" type="radio" value="2" name="licencia1" formControlName="licencia" (change)="cambiarSeleccion($event)">
                        <label for="licencia2">Mono-empresa</label>
                    </div>
                </div>
                <div class="acomodo">
                    <p class="texto">Número de Usuarios:</p>
                    <div class="container-quantity">
                        <span class="next" (click)="addQuantity()"></span>
                        <span class="prev" (click)="removeQuantity()"></span>
                        <div id="box">
                            <span>{{usuarios}}</span>
                        </div>
                    </div>
                </div>
                <p class="subtitulo">Precio</p>
                <div class="calculo-precio">
                    <app-button
                    class="full-width app-button-rounded app-button-secondary hover-button ajuste ajuste-verde"
                    [label]="'Calcular'"
                    type="submit"
                    (onClick)="cotizar()"
                    >
                    </app-button>
                    <div class="acomodo margenes separacion-boton">
                        <p class="texto espaciado ajuste">Total:</p>
                        <p class="subtitulo ajuste">{{total | currency}}</p>
                    </div>
                </div>
                <div *ngIf="cotizado">
                    <app-button
                        class="full-width app-button-rounded app-button-secondary hover-button ajuste"
                        [label]="'CART.ADD' | translate"
                        type="submit"
                        (onClick)="addCard()"
                        >
                    </app-button>
                </div>
            </div>
        </div>     
    </div>
</div>
