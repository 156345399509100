import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IErrorDefinition } from '.././../../models/error-definition.model';

@Component({
  selector: 'recaptcha',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true,
    },
  ],
})

export class RecaptchaComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor {
  private scriptId = 'recaptcha-script';
  private onLoadHandlerName = 'onRecaptchaLoad';
  private widgetId!: number;

  onChange = (val: string): void => {};
  onTouched = (): void => {};

  @Output() onSuccess = new EventEmitter();
  @Output() onError = new EventEmitter<IErrorDefinition>();

  constructor(
    @Inject('recaptchaKey') private recaptchaKey: string,
    @Inject('recaptchaUrl') private recaptchaUrl: string,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private translate: TranslateService
  ) {
    this.onLoad = this.onLoad.bind(this);
    this.callback = this.callback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  ngAfterViewInit(): void {
    this.loadScript();
  }

  ngOnDestroy(): void {
    this.reset();
  }

  writeValue(obj: string): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  execute() {
    return (<any>window).grecaptcha.execute(this.widgetId);
  }

  private reset(): void {
    (<any>window).grecaptcha.reset(this.widgetId);
  }

  private loadScript() {
    if (document.getElementById(this.scriptId)) {
      return this.onLoad();
    }

    this.renderer.setProperty(window, this.onLoadHandlerName, this.onLoad);
    this.renderer.appendChild(document.head, this.createScriptTag());
  }

  private onLoad() {
    this.ngZone.run(() => {
      this.widgetId = (<any>window).grecaptcha.render(
        this.elementRef.nativeElement,
        {
          sitekey: this.recaptchaKey,
          size: 'invisible',
          callback: this.callback,
          'error-callback': this.errorCallback,
        }
      );
    });
  }

  private callback(token: string) {
    this.ngZone.run(() => {
      this.onChange(token);
      this.onTouched();
      this.onSuccess.emit();
      this.reset();
    });
  }

  private errorCallback(): void {
    this.ngZone.run(() =>
      this.onError.emit({ message: this.translate.instant('ERROR.RECAPTCHA') })
    );
  }

  private createScriptTag() {
    const script = this.renderer.createElement('script');

    script.id = this.scriptId;
    script.innerHTML = '';
    script.src = `${this.recaptchaUrl}?onload=${this.onLoadHandlerName}&render=explicit`;
    script.async = true;
    script.defer = true;

    return script;
  }
}
