<!-- <div class="modal-content"> -->
<div class="modal-header">
  <a type="button" class="close" (click)="close()">
    <i class="fas fa-times"></i>
  </a>
  <h4 class="modal-title" id="modal-title">
    {{ title | translate }}
  </h4>
  <a class="close">
    <i class="fas fa-check ml-2 mr-2 pointer"
      (click)="acceptImage()"></i>
  </a>
</div>
<div class="modal-body">
  <br />
  <br />
  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="ratio" [roundCropper]="false"
    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"></image-cropper>
</div>
<!-- </div> -->