<div class="container">
    <div class="row">
      <div class="col-md-6 align-self-center">

        <img  src="assets/images/sibothAzul.png" alt="">
      </div>
      <div class="col-md-6 align-self-center">
        <h1>404</h1>
        <h2>{{"ERROR.LOST" | translate}}</h2>
        <p>{{"ERROR.404" | translate}}
        </p>
        <button class="btn green">HOME</button>
      </div>
    </div>
  </div>