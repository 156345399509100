import { Component, AfterViewInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { commonEnvironment } from "../../../../environments/common_environment";
import { ToastrMessageService } from "../../../services/message.service";
import { SithecCommonApiService } from "../../../services/sithec-common-api.service";
import { Subject } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import {
  CODE_LENGTH_VERIFICATION,
  RETURN_URL_PARAMETER_KEY,
  VERIFICATION_CODE_PARAMETER_KEY,
  VERIFICATION_ID_PARAMETER_KEY,
} from "../../../constants/signup.constants";
import { IAccountVerification } from "../../../models/account-verification.model";
import { SpinnerService } from "../../../services/spinner.service";
import { UserService } from "../../../services/user.service";
import { RecaptchaComponent } from "../recaptcha/recaptcha.component";
 
@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.less"],
})
export class VerifyEmailComponent implements AfterViewInit {
  @ViewChild("verificationCodeInput") verificationCodeInputRef: any;


  public verificationCodeLengthIsValid: boolean = false;

  public userEmail: string = "";

  public codeInputConfiguration = {
    allowNumbersOnly: false,
    length: CODE_LENGTH_VERIFICATION,
    isPasswordInput: false,
    disableAutoFocus: false,
    inputClass: "input-verify",
    containerClass: "verify-container",
  };

  private verificationCode!: string;
  debouncer: Subject<string> = new Subject();

  private verificationId!: string;

  private returnUrl!: string;

  public showSuccesfullMessage: boolean = false;

   @ViewChild("recaptcha")
  public recaptcha!: RecaptchaComponent; 

  gmailUrl: string = commonEnvironment.gmailUrl;
  outlookUrl: string = commonEnvironment.outlookUrl;
  requestSent: boolean = false;

  public timerDuration: number = 60;

  verifyAccountForm: FormGroup = new FormGroup({
    challenge: new FormControl(""),
  });

  constructor(
    private activateRoute: ActivatedRoute,
    private sithecCommonApiService: SithecCommonApiService,
    private toastrService:ToastrMessageService,
    private userService: UserService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    this.userEmail = userService.user.username;
  }

  public ngAfterViewInit(): void {
    this.activateRoute.queryParams.subscribe((parameters) => {
      this.returnUrl = parameters[RETURN_URL_PARAMETER_KEY];

      const tokenParameter = parameters[VERIFICATION_CODE_PARAMETER_KEY];

      if (
        tokenParameter &&
        tokenParameter.length === CODE_LENGTH_VERIFICATION
      ) {
        setTimeout(() => {
          this.verificationCodeInputRef.setValue(tokenParameter);
        }, 0);
      }

      this.verificationId = parameters[VERIFICATION_ID_PARAMETER_KEY];

      if (this.userService.hasVerifiedAccount()) {
        this.redirectToDashboard();
      }
    });

    this.debouncer.pipe(debounceTime(500)).subscribe((value) => {
      if (this.canSubmit()) {
        this.submit();
      }
    });
  }

  public onInputVerificationCodeChange(valInput: string): void {
    this.verificationCode = valInput;
    this.debouncer.next(valInput);
  }

  toggleDisable() {
    if (this.verificationCodeInputRef.otpForm) {
      this.verificationCodeInputRef.otpForm.disable();
    }
  }

  canSubmit(): boolean {
    return (this.verificationCodeLengthIsValid =
      !!this.verificationCode &&
      this.verificationCode.length === CODE_LENGTH_VERIFICATION);
  }

  public submit(): void {
    this.spinnerService.show();

    this.verificationId = !!this.verificationId
      ? this.verificationId
      : this.userService.user.accountVerification === null
      ? ""
      : this.userService.user.accountVerification.verificationId;

    const verificationRequest: any = {
      token: this.verificationCode,
      userId: this.userService.user.id,
      verificationId: this.verificationId,
    };
    this.sithecCommonApiService
      .verifyAccount(verificationRequest)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (response: IAccountVerification) => {
          this.userService.updateAccountVerification(response);
          this.showSuccesfullMessage = true;
        },
        (message) => this.handleError(message)
      );
  }

  public handleError({ message }: any): void {
    this
  }

  public executeRecaptcha(): void {
    this.spinnerService.show();
    this.recaptcha.execute().then(
      () => this.spinnerService.hide(),
      () => this.spinnerService.hide()
    );
  } 

  public resendEmailCode(): void {
    this.sithecCommonApiService
      .resendAccountVerification(this.verifyAccountForm.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (response: IAccountVerification) => {
          this.userService.updateAccountVerification(response);
          this.requestSent = true;
        },
        (message) => this.handleError(message)
      );
  }


  public returnVerifyEmail() {
    this.requestSent = false;
    this.showSuccesfullMessage = false;
  }

  public redirectToDashboard(): void {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }

  public redirectToGmail(): void {
    window.open(this.gmailUrl);
  }

  public redirectToOutlook(): void {
    window.open(this.outlookUrl);
  }
}
