<div class="contenido">
    <div class="contenedor-boton">
        <button styleSheetFile="../../../../../assets/styles/receipt-style.less" printTitle="Recibo de Compra" printSectionId="recibo" ngxPrint>Imprimir</button>
        <button (click)="regresar()">Regresar</button>
        <!-- <app-button
            class="full-width app-button-rounded app-button-secondary hover-button ajuste-pdf return-responsive"
            [label]="'Regresar' | translate"
            type="submit"
            (onClick)="regresar()"
        ></app-button> -->
    </div>
    <section class="recibo" id="recibo">
        <img src="../../../../../assets/images/encabezado.png" alt="">
        <section class="contenido-recibo">
            <section class="header-text">
                <div>
                    <p class="titulo titulo-recibo">Recibo</p>
                    <p># 2354973638</p>
                </div>
                <div class="header-right">
                    <p class="titulo">Contacto Sithec:</p>
                    <p>449 194 7838</p>
                </div>
            </section>
            <section class="informacion">
                <div class="informacion-left">
                    <div class="bloque-informacion">
                        <p class="titulo">Fecha</p>
                        <p>{{purchase.date.split('T')[0]}}</p>
                    </div>
                    <div class="bloque-informacion">
                        <p class="titulo">I.D. de cliente</p>
                        <p>{{purchase.userId}}</p>
                    </div>
                    <div class="bloque-informacion">
                        <p class="titulo">RFC</p>
                        <p>{{validarDatosFacturacion(purchase.user.rfc, purchase.user.rfc)}}</p>
                    </div>
                </div>
                <div class="informacion-right">
                    <div class="bloque-informacion">
                        <p class="titulo">Nombre de Cliente</p>
                        <p>{{purchase.user.name}} {{purchase.user.lastName}}</p>
                        <p>{{validarDatosFacturacion(purchase.user.calle+" "+purchase.user.numero_exterior+",", purchase.user.calle)}}</p>
                        <p>{{validarDatosFacturacion(purchase.user.colonia+", "+purchase.user.estado+",", purchase.user.calle)}}</p>
                        <p>{{validarDatosFacturacion(purchase.user.estado+" México", purchase.user.calle)}}</p>
                        <!-- <p>{{purchase.user.phone}}</p> -->
                    </div>
                    <div class="bloque-informacion">
                        <p class="titulo">PAGO:</p>
                        <div class="separacion-pago">
                            <p >
                                <span *ngIf="purchase.paymentMethod === 'credit'">
                                    Tarjeta de Crédito
                                </span>
                                <span *ngIf="purchase.paymentMethod === 'debit'">
                                    Tarjeta de Débito
                                </span>
                                {{checkCard(purchase.card)}}****{{purchase.card.substring(purchase.card.length-4)}}</p>
                            <p *ngIf="purchase.paymentMethod === 'credit'">{{purchase.bank}} pago a {{purchase.months}} meses</p>
                        </div>
                    </div>
                </div>
            </section>

            <div class="espaciado">
                <p class="titulo ajuste-titulo">Mi Compra:</p>
            </div>

            <table>
                <tr class="border-line">
                    <th><p class="titulo">Cant.</p></th>
                    <th><p class="titulo">Producto</p></th>
                    <th><p class="titulo">Precio Unit.</p></th>
                    <th><p class="titulo">Importe</p></th>
                </tr>
                <tr *ngFor="let detail of purchase.details; trackBy: trackDetails">
                    <td *ngIf="!detail.isInCombo"><p class="titulo">{{detail.quantity}}</p></td>
                    <td *ngIf="!detail.isInCombo && !detail.purchaseType" class="col-6 ajuste-nombres">
                        <p class="titulo">{{detail.name}}</p>
                         <p class="titulo" *ngIf="!detail.isInCombo && detail.metadata"> {{getmetadata(detail.metadata)}}</p>
                    </td>
                    <td *ngIf="!detail.isInCombo && detail.purchaseType" class="col-6 ajuste-nombres">
                        <p class="titulo">{{detail.name}} {{getLicenciamiento(detail.isMono)}}:</p>
                        <p class="titulo">{{detail.users}} Usuario(s) [{{detail.purchaseType}}]</p>
                        <p class="titulo" *ngIf="detail.license">Licencia: {{detail.license}} </p>
                    </td>
                    <td *ngIf="!detail.isInCombo"><p class="priceLeft">{{getPrecioUnitario(detail.unitPrice, detail.quantity, detail.users, detail.usersCost) | currency}}</p></td>
                    <td *ngIf="!detail.isInCombo"><p class="priceLeft">{{getImporte(detail.unitPrice, detail.quantity, detail.users, detail.usersCost) | currency}}</p></td>

                </tr>
                <tr *ngIf="checkCombo(purchase.details)">
                    <td><p class="titulo"> 1 </p></td>
                    <td><p class="titulo">Combo:</p></td>
                </tr>
                <tr *ngFor="let detail of purchase.details; trackBy: trackDetails">
                    <td *ngIf="detail.isInCombo"><p class="titulo"></p></td>
                    <td *ngIf="detail.isInCombo"><p class="combo">{{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                        <p class="combo">{{detail.users}} Usuario(s) [{{detail.purchaseType}}]</p>
                        <p class="combo" *ngIf="detail.license">Licencia: {{detail.license}} </p>
                    </td>
                    <td *ngIf="detail.isInCombo"><p class="priceLeft">{{getPrecioUnitario(detail.unitPrice, detail.quantity, detail.users, detail.usersCost) | currency}}</p></td>
                    <td *ngIf="detail.isInCombo"><p  class="priceLeft">{{getImporte(detail.unitPrice, detail.quantity, detail.users, detail.usersCost) | currency}} [-15%]</p></td>

                </tr>
            </table>
            <div class="payment-information">
                <div class="payment-field">
                    <div class="payment-text">
                        <p>Subtotal:</p>
                    </div>
                    <div class="payment-number">
                        <p class="bloque-precio">{{((purchase.total/1.16)- purchase.commission)+purchase.discount | currency}}</p>
                    </div>
                </div>
                <div class="payment-field">
                    <div class="payment-text">
                        <p>Descuento:</p>
                    </div>
                    <div class="payment-number">
                        <p class="bloque-precio">{{purchase.discount | currency}}</p>
                    </div>
                </div>
                <div class="payment-field" *ngIf="purchase.toMonths">
                    <div class="payment-text">
                        <p>Comisión: </p>
                    </div>
                    <div class="payment-number">
                        <p class="bloque-precio">{{purchase.commission| currency}}</p>
                    </div>
                </div>
                <div class="payment-field">
                    <div class="payment-text">
                        <p>IVA:</p>
                    </div>
                    <div class="payment-number">
                        <p class="bloque-precio">{{purchase.iva | currency}}</p>
                    </div>
                </div>
                <div class="payment-field">
                    <div class="payment-text">
                        <p class="titulo">TOTAL:</p>
                    </div>
                    <div class="payment-number">
                        <p class="bloque-precio titulo">{{purchase.total| currency}}</p>
                    </div>
                </div>
            </div>
            <p class="titulo">Contacto Sithec:</p>
            <p>449 194 7838</p>
            <p>www.sithec.com.mx</p>
            <br>
            <br>
            
            <!-- <div class="linea"></div>
            <div class="bloque-informacion">
                <p class="titulo">Saldo anterior:</p>
                <div class="separacion-pago">
                    <p>VISA****{{purchase.card.substring(purchase.card.length-4)}}</p>
                    <p class="bloque-precio">MXN {{purchase.total | currency}}</p>
                </div>
                <div class="separacion-pago salto">
                    <p class="titulo">Pago recibido:</p>
                    <p class="bloque-precio">MXN {{purchase.total | currency}}</p>
                </div>
            </div>
            <div class="linea"></div>
            <div class="separacion-pago">
                <p class="titulo">Saldo adeudado:</p>
                <p class="bloque-precio">MXN 0.00</p>
            </div> -->

            <!-- <div class="espaciado">
                <p class="titulo ajuste-titulo">Mi Compra:</p>
                <div class="separacion-pago">
                    <p class="titulo">Producto:</p>
                    <p class="bloque-precio titulo">Importe</p>
                </div>
            </div>
            <div class="linea"></div>
            <div class="detalles" *ngFor="let detail of purchase.details; trackBy: trackDetails">
                <div class="separacion-pago separacion-detalle">
                    <p>{{detail.quantity}} {{detail.name}} {{detail.isMono ? 'Mono-empresa':'Multi-empresa'}}</p>
                    <p class="bloque-precio">{{detail.unitPrice | currency}}</p>
                </div>
            </div>
            <div class="detalles-pago">
                <div class="separacion-pago separacion-detalle">
                    <p>Subtotal:</p>
                    <p class="bloque-precio">{{(purchase.total/(1.16))+purchase.discount| currency}}</p>
                </div>
                <div class="separacion-pago separacion-detalle">
                    <p>Descuento:</p>
                    <p class="bloque-precio">{{purchase.discount| currency}}</p>
                </div>
                <div class="separacion-pago separacion-detalle">
                    <p>IVA:</p>
                    <p class="bloque-precio">{{purchase.iva | currency}}</p>
                </div>
            </div>
            <div class="separacion-pago">
                <p class="titulo">TOTAL</p>
                <p class="bloque-precio titulo">MXN {{purchase.total| currency}}</p>
            </div> -->

        </section>
    </section>
    <!-- ../../../../../assets/styles/receipt-style.less -->
</div>
