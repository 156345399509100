import { Component, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AT_LEAST_ONE_NUMBER, AT_LEAST_ONE_CHARACTER_UPPERCASE, AT_LEAST_ONE_CHARACTER_LOWERCASE, AT_LEAST_ONE_SPECIAL_CHARACTER } from '../../../constants/passwordValidation.constants';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { IUpdatePasswordRequest } from '../../../models/update-password';
import { SpinnerService } from '../../../services/spinner.service';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component'; 
import { SithecCommonApiService } from '../../../services/sithec-common-api.service';
import { ToastrMessageService } from '../../../services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent {
  public hasParameterErrors: boolean = false;

  private verificationCode!: string;

  private verificationId!: string;

  public submitted = false;

  public requestSent:boolean = false;

  public CODE_PARAMETER_KEY: string = 'code';
  public ID_PARAMETER_KEY: string = 'id';

  public username: string = "";
  public usernameForm: string = "";
 
  @ViewChild('recaptcha')
  public recaptcha!: RecaptchaComponent;
  data: any;
  code: string = "";
  updatePasswordForm: FormGroup = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(AT_LEAST_ONE_NUMBER),
        Validators.pattern(AT_LEAST_ONE_CHARACTER_UPPERCASE),
        Validators.pattern(AT_LEAST_ONE_CHARACTER_LOWERCASE),
        Validators.pattern(AT_LEAST_ONE_SPECIAL_CHARACTER)
      ]),
      passwordConfirm: new FormControl('', Validators.required)
    },
    this.passwordMatchValidator
  );

  constructor(
    private sithecApiCommonService: SithecCommonApiService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService, 
    private toastrService: ToastrMessageService
  ) {
    let info = sessionStorage.getItem("resetPassword")
    if(info){
      const data = JSON.parse(info)
      //console.log(data)
      this.username=data.username;
    }
    info = sessionStorage.getItem("resetPhonePassword")
    if(info){
      const aux = JSON.parse(info)
      this.code = aux;
    }
    info = sessionStorage.getItem("usernamePR")
    if(info){
      const aux = JSON.parse(info)
      this.usernameForm = aux;
    }
  }

  public ngAfterViewInit(): void {
    this.activateRoute.queryParams.subscribe((parameters) => {
      if (parameters[this.CODE_PARAMETER_KEY] && parameters[this.ID_PARAMETER_KEY]) {
        this.verificationCode = parameters[this.CODE_PARAMETER_KEY];
        this.verificationId = parameters[this.ID_PARAMETER_KEY];
        this.hasParameterErrors = false;
      } else {
        setTimeout(() => {
          this.hasParameterErrors = true;
        });
      }
    });
  }

   /* public executeRecaptcha(): void {
    if (this.updatePasswordForm.invalid) {
      this.submitted = true;
      return;
    }
    this.submitted = false;
    this.spinnerService.show();
    this.recaptcha.execute().then(
      () => this.spinnerService.hide(),
      () => this.spinnerService.hide()
    );
  }  */

  private passwordMatchValidator(
    formGroup: AbstractControl
  ): ValidationErrors | null {
    const passwordConfirm = formGroup.get('passwordConfirm');

    if (formGroup.get('password')?.value === passwordConfirm?.value) {
      passwordConfirm?.setErrors(null);
      return null;
    }

    passwordConfirm?.setErrors({ mismatch: true });
    return { invalid: true };
  }

  public submit(): void {

    if(this.updatePasswordForm.invalid){
      this.submitted = true;
      if(this.updatePasswordForm.get('code')?.hasError('required') || this.updatePasswordForm.get('password')?.hasError('required') || this.updatePasswordForm.get('passwordConfirm')?.hasError('required')){
        this.toastrService.showError("Hay campos vacíos") 
      }
      else if(this.updatePasswordForm.get('password')?.value != this.updatePasswordForm.get('passwordConfirm')?.value){
        this.toastrService.showError("Las contraseñas no coinciden") 
      }
      else{
        this.toastrService.showError("Verifica que la contraseña contenga al menos una letra en mayúscula, una en minúscula, un número y un carácter")
      }
      return;
    }

    this.spinnerService.show();

     const updatePasswordRequest: IUpdatePasswordRequest = {
      username: this.usernameForm,
      code: this.code,
      newPassword: this.updatePasswordForm.value.password
    };
    this.sithecApiCommonService
      .updatePassword(updatePasswordRequest)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        () => this.redirectToSuccess(),
        (errorDefinition:IErrorDefinition) => {
          this.handleError(errorDefinition);
        }
      ); 
  }

  public handleError({ message }: IErrorDefinition): void {
   this.toastrService.showError((message))
  }

  public redirectToSuccess(): void {
    this.toastrService.showSuccess("Contraseña Reestablecida Éxitosamente")
    sessionStorage.removeItem("resetPassword")
    this.router.navigate(['/account/success-reset']);
  }

  
  public redirectToLogin(): void {
    this.requestSent = false;
    this.router.navigate(['/account/authentication']);
  }
  /* 
  this.sithecApiService
      .resetPassword(this.forgotPasswordForm.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        () => {
          this.requestSent = true;
          
        },
        (errorDefinition) => {
          this.handleError(errorDefinition);
        }
      );
    */
  isEmailOrPhone(value: string): boolean {
    let decition: boolean = false;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{10}$/;
  
    const isValidEmail = emailPattern.test(value);
    const isValidPhone = phonePattern.test(value);
    
    if(isValidEmail){
      decition = false;
    }
    else if(isValidPhone){
      decition = true;
    }
    return decition;
  }
}
