<div class="sign-up-container">
    <form [formGroup]="signUpForm" (ngSubmit)="submit()" novalidate>
      <form-input
        name="name"
        label="{{ 'AUTH.USER_NAME' | translate }} *"
        placeholder="AUTH.USER_NAME_PLACEHOLDER"
        formControlName="name"
        [control]="signUpForm.controls['name']"
        [submitted]="submitted"
      >
      </form-input>
  
      <form-input
        name="lastName"
        label="{{ 'AUTH.USER_LAST_NAME' | translate }} *"
        placeholder="AUTH.LAST_NAME_PLACEHOLDER"
        formControlName="lastName"
        [control]="signUpForm.controls['lastName']"
        [submitted]="submitted"
      >
      </form-input>
  
      <form-input
        name="email"
        label="{{ 'AUTH.USER_EMAIL' | translate }} *"
        placeholder="AUTH.USER_EMAIL_PLACEHOLDER"
        type="email"
        formControlName="email"
        [control]="signUpForm.controls['email']"
        [submitted]="submitted"
      >
      </form-input>

      <form-input
        name="email"
        label="Confirmar correo *"
        placeholder="Confirma tu correo"
        type="email"
        formControlName="emailConfirm"
        [control]="signUpForm.controls['emailConfirm']"
        [submitted]="submitted"
      >
      </form-input>
  
      <!-- <form-input
        name="email"
        label="{{ 'AUTH.USER_PHONE' | translate }} *"
        placeholder="AUTH.USER_PHONE_PLACEHOLDER"
        type="phone"
        formControlName="phone"
        [control]="signUpForm.controls['phone']"
        [submitted]="submitted"
      >
      </form-input> -->
      <!-- Nueva versión -->
      <form-input
        name="email"
        label="No. Celular *"
        placeholder="AUTH.USER_PHONE_PLACEHOLDER"
        type="number"
        formControlName="phone"
        [control]="signUpForm.controls['phone']"
        [submitted]="submitted"
      >
      </form-input> 

      <form-switch rightLabel="Deseo facturar" (onChangeElement)="solicitarDatosFactura($event)"></form-switch>
      
      <div [ngClass]="{'desplegable': !solicitarDatos}" class="contenedor-desplegable">
        <form-input
          name="rfc"
          label="{{ 'RFC*' | translate }}"
          placeholder="RFC"
          type="text"
          formControlName="rfc"
          [control]="signUpForm.controls['rfc']"
          [submitted]="submitted"
          [uppercase]="true"
        >
        </form-input> 
        <form-input
          name="razon"
          label="{{ 'Razón Social*' | translate }}"
          placeholder="Razón social"
          type="text"
          formControlName="razon"
          [control]="signUpForm.controls['razon']"
          [submitted]="submitted"
          [uppercase]="true"
        >
        </form-input> 

        <form-input
          name="cp"
          label="{{ 'Código Postal*' | translate }}"
          placeholder="Escribe tu código postal"
          type="number"
          formControlName="cp"
          [control]="signUpForm.controls['cp']"
          [submitted]="submitted"
        >
        </form-input> 

        <form-input
          name="calle"
          label="{{ 'Calle' | translate }}"
          placeholder="Escribe tu calle"
          type="text"
          formControlName="calle"
          [control]="signUpForm.controls['calle']"
          [submitted]="submitted"
        >
        </form-input> 

        <div class="numeros">
          <form-input
            name="numero_exterior"
            label="{{ 'No. exterior' | translate }}"
            placeholder="Escribe tu numero exterior"
            type="number"
            formControlName="numero_exterior"
            [control]="signUpForm.controls['numero_exterior']"
            [submitted]="submitted"
            class="size-adjustment"
          >
          </form-input> 

          <form-input
            name="numero_interior"
            label="{{ 'No. interior' | translate }}"
            placeholder="Escribe tu numero interior"
            type="text"
            formControlName="numero_interior"
            [control]="signUpForm.controls['numero_interior']"
            [submitted]="submitted"
            class="size-adjustment"
          >
          </form-input> 
        </div>

        <form-input
          name="colonia"
          label="{{ 'Colonia' | translate }}"
          placeholder="Escribe tu colonia"
          type="text"
          formControlName="colonia"
          [control]="signUpForm.controls['colonia']"
          [submitted]="submitted"
        >
        </form-input> 

        <form-input
          name="municipio"
          label="{{ 'Municipio' | translate }}"
          placeholder="Escribe tu municipio"
          type="text"
          formControlName="municipio"
          [control]="signUpForm.controls['municipio']"
          [submitted]="submitted"
        >
        </form-input> 

        <form-input
          name="estado"
          label="{{ 'Estado' | translate }}"
          placeholder="Escribe tu estado"
          type="text"
          formControlName="estado"
          [control]="signUpForm.controls['estado']"
          [submitted]="submitted"
        >
        </form-input> 
        
        <div class="form-fiscal">
          <label class="form-label">Régimen Fiscal</label>
          <div class="selectores-persona"> 
            <div class="selector-persona">
              <input type="radio" value="Persona Física" name="persona" formControlName="persona" (change)="cambiarSeleccionRazonSocial($event)">
              <label class="subtitle">Persona Física</label>
            </div>
            <div class="selector-persona">
              <input type="radio" value="Persona Moral" name="persona" formControlName="persona" (change)="cambiarSeleccionRazonSocial($event)">
              <label class="subtitle">Persona Moral</label>
            </div>
          </div>
        </div>
        
        <div [ngClass]="{'ocultar': personaFisica}">
          <select class="form-select" name="regimen" formControlName="regimen" >
            <option value="General de Ley Personas Morales" selected>601 - General de Ley Personas Morales</option>
            <option value="Personas Morales con Fines no Lucrativos">603 - Personas Morales con Fines no Lucrativos</option>
            <option value="Régimen de Enajenación o Adquisición de Bienes">607 - Régimen de Enajenación o Adquisición de Bienes</option>
            <option value="Consolidación">609 - Consolidación</option>
            <option value="Sociedades Cooperativas de Producción que optan por diferir sus ingresos">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
            <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
            <option value="Opcional para Grupos de Sociedades">623 - Opcional para Grupos de Sociedades</option>
            <option value="Coordinados">624 - Coordinados</option>
            <option value="Hidrocarburos">628 - Hidrocarburos</option>
          </select>
        </div>
        
        <div [ngClass]="{'ocultar': !personaFisica}">
          <select class="form-select" name="regimen" formControlName="regimen">
            <option value="Sueldos y Salarios e Ingresos Asimilados a Salarios" selected>605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
            <option value="Arrendamiento">606 - Arrendamiento</option>
            <option value="Demás ingresos">608 - Demás ingresos</option>
            <option value="Ingresos por Dividendos (socios y accionistas)">611 - Ingresos por Dividendos (socios y accionistas)</option>
            <option value="Personas Físicas con Actividades Empresariales y Profesionales">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
            <option value="Ingresos por intereses">614 - Ingresos por intereses</option>
            <option value="Regimen de los ingresos por obtención de premios">615 - Regimen de los ingresos por obtención de premios</option>
            <option value="Sin obligaciones fiscales">616 - Sin obligaciones fiscales</option>
            <option value="Incorporación Fiscal">621 - Incorporación Fiscal</option>
            <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
            <option value="De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
            <option value="Enajenación de acciones en bolsa de valores">630 - Enajenación de acciones en bolsa de valores</option>
            <option value="Residentes en el Extranjero sin Establecimiento Permanente en México">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
            <option value="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
            <option value="Régimen Simplificado de Confianza">626 - Régimen Simplificado de Confianza</option>
          </select>
        </div>
      </div>


      <form-input-password
        name="password"
        label="{{ 'AUTH.USER_PASSWORD' | translate }} *"
        placeholder="AUTH.USER_PASSWORD_PLACEHOLDER"
        type="password"
        formControlName="password"
        [control]="signUpForm.controls['password']"
        [submitted]="submitted"
      >
      </form-input-password>
  
      <form-input-password
        name="passwordConfirm"
        label="{{ 'AUTH.CONFIRM_PASSWORD' | translate }} *"
        placeholder="AUTH.CONFIRM_PASSWORD_PLACEHOLDER"
        type="password"
        formControlName="passwordConfirm"
        [control]="signUpForm.controls['passwordConfirm']"
        [submitted]="submitted"
      >
      </form-input-password>
  
      <div class="d-flex flex-column mt-5">
        <app-button
          class="full-width w-80 d-flex justify-content-center"
          [label]="'AUTH.SIGN_UP_SUBMIT'"
          type="submit"
        >
        </app-button>
      </div>
    </form>
    <div class="options-redirect my-5">
        <a class="a-button" href="javascript:void(0)"  (click)="this.showLogin.emit()">
          <p>{{ "AUTH.LOGIN" | translate }}</p>
        </a>

      </div>
  </div>