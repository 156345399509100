<div class="contenido">
    <div  class="recuadro">
        <h1>Política de garantía de servicios TI Sithec</h1>
        <h2>Proceso de solicitud de garantía</h2>
        <ul>
            <li>
                <p>Se contactará al equipo de soporte por cualquier medio autorizado</p>
            </li>
            <li>
                <p>El cliente deberá reportar el número de ticket del cual desea hacer valida su garantía.</p>
            </li>
            <li>
                <p>El área de soporte validará que aplique la garantía.</p>
            </li>
            <li>
                <p>En caso que si aplique la garantía, se agendará una cita de servicio para resolver el problema.</p>
            </li>
            <li>
                <p>En caso de que no aplique, se le explicará al cliente el motivo del rechazo de su solicitud.</p>
            </li>
        </ul>
        <h2>Condiciones</h2>
        <ul>
            <li>
                <p>El cliente contará con 3 días hábiles para solicitar garantía específicamente sobre lo realizado en el ticket que se reportó.</p>
            </li>
            <li>
                <p>Para solicitar garantía, se tendrá que haber liquidado completamente la factura realizada por el servicio reportado o tener un paquete de horas vigente.</p>
            </li>
            <li>
                <p>Cumplir con los requerimientos mínimos de hardware y software para el correcto funcionamiento del sistema</p>
            </li>
            <li>
                <p>La garantía no será válida en caso de factores externos, tales como subidas o bajadas de tensión, utilización de software inadecuados o prohibidos por los fabricantes, las caídas, agua, fuego o manejo incorrecto o abusivo por parte del cliente o por terceras personas no autorizadas del equipo de cómputo.</p>
            </li>
        </ul>
        <p>*Aplican restricciones</p>
    </div>
</div> 