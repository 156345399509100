import { Component, HostListener, OnInit } from '@angular/core';
import { IBanner } from '../../../models/banner.model';
import { IErrorDefinition } from '../../../models/error-definition.model';
import { SpinnerService } from '../../../services/spinner.service';
import { finalize } from 'rxjs/operators';
import { AdminApiServiceService } from 'src/app/services/admin-api-service.service';
import { PlatformUtil } from 'src/app/models/platformUtil';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  bannersData : IBanner[] = []
  filteredBanners: IBanner[] = [];
  filteredBanners2: IBanner[] = [];
  isMobileView: boolean = false;
  currentIndex = 0;
  public isPaused: boolean = false;
  private intervalId: any;
  constructor(
    private apiService:AdminApiServiceService,
    private spinnerService: SpinnerService,
    private platformUtil: PlatformUtil
  ) { }

  ngOnInit(): void {
    this.getBanners();
    this.startCarousel();
    this.checkViewport();
    //console.log("--> header")
  }
  
  startCarousel(): void {
    if(this.platformUtil.isBrowser())
      this.intervalId = setInterval(() => {
        this.nextCarousel();
      }, 3000); // Cambiar de imagen cada 3 segundos
  }
  // options: string[] = ['Angular', 'React', 'Vue'];

  // onOptionSelected(option: string) {
  //   //console.log('Option selected:', option);
  //   Puedes realizar acciones adicionales aquí, como completar el input principal.
  // }

  getBanners(){
    //console.log("Inicio de getBanners")
    this.apiService
      .getBanners(0,0,"",1)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (banners: any) => {
          if(banners){
            //console.log("banners",banners)
            this.bannersData=banners.results;
            this.filterBannersByDate();
            
          }
        },
        ({ message }: IErrorDefinition) => {
          // this.toastrService.showError(message)
        }
      );
  }

  filterBannersByDate() {
    let currentDate = new Date();
    this.filteredBanners = this.bannersData.filter(banner => {
      const initialDate = new Date(banner.initialDate);
      const expirationDate = new Date(banner.expirationDate);
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

      // Dar formato a la fecha actual
      const formattedCurrentDate: string = currentDate.toLocaleDateString('en-US', options);

      // Dar formato a la fecha de inicio
      const formattedStartDate: string = initialDate.toLocaleDateString('en-US', options);

      // Dar formato a la fecha de término
      const formattedEndDate: string = expirationDate.toLocaleDateString('en-US', options)
    return (formattedStartDate <= formattedCurrentDate && formattedEndDate >= formattedCurrentDate) || 
    (formattedStartDate <= formattedCurrentDate && banner.expirationDate == null) || 
    (formattedStartDate <= formattedCurrentDate && formattedCurrentDate == formattedEndDate);
    });
    
    this.filterBannersByActive();
    /*
    if(this.filteredBanners.length>0){
      this.filteredBanners.forEach(item=>{
        let urlImage = item.urlImage?.split("/")?.reverse()[0];
        if(urlImage){
          this.getImage(urlImage,item.urlRedirect)
        }
      })
    }*/
  }
  
  filterBannersByActive() {
    this.filteredBanners2 = this.filteredBanners.filter(banner =>!banner.paused);
    if(this.filteredBanners2.length>0){
      this.filteredBanners2.forEach(item=>{
        if(item.urlImage && item.urlImageMobile)
          this.imageURL.push({
            urlImage: item.urlImage,
            urlImageMobile: item.urlImageMobile,
            urlRedirect: item.urlRedirect,
          });
      })
    }
  }

  imageURL: any[] = [];
  // getImages(urlImage: string, urlImageMobile: string, urlRedirect: string) {
  //   const productInfo = {
  //     objectKey: urlImage,
  //     subDirectory: "banners",
  //     duration: 30,
  //   };
  //   const productInfoMobile = {
  //     objectKey: urlImageMobile,
  //     subDirectory: "bannersMobile",
  //     duration: 30,
  //   };
  
  //   const imagenWeb$ = this.apiService.getPublicImage(productInfo);
  //   const imagenMobile$ = this.apiService.getPublicImage(productInfoMobile);
  
  //   forkJoin([imagenWeb$, imagenMobile$]).subscribe(
  //     ([responseWeb, responseMobile]: [any, any]) => {
  //       const imagenWeb = responseWeb?.result || '';
  //       const imagenMobile = responseMobile?.result || '';
  
  //       // Ejecutar cuando ambas peticiones se resuelvan
  //       this.imageURL.push({
  //         urlImage: imagenWeb,
  //         urlImageMobile: imagenMobile,
  //         urlRedirect: urlRedirect,
  //       });
  //     },
  //     (error) => {
  //       // Manejo de errores
  //       // this.toastr.error(error.message, 'Error');
  //       // this.toastrService.showError(error.message);
  //     }
  //   );
  // }

  nextCarousel(): void {
    this.currentIndex = (this.currentIndex + 1) % this.imageURL.length;
  }

  prevCarousel(): void {
    this.currentIndex = (this.currentIndex - 1 + this.imageURL.length) % this.imageURL.length;
  }


  redirectTo(urlRedirect: string){
    if(urlRedirect)
      window.open(urlRedirect, '_blank');
  }
  toggleCarousel(): void {
    if (this.isPaused) {
      this.startCarousel();
      //console.log(this.isPaused)
    } else {
      this.clearCarouselInterval();
    }
    this.isPaused = !this.isPaused;
    
  }

  clearCarouselInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.checkViewport();
  }
  checkViewport() {
    if(this.platformUtil.isBrowser())
      this.isMobileView = window.innerWidth <= 768;
  }
}
