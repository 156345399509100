import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs";
import { LOCALE } from "../constants/locale.constants";
import { IAuthenticationResponse } from "../models/authentication-response.model";
import { IUpdate } from "../models/update.model";
import { SessionService } from "./session.service";
import { UserService } from "./user.service";
import { DataDogService } from "./datadog.service";

@Injectable({
  providedIn: "root",
})
export class SithecBaseApiService {
  protected readonly jsonHeaderValue: string = "application/json";

  private readonly contentTypeKey: string = "content-type";
  private readonly localeKey: string = "locale";
  private readonly acceptHeaderKey: string = "accept";
  private readonly authorizationHeaderKey: string = "Authorization";
  private readonly bearerKey: string = "Bearer";
  private readonly requestorKey: string = "requestor";
  private tokenRecaptcha: string = "";

  constructor(
    @Inject("sourceRequestor") protected sourceRequestor: string,
    protected userService: UserService,
    protected sessionService: SessionService,
    private translateService: TranslateService,
    private router: Router,
    protected dd: DataDogService
  ) {}

  public handleLogoutResponse(): void {
    this.sessionService.clearSession();
    this.userService.setUser(UserService.nullUser);
  }

  protected handleError(response: HttpErrorResponse) {
    if (response.status === 0 || response.status === 401) {
      this.handleLogoutResponse();
      this.navigateToLogin();
    }

    return throwError(response.error);
  }

  protected handleAuthenticationResponse({
    user,
    session,
  }: IAuthenticationResponse) {
    this.dd.setUserSession(user.id,user.contactEmail || '', user.name)
    this.userService.setUser(user);
    this.sessionService.setSession(session);
  }

  protected handleUpdateResponse({user}: IUpdate) {
    this.userService.setUser(user);
  }

  protected getHeaders(): HttpHeaders { 
    const locale: string = this.getSelectedLocale(),
      headers = this.sessionService.hasValidSession
        ? new HttpHeaders()
            .set(this.contentTypeKey, this.jsonHeaderValue)
            .append(this.acceptHeaderKey, this.jsonHeaderValue)
            .append(this.localeKey, locale)
            .append(
              this.authorizationHeaderKey,
              `${this.bearerKey} ${this.sessionService.token}`
            )
        : new HttpHeaders()
            .set(this.contentTypeKey, this.jsonHeaderValue)
            .append(this.localeKey, locale)
            .append(this.acceptHeaderKey, this.jsonHeaderValue)
            .append(this.requestorKey, this.sourceRequestor);

    return headers;
  }

  protected getFormHeaders(isAuthenticated: boolean): HttpHeaders {
    const locale: string = this.getSelectedLocale(),
      headers = this.sessionService.hasValidSession
        ? new HttpHeaders()
            .append(this.acceptHeaderKey, this.jsonHeaderValue)
            .append(this.localeKey, locale)
            .append(
              this.authorizationHeaderKey,
              `${this.bearerKey} ${this.sessionService.token}`
            )
        : new HttpHeaders()
            .set(this.contentTypeKey, this.jsonHeaderValue)
            .append(this.localeKey, locale)
            .append(this.acceptHeaderKey, this.jsonHeaderValue);
    return headers;
  }

  private getSelectedLocale(): string {
    return LOCALE.ES.SERVER;
    // switch (this.translateService.currentLang) {
    //   default:
    //   case LOCALE.ES.CLIENT:
    //     return LOCALE.ES.SERVER;
    //   case LOCALE.EN.CLIENT:
    //     return LOCALE.EN.SERVER;
    // }
  }

  private navigateToLogin(): void {
    this.router.navigate(["/account/authentication"]);
  }
}
