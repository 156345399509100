import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
  Output,
  EventEmitter
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormOptionComponent } from '../form-option/form-option.component';


@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true
    }
  ]
})
export class FormSelectComponent implements ControlValueAccessor {
  public value: any = null;
  @ContentChildren(FormOptionComponent)
  options!: QueryList<FormOptionComponent>;
  @Input() label = '';
  @Input() placeholder = '';
  @Input('class') className = '';
  @Input() control!:AbstractControl;
  @Input() submitted: boolean = false;
  @Output() onChangeElement: EventEmitter<string> = new EventEmitter<string>();

  constructor(private cdr:ChangeDetectorRef){

  }
  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }
  public onChange(val: any): void {}
  public onTouched(): void {}

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(event: any): void {
    this.value = event.target.value;
    this.onChange(event.target.value);
    this.onTouched();
    this.onChangeElement.emit("hola");
  }

  get errorClass() {
    return this.hasErrorInput()
      ? `is-invalid-input`
      : '';
  }

  hasErrorInput():boolean {
    return (
      (this.submitted && this.control?.invalid) ||
      (this.control &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }
}