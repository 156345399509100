import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IAuthenticationResponse } from "../../../models/authentication-response.model";
import { ToastrMessageService } from "../../../services/message.service";
import { SithecCommonApiService } from "../../../services/sithec-common-api.service";
import { SpinnerService } from "../../../services/spinner.service";
import { UserService } from "../../../services/user.service";
import { MessageService } from "primeng/api";
import { finalize } from "rxjs/operators";
import { SessionService } from "../../../services/session.service";
import Swal from "sweetalert2";

@Component({
  selector: "lib-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.less"],
})
export class LoginComponent {
  public submitted = false;
  public validar = false;
  admin:boolean = false;

  @Output() showSignUp: EventEmitter<any> = new EventEmitter<any>();

  loginForm: FormGroup = new FormGroup({
    username: new FormControl("", [Validators.required, this.emailOrPhoneValidator()]),
    password: new FormControl("", Validators.required),
  });

  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  phonePattern = /^\d{10}$/;

  private returnUrl: string | null = null;

  constructor(
    @Inject("sourceRequestor") protected sourceRequestorSource: string,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private sithecCommonApiService: SithecCommonApiService,
    private spinnerService: SpinnerService,
    private messageService: ToastrMessageService,
    private userService: UserService,
    private session: SessionService
  ) {
    /* if (userService.isAuthenticated()) {
      this.handleSuccessfulLogin();
    } */
    this.returnUrl = session.getURLSession();
    /* session.setURLSession(""); */
    if(this.sourceRequestorSource=="admin"){
      this.admin=true
    }else{
      this.admin=false
    }
  }

  public ngAfterViewInit(): void {}

  submit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loginForm.value.scope = this.sourceRequestorSource;
    this.submitted = false;
    this.spinnerService.show();

    this.sithecCommonApiService
      .login(this.loginForm.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (response: IAuthenticationResponse) => {
          this.handleSuccessfulLogin();
          localStorage.removeItem("empresa")
          this.messageService.showSuccess("Se inicio sesión");
        },
        ({message}) => {
          this.validar = true;
          this.messageService.showError(message);
          if(this.admin){
            Swal.fire(
              'Verifique sus credenciales',
              'Credenciales inválidas',
              'error'
            )
          }
        }
      );
  }

  private handleSuccessfulLogin(): void {
    this.session.setURLSession("");
    /* //console.log(this.returnUrl) */
    const url = !!this.returnUrl ? decodeURI(this.returnUrl) : "/landing";
    this.router.navigateByUrl(url);
  }

  public redirectForgotPassword(): void {
    this.session.setURLSession("");
    this.router.navigate(["/account/forgot-password"]);
  }
  emailOrPhoneValidator(): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
  
      const isValidEmail = this.emailPattern.test(value);
      const isValidPhone = this.phonePattern.test(value);
  
      if (isValidEmail || isValidPhone) {
        return null;  // Es válido
      } else {
        return { emailOrPhone: true };  // No es válido
      }
    };
  }
}
