import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToastrMessageService } from "../../../../services/message.service";
import {
  PricesService,
  ObjTotal,
  PriceData,
} from "../../../../services/prices.service";
import { SessionService } from "../../../../services/session.service";

@Component({
  selector: "app-cart-operations",
  templateUrl: "./cart-operations.component.html",
  styleUrls: ["./cart-operations.component.less"],
})
export class CartOperationsComponent implements OnInit {
  @Input() productsCart!: any[];
  @Output() onNextStep: EventEmitter<any> = new EventEmitter();
  @Output() sendInfo: EventEmitter<any> = new EventEmitter();
  @Input() confirmAction: boolean = false;

  ngOnChanges(): void {
    if (this.confirmAction) {
      this.confirm();
    }
  }
  objTotal: ObjTotal = {
    _discount: 0,
    _iva: 0,
    _subtotal: 0,
  };

  total: number = 0;
  subtotal: number = 0;
  iva: number = 0;
  discount: number = 0;
  boolDiscount: boolean = false;
  boolService: boolean = false;
  boolUser: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: ToastrMessageService,
    private priceService: PricesService,
    private router: Router,
    private session: SessionService
  ) {}

  ngOnInit(): void {
    let auxUsr = JSON.parse(localStorage.getItem("user") || "{}");
    this.boolUser = auxUsr && auxUsr.username;

    this.getTotal();

    this.priceService.getObjTotal$().subscribe((res: ObjTotal) => {
      this.boolService = true;

      this.objTotal._discount = res._discount;
      this.objTotal._iva = res._iva;
      this.objTotal._subtotal = res._subtotal;
      this.showDiscountSwitch()
      this.refreshTotal();
    });
  }

  applyDiscount() {
    this.boolDiscount = !this.boolDiscount;
    if (this.boolDiscount) {
      this.messageService.showInfo(
        "Al comprar en suite (con el 15% de descuento) todos tus sistemas serán registrados y activados al mismo RFC"
      );
    }

    this.refreshTotal();
  }

  getTotal() {
    this.productsCart.forEach((product: PriceData) => {
      /* this.subtotal += product._cost * product._quantity + product._users * 150; */
      this.subtotal += (product._cost + (product._users-1) * product._aditionalCost)*product._quantity;
    });

    //this.discount = 0;
    this.iva = this.subtotal * 0.16;
    this.total = this.subtotal + this.iva;

    
    this.priceService.updateTotal(this.total);
  }

  restoreValues() {
    this.iva = 0;
    this.discount = 0;
    this.total = 0;
    this.subtotal = 0;
  }

  refreshTotal() {
    if (this.boolDiscount) {
      if (this.boolService) {
        this.discount = this.objTotal._discount;
        /* this.subtotal = this.objTotal._subtotal - this.objTotal._discount; */
        this.subtotal = this.objTotal._subtotal;
        this.iva = (this.subtotal 
          - this.objTotal._discount
        )  * 0.16;
      } else {
        this.restoreValues();
        this.getTotal();
        this.discount = this.subtotal * 0.15;
        this.iva = this.subtotal * 0.16;
      }
      this.total = this.iva + (this.subtotal - this.objTotal._discount);
     
    } else {
      if (!this.boolService) {
        this.restoreValues();
        this.getTotal();
      } else {
        this.discount = this.objTotal._discount;
        this.subtotal = this.objTotal._subtotal;
        this.iva = this.objTotal._iva;
        this.total = this.iva + this.subtotal-this.discount;
        
      }
    }

    this.priceService.updateTotal(this.total);
  }

  showDiscountSwitch() {
    if (this.priceService.productsLength <= 1) {
      this.boolDiscount = false;
      return false;
    }
    this.boolDiscount = true
    return true;
  }

  confirm() {
    if (this.boolUser) {
      this.sendingInfo();

      this.onNextStep.emit();
    } else {
      localStorage.setItem("cart", "1")
      this.session.setURLSession("cart");
      this.router.navigate(["account/authentication"]);
    }
  }

  informacionDescuento(){
    this.messageService.showInfo("15% de descuento en la compra de dos o más licencias CONTPAQi activadas al mismo RFC*");
  }
  sendingInfo(){
    var info = JSON.stringify({
      subtotal: this.subtotal,
      iva: this.iva,
      discount:this.discount,
      total:this.total
    })
    localStorage.setItem("infoPago",info);
  }
}
