import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { SithecTranslateModule } from '../translate/sithec-translate.module';
import { ControlsModule } from '../controls/controls.module';
import { LandingRoutingModule } from './landing-module.routing';
import { ComponentsComponent } from './components/components.component';


@NgModule({
  declarations: [
    LandingComponent,
    ComponentsComponent
  ],
  imports: [
    CommonModule,
    ControlsModule,
    CommonComponentsModule,
    PrimeNgModule,
    FormsModule,
    SithecTranslateModule,
    ReactiveFormsModule,
    LandingRoutingModule,
  ],
  exports:[
    CommonComponentsModule
  ]
})
export class LandingModule { }
