import {
  Component,
  HostListener,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  Inject,
  OnInit
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { IUser } from "../../../models/user.model";
import { UserService } from "../../../services/user.service";
import { ShoppingCarServiceService } from "../../../services/shopping-car-service.service";
import { MenuItem, PrimeNGConfig } from "primeng/api";
import { SithecBaseApiService } from "../../../services/sithec-base-api.service";
import { SithecCommonApiService } from "../../../services/sithec-common-api.service";
import { finalize } from "rxjs/operators";
import { SessionService } from "../../../services/session.service";
import { SpinnerService } from "../../../services/spinner.service";
import { IErrorDefinition } from "../../../models/error-definition.model";
import { ICardInformationConfiguration } from "../../../models/product.model";
import { AdminApiServiceService } from "src/app/services/admin-api-service.service";
import { PlatformUtil } from "src/app/models/platformUtil";
import Swal from "sweetalert2";
import { PricesService, PriceData } from "src/app/services/prices.service";

@Component({
  selector: "nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.less"],
})
export class NavBarComponent implements OnInit {
  @ViewChild("cartIcon", { read: ElementRef, static: false }) cartEl:
    | ElementRef
    | undefined;

  @Output() onLogout: EventEmitter<any> = new EventEmitter();
  @Input() admin!: boolean;

  items!: MenuItem[];
  public user!: IUser;

  adminName = ""
  products: any[] = [];
  combo: any[] = [];
  cartCount:number = 0;
  emptyComboBool:boolean = false;
  boolVisibleSideBar: boolean = false;
  boolVisibleSelection: boolean = false;
  public lengthItems: number = 0;
  public productToBuild: any[] = [];
  iconCartEl: any;
  emptyCartBool: boolean = false;
  @Inject("sourceRequestor") protected sourceRequestorSource!: string 
  constructor(
    private sithecApiService: SithecCommonApiService,
    private sithecApiBaseService: SithecBaseApiService,
    private spinnerService: SpinnerService,
    private sessionService: SessionService,
    private router: Router,
    private userService: UserService,
    private shoppingCar: ShoppingCarServiceService,
    private primengConfig: PrimeNGConfig,
    private apiService:AdminApiServiceService,
    private platformUtil: PlatformUtil,
    private localStorageService: ShoppingCarServiceService,
    private priceService: PricesService
  ) {
    
    userService.getUserObservable().subscribe((user) => {
      this.user = user;
      if(this.platformUtil.isBrowser()){
        let aux = localStorage.getItem('user')
        if(user === null)
         if(aux)
         this.user = JSON.parse(aux)
      }
    });
    let info:any[] = []
    shoppingCar.getCarAsObservable().subscribe((item) => {
      info.push(item);
    });
    this.combo = [[]]
    this.products = [[]]
    info[0].forEach((product:ICardInformationConfiguration) => {
      if(product.isInCombo){
        this.combo[0].push(product);
      }else{
        this.products[0].push(product);
      }
    });
    this.checkComboIsEmpty();
    if(this.emptyComboBool){
      this.cartCount = this.products[0].length;
    }
    else{
      this.cartCount = this.products[0].length + 1;
    }
    //console.log(this.combo)
    //console.log("Nav--this.products",this.products)
    shoppingCar.getCarAsObservable().subscribe((item) => {
      this.lengthItems = 0;
      if (item.length > 0) {
        this.productToBuild = item;
        item.forEach((res) => {
          this.lengthItems = this.lengthItems + res.quantity;
          this.addAnimation();
          info.push(res)
        });
      } else {
        this.productToBuild = [];
        this.lengthItems = 0;
      }
    });

    if(this.sourceRequestorSource=="admin"){
      this.admin=true
    }else{
      this.admin=false
    }
  }
  checkComboIsEmpty() {
    this.combo[0].length == 0
      ? (this.emptyComboBool = true)
      : (this.emptyComboBool = false);
  }
  iniciar(){
    //console.log("Esto no se debió de haber ejecutado")
    this.boolVisibleSelection = false;
    this.spinnerService.show();
    this.spinnerService.hide();
    this.navigateToLogin();
  }
  salir(){
    this.boolVisibleSelection = false;
    this.spinnerService.show();
    this.sithecApiService
      .logout()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(() => {
        this.sessionService.clearSession();
        this.onLogout.emit();
        this.navigateToLogin();
      });
    this.sessionService.clearSession();
    this.sithecApiBaseService.handleLogoutResponse();
    this.spinnerService.hide();
    this.navigateToLogin();
  }
  private navigateToLogin(): void {
    this.router.navigate(["/account/authentication"]);
  }
  addAnimation() {
    if (this.cartEl) {
      this.cartEl.nativeElement.classList.add("animate-addCart");
      setTimeout(() => {
        if (this.cartEl)
          this.cartEl.nativeElement.classList.remove("animate-addCart");
      }, 500);
    }
  }

  ngOnInit() {
    //console.log(this.userService.user.name)
    if(this.userService.user.name != ""){
      if(this.platformUtil.isBrowser())
        localStorage.setItem('nombre', this.userService.user.name + " " + this.userService.user.lastName)
      this.adminName = this.userService.user.name + " " + this.userService.user.lastName
    }
    if(this.platformUtil.isBrowser())
      if(localStorage.getItem('nombre')){
        this.adminName = localStorage.getItem('nombre')!
      }
    this.primengConfig.ripple = true;
    this.onWindowScroll();

    this.items = [];

    //console.log(this.userService.user.name)
    if(this.userService.user.name != ""){
      if(this.platformUtil.isBrowser())
        localStorage.setItem('nombre', this.userService.user.name + " " + this.userService.user.lastName)
      this.adminName = this.userService.user.name + " " + this.userService.user.lastName
    }

    this.fnGetProductsAvailable();
    this.getProducts();

    // Actualización de carrito en tiempo real
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let info:any[] = []
        this.shoppingCar.getCarAsObservable().subscribe((item) => {
          info.push(item);
        });
        this.combo = [[]]
        this.products = [[]]
        info[0].forEach((product:ICardInformationConfiguration) => {
          if(product.isInCombo){
            this.combo[0].push(product);
          }else{
            this.products[0].push(product);
          }
        });
        this.checkComboIsEmpty();
        if(this.emptyComboBool){
          this.cartCount = this.products[0].length;
        }
        else{
          this.cartCount = this.products[0].length + 1;
        }
      }
    });    
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    if(this.platformUtil.isBrowser()){
      let element = document.querySelector(".navbar") as HTMLElement;
      if (window.pageYOffset > element.clientHeight) {
        element.classList.add("navbar-scroll");
      } else {
        element.classList.remove("navbar-scroll");
      }
    }
  }

  public routerNavbar(urlNavigate: any) {
    this.router.navigate([urlNavigate]);
  }

  remove() {}

  get badgeItems() {
    return this.lengthItems;
  }

  showSideCart() {
    this.boolVisibleSideBar = !this.boolVisibleSideBar;
  }

  goToCart() {
    this.boolVisibleSideBar = false;
    this.router.navigate(["/cart"]);
  }

  logout() {
    this.onLogout.emit();
  }
  abrirSeleccion(){
    this.boolVisibleSelection = !this.boolVisibleSelection;
  }

  goTo(destino: string){
    this.boolVisibleSideBar = false;
    this.router.navigate([destino]);
  }

  optionsProducts: ICardInformationConfiguration[] = [
  ];

  onOptionSelected(option: ICardInformationConfiguration) {
    //console.log('Option selected:', option);
    if(option.id)
    sessionStorage.setItem('productID', option.id)
    // Puedes realizar acciones adicionales aquí, como completar el input principal.
  }

  get getNickName(){
    return this.user ? this.user?.name?.charAt(0).toUpperCase() + this.user?.lastName?.charAt(0).toUpperCase(): "";
  }

  get getFullName(){
    return this.user?.name +" "+ this.user?.lastName;
  }
  get getMail(){
    return this.user?.username;
  }

  getProducts(){
    this.apiService
      .getProductsBar()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (products: any) => {
            if(products){
              this.optionsProducts = products.results;
              
            }
          },
          ({ message }: IErrorDefinition) => {
            // this.toastrService.showError(message)
          }
        );

  }

  removeItem(identificador: string) {
    let completeProducts = []
    this.products[0] = this.products[0].filter(
      (product: any) => product.identificador != identificador
    );
    completeProducts = this.products[0].concat(this.combo[0]);
    //this.updateLS(this.products[0]);
    this.updateLS(completeProducts);
    this.cartCount = this.products[0].length;
  }

  async confirm(id: string) {
    await Swal.fire({
      title: 'Eliminar',
      text: "¿Deseas eliminar este producto de tu carrito?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
      } else{
        Swal.fire(
          'Eliminado',
          'Producto eliminado',
          'success'
        )
        this.removeItem(id);
        if (this.router.url.includes('/cart')) {
          setTimeout(this.recargar, 1000);
        }
      }
    })
  }

  recargar(){
    window.location.reload()
  }

  checkIsEmpty() {
    this.products[0].length == 0 && this.combo[0].length == 0
      ? (this.emptyCartBool = true)
      : (this.emptyCartBool = false);
  }

  fnConvertToInterface(arrayProducts: any[]): PriceData[] {    
    let arrPriceData: PriceData[] = [];

    arrayProducts.forEach((objProduct: any) => {
      let aux: PriceData = {
        _idProduct: objProduct.id,
        _quantity: objProduct.quantity,
        _users: objProduct.usersQuantity,
        _cost: objProduct.selectedCost,
        _aditionalCost: objProduct.isMono ? objProduct.costMonoRFCUA : objProduct.costMultiRFCUA,
        _discountable: !(objProduct.name == "CFDI Facturación en Línea+"),
        _isInCombo: objProduct.isInCombo
      };

      arrPriceData.push(aux);
    });

    return arrPriceData;
  }

  fnRefresh() {
    let completeProducts = []
    completeProducts = this.products[0].concat(this.combo[0]);

    let priceDataArray: PriceData[] = this.fnConvertToInterface(
      completeProducts
    );
    
    this.priceService.updatePrice(priceDataArray);
    this.checkIsEmpty();
  }

  updateLS(obj: any[]) {
    this.localStorageService.updateItemsLocalStorage(obj);
    this.fnRefresh();
  }

  eliminarCombo(){
    this.combo=[[]]
    this.emptyComboBool=true
    this.updateLS(this.products[0]);
    localStorage.removeItem("empresa")
    this.cartCount = this.products[0].length;
  }

  async confirmDeleteCombo() {
    await Swal.fire({
      title: 'Eliminar',
      text: "¿Deseas eliminar el combo de tu carrito?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {

      } else{
        Swal.fire(
          'Eliminado',
          'Combo eliminado',
          'success'
        )
        this.eliminarCombo()
        if (this.router.url.includes('/cart')) {
          setTimeout(this.recargar, 1000);
        }
      }
    })
  }

  fnGetProductsAvailable() {
    let info:any[] = []
    this.localStorageService.getCarAsObservable().subscribe((item) => {
      info.push(item);
    });
    //console.log(info)
    this.combo = [[]]
    this.products = [[]]
    info[0].forEach((product:ICardInformationConfiguration) => {
      if(product.isInCombo){
        this.combo[0].push(product);
      }else{
        this.products[0].push(product);
      }
    });
    //console.log(this.combo)
    //console.log("this.products",this.products)
    this.products.forEach((ren: any[], i) => {
      ren?.forEach((col: any,j) =>{
        let name = col?.headerImage?.split("/")?.reverse()[0]
        if(name){
          name = name?.split("?")[0]
          this.getImage(name,i,j)
        }
      })
    })
    this.combo.forEach((ren: any[], i) => {
      ren?.forEach((col: any,j) =>{
        let name = col?.headerImage?.split("/")?.reverse()[0]
        if(name){
          name = name?.split("?")[0]
          this.getComboImage(name,i,j)
        }
      })
    })
    this.spinnerService.hide();
  }
  getComboImage(name: string,i: number,j:number){
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if(response){
            //Verificar que existe imagen
            this.combo[i][j].headerImage = response.result;
          } 
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
  getImage(name: string,i: number,j:number){
    const productInfo = {
      "objectKey": name,
      "subDirectory": "products",
      "duration": 30
    };
    this.apiService
      .getPublicImage(productInfo)
      .subscribe(
        (response: any) => {
          if(response){
            //Verificar que existe imagen
            this.products[i][j].headerImage = response.result;
          } 
        },
        ({ message }: IErrorDefinition) => {
          // this.toastr.error(message,'Error');
        }
      );
  }
  
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!(event.target instanceof HTMLElement && event.target.closest('.icon-badge-container')) && !(event.target instanceof HTMLElement && event.target.closest('.side-cart'))) {
      // Si el clic ocurrió fuera del carrito, ocultarlo
      this.boolVisibleSideBar = false;
      if (!(event.target instanceof HTMLElement && event.target.closest('.img-profile'))) {
        // Si el clic ocurrió fuera del carrito, ocultarlo
        this.boolVisibleSelection = false;
      }
    }
  }

} 